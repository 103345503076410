import * as React from "react";

import { Modal, Select } from "antd";

import { v4 as uuid } from "uuid";

import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";
import * as boxTypeCounterLib from "@lib/box/box-type-counter";
import LabelledElement from "@components/molecules/LabelledElement";
import * as attributeTypeLib from "@lib/box/attribute-type";
import { useState, useEffect } from "react";
import { ValueTypeKey } from "@lib/box/value-type";

export interface AssociationToEnable {
	boxTypeKey: string;
	attributeTypeKey: string;
}

export interface CreateAutomaticFilterChildBoxesProps {
	onAddButtonClick: (box: boxLib.Box, editedBoxTypes: boxTypeLib.BoxTypeMap, associationsToEnable: AssociationToEnable[]) => void;
	onCloseOrCancelButtonClick: () => void;
	isVisible: boolean;
	boxKey: string;
	boxChildTypeKey: string;
	box: boxLib.Box | undefined;
	flattenedBoxMap: boxLib.BoxMap;
	boxTypeMap: boxTypeLib.BoxTypeMap;
	boxTypeCounters: boxTypeCounterLib.BoxTypeCounters;
	attributeTypes: attributeTypeLib.AttributeTypeMap;
}


const CreateAutomaticFilterChildBoxesDialog: React.FC<CreateAutomaticFilterChildBoxesProps> = (props) => {

	// const [newBoxTypeName, setNewBoxTypeName] = useState<string>("");

	const [boxTypeKey, setBoxTypeKey] = useState<string | undefined>(undefined);

	const [attributeTypeKey, setAttributeTypeKey] = useState<string | undefined>(undefined);

	const [associationType, setAssociationType] = useState<"name" | "attribute">("name");

	useEffect(() => {
		// setNewBoxTypeName("");
		setBoxTypeKey(undefined);
		setAttributeTypeKey(undefined);
	}, [props.isVisible])

	// Get the title
	const title = 'Create Automatic Filter Child Boxes';

	// Get the OK button text
	const okButtonText = 'Add';



	const boxTypesArray = props.boxTypeMap === undefined ? [] : Object.keys(props.boxTypeMap)
		.map((boxTypeKey) => {
			return {
				key: boxTypeKey,
				name: props.boxTypeMap !== undefined &&
					props.boxTypeMap[boxTypeKey] !== undefined ?
					props.boxTypeMap[boxTypeKey].name : ""
			}
		})
		.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}

			if (a.name > b.name) {
				return 1;
			}

			return 0;
		});

	const attributeTypesArray = props.boxTypeMap === undefined || boxTypeKey === undefined ? [] :
		Object.keys(props.boxTypeMap[boxTypeKey].attributeTypes).map((attributeTypeKey) => {
			return {
				key: attributeTypeKey,
				name: props.boxTypeMap !== undefined &&
					boxTypeKey !== undefined &&
					props.boxTypeMap[boxTypeKey] !== undefined ?
					props.boxTypeMap[boxTypeKey].attributeTypes[attributeTypeKey].name : ""
			}
		});


	const newBoxValues: string[] = [];

	if (boxTypeKey !== undefined && attributeTypeKey !== undefined) {
		Object.keys(props.flattenedBoxMap).filter((boxkey) => {
			return props.flattenedBoxMap[boxkey].boxType === boxTypeKey;
		}).forEach((boxTypeKey) => {
			if (props.flattenedBoxMap[boxTypeKey] !== undefined) {
				const boxTypeObject = props.flattenedBoxMap[boxTypeKey];

				if (boxTypeObject.attributes !== undefined && boxTypeObject.attributes[attributeTypeKey] !== undefined) {

					const newValue = boxTypeObject.attributes[attributeTypeKey].toString();
					if (newBoxValues.indexOf(newValue) === -1) {
						newBoxValues.push(newValue);
					}
				}
			}
		});
	}

	newBoxValues.sort((a, b) => {
		if (a < b) {
			return -1;
		}

		if (a > b) {
			return 1;
		}

		return 0;
	});


	const okButtonEnabled = newBoxValues.length > 0 && boxTypeKey !== undefined && attributeTypeKey !== undefined;

	const handleOKButtonClick = () => {

		if (props.box &&
			props.boxTypeMap && props.attributeTypes && attributeTypeKey && boxTypeKey) {
			const updatedBox = JSON.parse(JSON.stringify(props.box)) as boxLib.Box;

			// Make a copy of the box types
			const editedBoxTypeMap = JSON.parse(JSON.stringify(props.boxTypeMap)) as boxTypeLib.BoxTypeMap;

			// Get the source boxType
			const sourceBoxType = editedBoxTypeMap[boxTypeKey];

			// Create a new box type
			const destinationBoxType = boxTypeLib.createDefaultBoxType();

			// Create the default values for the box type.
			boxTypeLib.setDefaultAttributeTypesForBoxType(destinationBoxType);

			const selectedAttributeType = attributeTypesArray.find((value, index) => { return value.key === attributeTypeKey; });

			if (selectedAttributeType) {

				const associationsToEnable : AssociationToEnable[] = [];

				destinationBoxType.name = `Filter.${selectedAttributeType.name}`;
				const destinationBoxTypeKey = uuid();

				// If we're a Name association type:
				// Source Association: Convert the selectedAttributeType to a "Names Lookup" 
				// the Permitted Association Box To the Destination Association
				// Destination Association: Name Reverse Lookup

				// If we're a doing an Attribute association:
				// source association: AttributeValueReverseLookup
				// destination association: AttributeValuesLookup

				const isAttributeAssociation = associationType === "attribute";

				// Create the Source Association Type
				const sourceAssocation = isAttributeAssociation ? attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Associations) : sourceBoxType.attributeTypes[selectedAttributeType.key];
				sourceAssocation.valueType = ValueTypeKey.Associations;
				sourceAssocation.associationsType = isAttributeAssociation ? attributeTypeLib.AssociationType.AttributeValueReverseLookup : attributeTypeLib.AssociationType.NamesLookup;
				sourceAssocation.associationsAttributeName = isAttributeAssociation ? selectedAttributeType.name : undefined;
				sourceAssocation.name = isAttributeAssociation ? `JOIN TO ${destinationBoxType.name}` : sourceAssocation.name;
				sourceAssocation.description = isAttributeAssociation ? `Joins to any boxes on ${destinationBoxType.name} that contain an attribute with the same value as ${selectedAttributeType.name}` : sourceAssocation.description;
				sourceAssocation.showInSummary = true;
				sourceAssocation.order = -1;

				sourceAssocation.permittedAssociationBoxTypes = destinationBoxTypeKey;

				sourceAssocation.renderFunctions = {};
				const sourceAssociationId = isAttributeAssociation ? uuid() : selectedAttributeType.key;

				// Add the ID of the source association to the array of associations that need to be enabled in the left hand menu
				associationsToEnable.push({boxTypeKey: boxTypeKey, attributeTypeKey: sourceAssociationId});
				
				// Add Source Association to the source box type
				sourceBoxType.attributeTypes[sourceAssociationId] = sourceAssocation;

				// Include the source box type in the edited box type map
				editedBoxTypeMap[boxTypeKey] = sourceBoxType;			

				// Create the destination Association Type
				const destinationAssociation = attributeTypeLib.createDefaultAttributeType(ValueTypeKey.Associations);

				destinationAssociation.associationsType = isAttributeAssociation ? attributeTypeLib.AssociationType.AttributeValuesLookup : attributeTypeLib.AssociationType.NameReverseLookup;
				destinationAssociation.associationsAttributeName = selectedAttributeType.name;
				destinationAssociation.name = `JOIN To ${sourceBoxType.name}`;
				destinationAssociation.description = `Joins to ${sourceBoxType.name}.${selectedAttributeType.name}`;
				destinationAssociation.showInSummary = true;
				destinationAssociation.order = -1;
				destinationAssociation.permittedAssociationBoxTypes = boxTypeKey;

				destinationAssociation.renderFunctions = {};

				const destinationAssociationId = uuid();

				// Add the ID of the destination association to the array of associations that need to be enabled in the left hand menu
				associationsToEnable.push({boxTypeKey: destinationBoxTypeKey, attributeTypeKey: destinationAssociationId});

				// Enable the default association, too.
				associationsToEnable.push({boxTypeKey: destinationBoxTypeKey, attributeTypeKey: "DefaultAssociation"});
				
				// Add the destination association to the destination box type
				destinationBoxType.attributeTypes[destinationAssociationId] = destinationAssociation;

				// Include the destination box type in the edited box type map
				editedBoxTypeMap[destinationBoxTypeKey] = destinationBoxType;

				// If there's no children, set up the child box map.
				if (!updatedBox.children) {
					updatedBox.children = {};
				}

				const updatedBoxChildren = updatedBox.children;

				// // New boxes are last in the box order
				// let highestOrder = boxLib.getHighestBoxOrder(updatedBoxChildren);

				// Given it's a new box type, the Ids are going to start from 1
				let highestId = 1;

				for (let i = 0; i < newBoxValues.length; i += 1) {
					const newChildBox = boxLib.createDefaultBox(destinationBoxTypeKey,
						editedBoxTypeMap,
						highestId);

					newChildBox.name = newBoxValues[i];
					
					boxLib.setDefaultBoxAttributes(editedBoxTypeMap, newChildBox, highestId);

					if (newChildBox.attributes) {
						newChildBox.attributes[destinationAssociationId] = newBoxValues[i];
					}
					

					const newChildBoxKey = uuid();
					
					updatedBoxChildren[newChildBoxKey] = newChildBox;

					highestId += 1;
				}

				props.onAddButtonClick(updatedBox, editedBoxTypeMap, associationsToEnable);
			}
		}
	};

	return (
		<Modal
			title={title}
			open={props.isVisible}
			okText={okButtonText}
			okButtonProps={{ disabled: !okButtonEnabled }}
			onOk={handleOKButtonClick}
			cancelButtonProps={{ disabled: false }}
			onCancel={props.onCloseOrCancelButtonClick}
			zIndex={9999}
			width={"50%"}
		>
			<div>
				<div
					style={{
						width: "100%",
						display: "grid",
						gridTemplateColumns: "0.2fr 0.8fr",
						gap: "4px 12px",
						padding: 0,
						margin: 0,
						overflowY: "visible",
					}}
				>

					<LabelledElement labelText="Source Box Type">
						<Select
							key={`input-operator`}
							size="large"
							placeholder="Please select"
							value={boxTypeKey ? boxTypeKey : ""}
							onChange={(newKey: string) => {
								setAttributeTypeKey(undefined);
								setBoxTypeKey(newKey !== "" ? newKey : undefined);
							}}
							style={{ width: "100%" }}
							getPopupContainer={(node) => {
								let popupContainer: HTMLElement | null =
									window.document.documentElement;
								if (node && node.parentElement) {
									popupContainer = node.parentElement;
								}
								return popupContainer as HTMLElement;
							}}
						>
							<Select.Option key="" value="">- Please select -</Select.Option>

							{
								boxTypesArray.map((attributeType, index) => {
									return <Select.Option key={attributeType.key} value={attributeType.key}>{attributeType.name}</Select.Option>
								})
							}
						</Select>
					</LabelledElement>

					<LabelledElement labelText="Source Attribute Type">
						<Select
							key={`input-operator`}
							size="large"
							placeholder="Please select"
							value={attributeTypeKey ? attributeTypeKey : ""}
							onChange={(newKey: string) => {
								setAttributeTypeKey(newKey !== "" ? newKey : undefined);
							}}
							style={{ width: "100%" }}
							getPopupContainer={(node) => {
								let popupContainer: HTMLElement | null =
									window.document.documentElement;
								if (node && node.parentElement) {
									popupContainer = node.parentElement;
								}
								return popupContainer as HTMLElement;
							}}
						>
							<Select.Option key="" value="">- Please select -</Select.Option>

							{
								attributeTypesArray.map((attributeType, index) => {
									return <Select.Option key={attributeType.key} value={attributeType.key}>{attributeType.name}</Select.Option>
								})
							}
						</Select>
					</LabelledElement>

					<LabelledElement labelText="Association Type">
						<Select
							key={`input-operator`}
							size="large"
							placeholder="Please select"
							value={associationType}
							onChange={(newKey) => {
								setAssociationType(newKey);
							}}
							style={{ width: "100%" }}
							getPopupContainer={(node) => {
								let popupContainer: HTMLElement | null =
									window.document.documentElement;
								if (node && node.parentElement) {
									popupContainer = node.parentElement;
								}
								return popupContainer as HTMLElement;
							}}
						>
							<Select.Option key="name" value="name">Name</Select.Option>
							<Select.Option key="attribute" value="attribute">Attribute</Select.Option>
						</Select>
					</LabelledElement>

					{
						boxTypeKey && attributeTypeKey &&
						<LabelledElement labelText="Values to Create">
							<ul>
								{newBoxValues.length < 1 ? <li>No Values Found</li> : newBoxValues.map((val, index) => {
									return <li key={index}>{val}</li>;
								})}
							</ul>
						</LabelledElement>
					}
				</div>
			</div>
		</Modal>
	);
}


export default CreateAutomaticFilterChildBoxesDialog;