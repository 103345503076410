import * as React from "react";

import "./Illustration.css";

import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";
import { createBoxDefaultProperties } from "@lib/box/box-properties";

import * as illustrationLib from "@lib/illustration/illustration";

import { LayoutItem } from "@lib/layout/layout";

import { Box } from "../box/Box";

// TODO - the grid constants should be retrieved from a single file

// The grid width and height (in columns)
const GRID_WIDTH_IN_COLUMNS = 100;
const GRID_HEIGHT_IN_ROWS = 100;

interface IllustrationProps {
	onBoxAcceptEditInPlace: (boxKey: string, boxText: string) => void;
	onBoxEditSelect: (boxKey: string, ctrlKey: boolean, shiftKey: boolean) => void;
	onBoxMultiEditSelect: (boxKey: string, ctrlKey: boolean, shiftKey: boolean) => void;
	onBoxAssociationsEditSelect: (boxKey: string) => void;
	onBoxViewSelect: (boxKey: string) => void;
	onBoxAddChild: (boxKey: string) => void;
	onBoxRemoveChild: (boxKey: string) => void;
	onBoxChildLayoutChange: (boxKey: string, childLayout: string) => void;
	onBoxCanDrop: (
		dragSourceBoxKey: string,
		dragSourceBoxParentKey: string,
		dropTargetBoxKey: string,
		dropTargetBoxParentKey: string
	) => boolean;
	onBoxDragStart: (dragSourceBoxKey: string, dragSourceBoxParentKey: string) => void;
	onBoxDragCancel: () => void;
	onBoxDragHover: (
		dragSourceBoxKey: string,
		dragSourceBoxParentKey: string,
		dropTargetBoxKey: string,
		dropTargetBoxParentKey: string,
		sortedBoxKeys: string[],
		dragX: number,
		dragY: number
	) => void;
	onBoxDragDrop: (
		dragSourceBoxKey: string,
		dragSourceBoxParentKey: string,
		dropTargetBoxKey: string,
		dropTargetBoxParentKey: string,
		sortedBoxKeys: string[]
	) => void;
	illustration: illustrationLib.Illustration | undefined;
	illustrationState: illustrationLib.IllustrationState;
	illustrationFlattenedBoxMap: boxLib.BoxMap | undefined;
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined;
	boxVisibilityMap: boxLib.BoxVisibilityMap | undefined;
	boxAssociationsMap: boxLib.BoxAssociationsMap | undefined;
	boxSelectionInfoMap: boxLib.BoxSelectionInfoMap | undefined;
	widthInPixels: number;
	heightInPixels: number;
	viewModeHorizontalBoxGapInPixels: number,
	viewModeVerticalBoxGapInPixels: number,
	editModeHorizontalBoxGapInPixels: number,
	editModeVerticalBoxGapInPixels: number,
	currentDragSourceBoxKey: string,
	currentlyHighlightedDropTargetBoxKey: string;
	disableAddBox: boolean;
	disableEditBox: boolean;
	disableRemoveBox: boolean;
	disableDragBox: boolean;

	shouldUpdate: boolean;
}

type HighlightedBoxChangeListener = (boxKey: string) => void;

export class Illustration extends React.Component<IllustrationProps> {
	highlightedBoxChangeListeners: Record<string, HighlightedBoxChangeListener | undefined> = {}
	previousHighlightedBoxKey: string = ''

	shouldComponentUpdate(nextProps: IllustrationProps) {
		return nextProps.shouldUpdate;
 }

	public render() {
		const horizontalBoxGapInPixels = (this.props.illustrationState === illustrationLib.IllustrationState.VIEW)
			? this.props.viewModeHorizontalBoxGapInPixels
			: this.props.editModeHorizontalBoxGapInPixels;
		const verticalBoxGapInPixels = (this.props.illustrationState === illustrationLib.IllustrationState.VIEW)
			? this.props.viewModeVerticalBoxGapInPixels
			: this.props.editModeVerticalBoxGapInPixels;

		boxTypeLib.setAttributeTypeVisibilityCacheEnabled(true);
		boxTypeLib.setMixinBoxTypeCacheEnabled(true);

		// Render the boxes
		const renderedBoxes = this.renderBoxes(this.props.illustration,
			horizontalBoxGapInPixels,
			verticalBoxGapInPixels);

		boxTypeLib.setMixinBoxTypeCacheEnabled(false);
		boxTypeLib.setAttributeTypeVisibilityCacheEnabled(false);

		return (
			<div
				style={{
					display: "inline-block",
					width: "100%",
					padding: 0,
					margin: 0,
					backgroundColor: "#FFF",
				}}
			>
				{renderedBoxes}
			</div>
		);
	}

	private renderBoxes = (
		illustration: illustrationLib.Illustration | undefined,
		horizontalBoxGapInPixels: number,
		verticalBoxGapInPixels: number
	) => {
		// If we don't have an illustration or boxes there's nothing to render
		if (!illustration || !illustration.boxes) {
			return null;
		}

		// TODO: We can clean this up later

		// Get the boxes (in reality there's only a single root box)
		const boxes = illustration.boxes;

		// Get the box properties
		const boxProperties = createBoxDefaultProperties();
		boxLib.setBoxPropertiesFromBoxPropertyMap(
			boxProperties,
			illustration.defaultProperties,
			illustration.boxStyles
		);

		// We have a single layout item for the root
		const layoutItem: LayoutItem = {
			i: "root",
			x: 0,
			y: 0,
			w: GRID_WIDTH_IN_COLUMNS,
			h: GRID_HEIGHT_IN_ROWS,
		};

		// Build the layout
		const layout: LayoutItem[] = [layoutItem];

		const gridWidthInPixels = this.props.widthInPixels;
		const gridHeightInPixels = this.props.heightInPixels;

		// Render each of them
		const renderedBoxes = Object.keys(boxes).map((boxKey: string) => {
			// Get the box
			const box = boxes[boxKey];

			// Top-level boxes don't have a header and can't be removed or dragged
			const showHeader = false;
			const canRemove = false;
			const canDrag = false;

			// Determine the illustration features enabled on the illustration state
			let onBoxClick = this.props.onBoxEditSelect;
			let onBoxHeaderChangeAccept = this.props.onBoxAcceptEditInPlace;

			let isBoxAddChildButtonEnabled = false;
			let isBoxRemoveChildButtonEnabled = false;
			let isBoxChildLayoutChangeButtonEnabled = false;

			let isBoxChildDragAndDropEnabled = false;
			let isBoxAssociationsHighlightingEnabled = false;

			if (
				this.props.illustrationState ===
				illustrationLib.IllustrationState.EDIT_BOX
			) {
				// Wire up the edit callbacks
				onBoxClick = this.props.onBoxEditSelect;
				onBoxHeaderChangeAccept = this.props.onBoxAcceptEditInPlace;

				// Enable illustration editing
				isBoxAddChildButtonEnabled = true;
				isBoxRemoveChildButtonEnabled = true;
				isBoxChildLayoutChangeButtonEnabled = true;

				isBoxChildDragAndDropEnabled = true;

				// Enable association highlighting
				isBoxAssociationsHighlightingEnabled = true;
			} else if (
				this.props.illustrationState ===
				illustrationLib.IllustrationState.EDIT_MULTI_BOX
			) {
				// Wire up the multi-edit selection callback
				onBoxClick = this.props.onBoxMultiEditSelect;

				// Disable the double-click and header change callbacks
				onBoxHeaderChangeAccept = () => {};

				// Disable illustration editing
				isBoxAddChildButtonEnabled = false;
				isBoxRemoveChildButtonEnabled = false;
				isBoxChildLayoutChangeButtonEnabled = false;

				isBoxChildDragAndDropEnabled = false;

				// Disable association highlighting
				isBoxAssociationsHighlightingEnabled = false;
			} else if (
				this.props.illustrationState ===
				illustrationLib.IllustrationState.EDIT_ASSOCIATIONS
			) {
				// TODO: This needs to be the new simple associations stuff

				// Wire up the association selection callback
				onBoxClick = this.props.onBoxAssociationsEditSelect;

				// Disable the double-click and header change callbacks
				onBoxHeaderChangeAccept = () => {};

				// Disable illustration editing
				isBoxAddChildButtonEnabled = false;
				isBoxRemoveChildButtonEnabled = false;
				isBoxChildLayoutChangeButtonEnabled = false;

				isBoxChildDragAndDropEnabled = false;

				// Disable association highlighting
				isBoxAssociationsHighlightingEnabled = false;
			} else if (
				this.props.illustrationState ===
				illustrationLib.IllustrationState.SIMPLE_EDIT_ASSOCIATIONS
			) {
				// Wire up the association selection callback
				onBoxClick = this.props.onBoxAssociationsEditSelect;

				// Disable the double-click and header change callbacks
				onBoxHeaderChangeAccept = () => {};

				// Disable illustration editing
				isBoxAddChildButtonEnabled = false;
				isBoxRemoveChildButtonEnabled = false;
				isBoxChildLayoutChangeButtonEnabled = false;

				isBoxChildDragAndDropEnabled = false;

				// Disable association highlighting
				isBoxAssociationsHighlightingEnabled = false;
			} else if (
				this.props.illustrationState ===
				illustrationLib.IllustrationState.VIEW
			) {
				// Wire up the view selection callback
				onBoxClick = this.props.onBoxViewSelect;

				// Disable the double-click and header change callbacks
				onBoxHeaderChangeAccept = () => {};

				// Disable illustration editing
				isBoxAddChildButtonEnabled = false;
				isBoxRemoveChildButtonEnabled = false;
				isBoxChildLayoutChangeButtonEnabled = false;

				isBoxChildDragAndDropEnabled = false;

				// Enable association highlighting
				isBoxAssociationsHighlightingEnabled = true;
			}

			// Override the illustration editing with the global settings
			if (this.props.disableAddBox) {
				isBoxAddChildButtonEnabled = false;
			}
			if (this.props.disableEditBox) {
				isBoxChildLayoutChangeButtonEnabled = false;
				if (
					this.props.illustrationState !==
					illustrationLib.IllustrationState.VIEW
				) {
					onBoxClick = () => {};
					onBoxHeaderChangeAccept = () => {};
				}
			}
			if (this.props.disableRemoveBox) {
				isBoxRemoveChildButtonEnabled = false;
			}
			if (this.props.disableDragBox) {
				isBoxChildDragAndDropEnabled = false;
			}

			return (
				<Box
					key={boxKey}
					registerHighlightedBoxChangeListener={this.handleRegisterHighlightedBoxChangeListener}
					unregisterHighlightedBoxChangeListener={this.handleUnregisterHighlightedBoxChangeListener}
					onHighlightedBoxChange={this.handleHighlightedBoxChange}
					onBoxClick={onBoxClick}

					onBoxHeaderChangeAccept={onBoxHeaderChangeAccept}
					onBoxAddChild={this.props.onBoxAddChild}
					onBoxRemoveChild={this.props.onBoxRemoveChild}
					onBoxChildLayoutChange={this.props.onBoxChildLayoutChange}
					onBoxCanDrop={this.props.onBoxCanDrop}
					onBoxDragStart={this.props.onBoxDragStart}
					onBoxDragCancel={this.props.onBoxDragCancel}
					onBoxDragHover={this.props.onBoxDragHover}
					onBoxDragDrop={this.props.onBoxDragDrop}
					widthInPixels={gridWidthInPixels}
					heightInPixels={gridHeightInPixels}
					horizontalBoxGapInPixels={horizontalBoxGapInPixels}
					verticalBoxGapInPixels={verticalBoxGapInPixels}
					baseStyle={{}}
					boxKey={boxKey}
					boxParentKey={""}
					boxParentLayout={layout}
					boxParentChildLayout={boxProperties.childLayout}
					boxParentGridColumnWidthInPixels={gridWidthInPixels}
					boxParentGridRowHeightInPixels={gridHeightInPixels}
					box={box}
					illustrationFlattenedBoxMap={this.props.illustrationFlattenedBoxMap}
					boxTypeKey={box.boxType}
					boxTypes={illustration.boxTypes}
					boxTypeVisibilityMap={this.props.boxTypeVisibilityMap}
					boxSelectionInfoMap={this.props.boxSelectionInfoMap}
					boxVisibilityMap={this.props.boxVisibilityMap}
					boxVisualisations={illustration.boxVisualisations}
					boxStyles={illustration.boxStyles}
					boxParentTypeKey={""}
					boxParentProperties={boxProperties}
					boxParentAttributes={illustration.attributes}
					boxAssociationsMap={this.props.boxAssociationsMap}
					currentDragSourceBoxKey={this.props.currentDragSourceBoxKey}
					currentlyHighlightedDropTargetBoxKey={
						this.props.currentlyHighlightedDropTargetBoxKey
					}
					showHeader={showHeader}
					canAddChildren={isBoxAddChildButtonEnabled}
					canRemoveChildren={isBoxRemoveChildButtonEnabled}
					canRemove={canRemove}
					canDragChildren={isBoxChildDragAndDropEnabled}
					canDrag={canDrag}
					canChangeChildLayout={isBoxChildLayoutChangeButtonEnabled}
					canHighlightAssociations={
						isBoxAssociationsHighlightingEnabled
					}
				/>
			);
		});

		return renderedBoxes;
	};

	private notifyHighlightedBoxChange = (boxKey: string, boxAssociations: string[]) => {
		// Ignore the event if we're dragging
		if (this.props.currentDragSourceBoxKey) return;

		boxAssociations.forEach((associatedBoxKey: string) => {
			const highlightedBoxChangeListener = this.highlightedBoxChangeListeners[associatedBoxKey]
			if (highlightedBoxChangeListener) {
				highlightedBoxChangeListener(boxKey);
			}
		})
	}

	private handleRegisterHighlightedBoxChangeListener = (boxKey: string, listener: HighlightedBoxChangeListener) => {
		// console.log(`registering change listener for: ${boxKey}`);

		this.highlightedBoxChangeListeners[boxKey] = listener;
	}

	private handleUnregisterHighlightedBoxChangeListener = (boxKey: string) => {
		// console.log(`unregistering change listener for: ${boxKey}`);

		this.highlightedBoxChangeListeners[boxKey] = undefined;
	}

	private handleHighlightedBoxChange = (boxKey: string) => {
		// console.log(`handleHighlightedBoxChange: ${boxKey}`);
		
		// Ignore the event if we're dragging
		if (this.props.currentDragSourceBoxKey) return;

		if (this.props.boxAssociationsMap) {
			const previousBoxAssociations = this.props.boxAssociationsMap[this.previousHighlightedBoxKey]
			if (previousBoxAssociations) {
				this.notifyHighlightedBoxChange(boxKey, previousBoxAssociations)
			}

			const currentBoxAssociations = this.props.boxAssociationsMap[boxKey]
			if (currentBoxAssociations) {
				this.notifyHighlightedBoxChange(boxKey, currentBoxAssociations)
			}
		}

		this.previousHighlightedBoxKey = boxKey;
	}
}
