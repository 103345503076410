import React, { useEffect } from "react";

import { Modal, Collapse, Input, Select } from "antd";

import * as lensesLib from "@lib/lenses/lenses";
import * as boxTypeLib from "@lib/box/box-type";
import * as boxLib from "@lib/box/box";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as attributeLib from "@lib/box/attribute";
import FormHeading from "@components/organisms/FormHeading";
import FormHeadingText from "@components/organisms/FormHeadingText";
import BoxTypeQueryRestrictions from "./components/BoxTypeQueryRestrictions";
import AttributeTypeQueryRestrictions from "./components/AttributeTypeQueryRestrictions";
import BoxQueryRestrictions from "./components/BoxQueryRestrictions";
import LabelledElement from "@components/molecules/LabelledElement";
import AttributeQueryRestrictions from "./components/AttributeQueryRestrictions";
import AttributeTypeVisibilityList from "./components/AttributeTypeVisibilityList";
import useBoxTypeQueryRestrictions from "./hooks/useBoxTypeQueryRestrictions";
import useAttributeTypeQueryRestrictions from "./hooks/useAttributeTypeQueryRestrictions";
import useBoxQueryRestrictions from "./hooks/useBoxQueryRestrictions";
import useAttributeQueryRestrictions from "./hooks/useAttributeQueryRestrictions";
import useQuery from "./hooks/useQuery";
import useAttributeTypeVisibilityList from "./hooks/useAttributeTypeVisibilityList";
import MixinVisibilityList from "./components/MixinVisibilityList";
import useMixinVisibilityList from "./hooks/useMixinVisibilityList";
// import AttributeTypeQueryRestrictions from "./components/AttributeTypeQueryRestrictions";
// import BoxQueryRestrictions from "./components/BoxQueryRestrictions";
// import AttributeQueryRestrictions from "./components/AttributeQueryRestrictions";

const { Panel } = Collapse;

// Controls we need

// - Name
// - Operator, which applies to everything.

// # Query Restrictions - Where Clause
// ## Box Type expressions - List of Box Type expressions that you can add to, where you can pick the operator
// - boxTypeExpressions - list
// - boxTypeExpressionOperator - dropdown
// - boxTypeIsVisible
// - boxTypeAreBoxesVisible
// - boxTypeAreBoxesInLayout

// ## Attribute Type Expressions - List, where you can pick the operator
// - attributeTypeExpressions - list
// - attributeTypeExpressionOperator - dropdown


// ## Attribute Expressions - A List, where you can pick the operator
// - need to double up on the operator here.

// ## Box Expressions: A list, where you can pick the operator
// - boxExpressions
// - applyBoxVisibility
// - boxIsVisible
// - boxIsInLayout

// # Turn Attributes on and off
// boxTypeMixinBoxTypeVisibilityMap
// boxTypeAttributeTypeVisibilityMap

export interface AddEditQueryDialogProps {
	onOKButtonClick: (query: lensesLib.Query) => void;
	onCancelButtonClick: (queryType: lensesLib.QueryType) => void;
	isAdding: boolean;
	isVisible: boolean;
	query: lensesLib.Query;
	boxTypes: boxTypeLib.BoxTypeMap;
	boxes: boxLib.BoxMap;
	attributes: attributeLib.AttributeMap;
	parameters: lensesLib.ParameterMap;
}

const AddEditQueryDialog: React.FC<AddEditQueryDialogProps> = (props) => {
	// Get the title
	const title = props.isAdding ? "Add Query" : "Edit Query";



	// const [description, setDescription] = useState("");
	// const [order, setOrder] = useState(0);
	// const [queries, setQueries] = useState<lensesLib.Query[]>([]);
	// const [parameters, setParameters] = useState<lensesLib.ParameterMap>({})

	useEffect(() => {
		const copiedObject: lensesLib.Query = JSON.parse(JSON.stringify(props.query));

		//copiedObject.

		// setName(copiedObject.name);
		// setDescription(copiedObject.description !== undefined ? copiedObject.description : "");
		// setOrder(copiedObject.order);
		// setQueries(copiedObject.queries !== undefined ? copiedObject.queries : []);
		// setParameters(copiedObject.parameters !== undefined ? copiedObject.parameters : {});
	}, [props.query, props.isVisible]);


	const {
		name,
		operator,
		onNameChange,
		onOperatorChange,
	} = useQuery(props.query);



	let attributeTypes: attributeTypeLib.AttributeTypeMap = {};

	Object.keys(props.boxTypes).forEach((boxTypeKey, index) => {
		const newAttributeTypes: attributeTypeLib.AttributeTypeMap = boxTypeLib.getBoxTypeAttributeTypeCacheForType(boxTypeKey);


		if (newAttributeTypes !== undefined && newAttributeTypes != null) {
			attributeTypes = Object.assign(attributeTypes, newAttributeTypes);
		}
	});

	const processBoxTypeQueryRestrictions = (
		query: lensesLib.Query,
		processingFunction: (query: lensesLib.Query) => lensesLib.Query
	) => {
		return processingFunction(query);
	};


	const flattenedBoxes = boxLib.getFlattenedBoxMap(props.boxes, {});



	const {
		boxTypeExpressions,
		isAddEditBoxTypeExpressionDialogVisible,
		isAddEditBoxTypeExpressionDialogAdding,
		addEditBoxTypeExpression,
		boxTypeAreBoxesInLayout,
		boxTypeAreBoxesVisible,
		applyBoxTypeVisibility,
		boxTypeExpressionOperator,
		boxTypeIsVisible,
		onApplyBoxTypeVisibilityChanged,
		onBoxTypeExpressionOperatorChanged,
		onBoxTypeAreBoxesVisibleChanged,
		onBoxTypeAreBoxesInLayoutChanged,
		onApplyBoxTypeIsVisibleChanged,
		onCloseAddEditBoxTypeExpressionForm,
		onOpenAddBoxTypeExpressionForm,
		onOpenEditBoxTypeExpressionForm,
		onAddEditBoxTypeExpressionFormOk,
		onDeleteBoxTypeExpression
	} = useBoxTypeQueryRestrictions(props.query);


	const {
		attributeTypeExpressions,
		attributeTypeExpressionOperator,

		isAddEditAttributeTypeExpressionDialogVisible,
		isAddEditAttributeTypeExpressionDialogAdding,
		addEditAttributeTypeExpression,

		onAttributeTypeExpressionOperatorChanged,
		onDeleteAttributeTypeExpression,
		onAddEditAttributeTypeExpressionFormOk,
		onCloseAddEditAttributeTypeExpressionForm,
		onOpenEditAttributeTypeExpressionForm,
		onOpenAddAttributeTypeExpressionForm

	} = useAttributeTypeQueryRestrictions(props.query)


	const {
		boxExpressions,
		applyBoxVisibility,
		boxIsInLayout,
		boxIsVisible,
		boxExpressionOperator,

		isAddEditBoxExpressionDialogVisible,
		isAddEditBoxExpressionDialogAdding,
		addEditBoxExpression,

		onApplyBoxVisibilityChanged,
		onBoxIsInLayoutChanged,
		onBoxIsVisibleChanged,
		onBoxExpressionOperatorChanged,

		onDeleteBoxExpression,
		onAddEditBoxExpressionFormOk,
		onCloseAddEditBoxExpressionForm,
		onOpenEditBoxExpressionForm,
		onOpenAddBoxExpressionForm
	} = useBoxQueryRestrictions(props.query);


	const {
		attributeExpressionList,

		requiresJSONEditor,
		JSONString,
		JSONEditorStyle,
		useAdvancedMode,
		showJSONEditor,
		JSONIsValid,

		onUseAdvancedModeChanged,
		onJSONStringChange,

		isAddEditAttributeExpressionDialogVisible,
		isAddEditAttributeExpressionDialogAdding,
		addEditAttributeExpression,
		addEditAttributeExpressionIndex,

		onAddEditAttributeExpressionFormOk,
		onOpenAddAttributeExpressionForm,
		onOpenEditAttributeExpressionForm,
		onCloseAddEditAttributeExpressionForm,
		onDeleteAttributeExpression
	} = useAttributeQueryRestrictions(props.query);

	const {
		attributeTypeVisibilityMap,

		isAddEditAttributeTypeVisibilityItemDialogVisible,
		isAddEditAttributeTypeVisibilityItemDialogAdding,
		addEditAttributeTypeVisibilityItem,

		onDeleteAttributeTypeVisibilityItem,
		onAddEditAttributeTypeVisibilityItemFormOk,
		onCloseAddEditAttributeTypeVisibilityForm,
		onOpenEditAttributeTypeVisibilityForm,
		onOpenAddAttributeTypeVisibilityForm

	} = useAttributeTypeVisibilityList(props.query.boxTypeAttributeTypeVisibilityMap);


	const {
		mixinVisibilityMap,

		isAddEditMixinVisibilityItemDialogVisible,
		isAddEditMixinVisibilityItemDialogAdding,
		addEditMixinVisibilityItem,

		onDeleteMixinVisibilityItem,
		onAddEditMixinVisibilityItemFormOk,
		onCloseAddEditMixinVisibilityForm,
		onOpenEditMixinVisibilityForm,
		onOpenAddMixinVisibilityForm,
	} = useMixinVisibilityList(props.query.boxTypeMixinBoxTypeVisibilityMap);


	const handleOKButtonClick = () => {

		// Build the Query

		const newQuery: lensesLib.Query = {
			name,
			operator,
			applyBoxTypeVisibility,

			// TODO: Create this
			attributeExpression: undefined,
			attributeTypeExpressionOperator,
			attributeTypeExpressions,
			boxExpressionOperator,
			boxIsInLayout,
			boxIsVisible,
			applyBoxVisibility,
			boxExpressions,
			boxTypeAreBoxesInLayout,
			boxTypeAreBoxesVisible,

			// TODO: These need filling in
			boxTypeAttributeTypeVisibilityMap: attributeTypeVisibilityMap,
			boxTypeExpressionOperator,
			boxTypeExpressions,
			boxTypeIsVisible,

			// TODO: These need filling in
			boxTypeMixinBoxTypeVisibilityMap: {}

		};

		props.onOKButtonClick(newQuery);
	};



	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		onCancel={() => {props.onCancelButtonClick(lensesLib.QueryType.DEFAULT)}}
		zIndex={9999}
		width="75%"
	>
		<div>
			<FormHeading>
				<FormHeadingText>Overview</FormHeadingText>
			</FormHeading>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "0.1fr 0.9fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<LabelledElement labelText="Name">
					<Input

						value={name}
						onChange={(
							event: React.ChangeEvent<HTMLInputElement>
						) => {
							onNameChange(event.target.value);
						}}
					/>
				</LabelledElement>

				<LabelledElement labelText="Logical Operator to Apply to All Query Expressions">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={operator}
						onChange={
							(value: string) => {
								onOperatorChange(value);
							}
						}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						<Select.Option>and</Select.Option>
						<Select.Option>or</Select.Option>
					</Select>
				</LabelledElement>

			</div>
			<div>
				<Collapse>
					<Panel header="Query Expressions" key="1">
						<BoxTypeQueryRestrictions
							boxTypes={props.boxTypes}
							boxTypeExpressions={boxTypeExpressions}
							isAddEditBoxTypeExpressionDialogVisible={isAddEditBoxTypeExpressionDialogVisible}
							isAddEditBoxTypeExpressionDialogAdding={isAddEditBoxTypeExpressionDialogAdding}
							addEditBoxTypeExpression={addEditBoxTypeExpression}
							boxTypeAreBoxesInLayout={boxTypeAreBoxesInLayout}
							boxTypeAreBoxesVisible={boxTypeAreBoxesVisible}
							applyBoxTypeVisibility={applyBoxTypeVisibility}
							boxTypeExpressionOperator={boxTypeExpressionOperator}
							boxTypeIsVisible={boxTypeIsVisible}
							onApplyBoxTypeVisibilityChanged={onApplyBoxTypeVisibilityChanged}
							onBoxTypeExpressionOperatorChanged={onBoxTypeExpressionOperatorChanged}
							onBoxTypeAreBoxesVisibleChanged={onBoxTypeAreBoxesVisibleChanged}
							onBoxTypeAreBoxesInLayoutChanged={onBoxTypeAreBoxesInLayoutChanged}
							onApplyBoxTypeIsVisibleChanged={onApplyBoxTypeIsVisibleChanged}
							onCloseAddEditBoxTypeExpressionForm={onCloseAddEditBoxTypeExpressionForm}
							onOpenAddBoxTypeExpressionForm={onOpenAddBoxTypeExpressionForm}
							onOpenEditBoxTypeExpressionForm={onOpenEditBoxTypeExpressionForm}
							onAddEditBoxTypeExpressionFormOk={onAddEditBoxTypeExpressionFormOk}
							onDeleteBoxTypeExpression={onDeleteBoxTypeExpression}
						/>
						<br />
						<AttributeTypeQueryRestrictions
							attributeTypes={attributeTypes}
							attributeTypeExpressions={attributeTypeExpressions}
							attributeTypeExpressionOperator={attributeTypeExpressionOperator}

							isAddEditAttributeTypeExpressionDialogVisible={isAddEditAttributeTypeExpressionDialogVisible}
							isAddEditAttributeTypeExpressionDialogAdding={isAddEditAttributeTypeExpressionDialogAdding}
							addEditAttributeTypeExpression={addEditAttributeTypeExpression}

							onAttributeTypeExpressionOperatorChanged={onAttributeTypeExpressionOperatorChanged}
							onDeleteAttributeTypeExpression={onDeleteAttributeTypeExpression}
							onAddEditAttributeTypeExpressionFormOk={onAddEditAttributeTypeExpressionFormOk}
							onCloseAddEditAttributeTypeExpressionForm={onCloseAddEditAttributeTypeExpressionForm}
							onOpenEditAttributeTypeExpressionForm={onOpenEditAttributeTypeExpressionForm}
							onOpenAddAttributeTypeExpressionForm={onOpenAddAttributeTypeExpressionForm}
						/>
						<br />
						<BoxQueryRestrictions
							flattenedBoxes={flattenedBoxes}
							boxExpressions={boxExpressions}
							applyBoxVisibility={applyBoxVisibility}
							boxIsInLayout={boxIsInLayout}
							boxIsVisible={boxIsVisible}
							boxExpressionOperator={boxExpressionOperator}

							isAddEditBoxExpressionDialogVisible={isAddEditBoxExpressionDialogVisible}
							isAddEditBoxExpressionDialogAdding={isAddEditBoxExpressionDialogAdding}
							addEditBoxExpression={addEditBoxExpression}

							onApplyBoxVisibilityChanged={onApplyBoxVisibilityChanged}
							onBoxIsInLayoutChanged={onBoxIsInLayoutChanged}
							onBoxIsVisibleChanged={onBoxIsVisibleChanged}
							onBoxExpressionOperatorChanged={onBoxExpressionOperatorChanged}

							onDeleteBoxExpression={onDeleteBoxExpression}
							onAddEditBoxExpressionFormOk={onAddEditBoxExpressionFormOk}
							onCloseAddEditBoxExpressionForm={onCloseAddEditBoxExpressionForm}
							onOpenEditBoxExpressionForm={onOpenEditBoxExpressionForm}
							onOpenAddBoxExpressionForm={onOpenAddBoxExpressionForm}
						/>
						<br />
						<AttributeQueryRestrictions
							attributeTypes={attributeTypes}
							parameters={props.parameters}
							attributeExpressionList={attributeExpressionList}

							requiresJSONEditor={requiresJSONEditor}
							JSONString={JSONString}
							JSONEditorStyle={JSONEditorStyle}
							useAdvancedMode={useAdvancedMode}
							showJSONEditor={showJSONEditor}
							JSONIsValid={JSONIsValid}

							onUseAdvancedModeChanged={onUseAdvancedModeChanged}
							onJSONStringChange={onJSONStringChange}

							isAddEditAttributeExpressionDialogVisible={isAddEditAttributeExpressionDialogVisible}
							isAddEditAttributeExpressionDialogAdding={isAddEditAttributeExpressionDialogAdding}
							addEditAttributeExpression={addEditAttributeExpression}
							addEditAttributeExpressionIndex={addEditAttributeExpressionIndex}

							onAddEditAttributeExpressionFormOk={onAddEditAttributeExpressionFormOk}
							onOpenAddAttributeExpressionForm={onOpenAddAttributeExpressionForm}
							onOpenEditAttributeExpressionForm={onOpenEditAttributeExpressionForm}
							onCloseAddEditAttributeExpressionForm={onCloseAddEditAttributeExpressionForm}
							onDeleteAttributeExpression={onDeleteAttributeExpression}

						/>
					</Panel>
					<Panel header="Attribute Visibility" key="2">
						<AttributeTypeVisibilityList
							attributeTypes={attributeTypes}
							attributeTypeVisibilityMap={attributeTypeVisibilityMap}

							isAddEditAttributeTypeVisibilityItemDialogVisible={isAddEditAttributeTypeVisibilityItemDialogVisible}
							isAddEditAttributeTypeVisibilityItemDialogAdding={isAddEditAttributeTypeVisibilityItemDialogAdding}
							addEditAttributeTypeVisibilityItem={addEditAttributeTypeVisibilityItem}

							onDeleteAttributeTypeVisibilityItem={onDeleteAttributeTypeVisibilityItem}
							onAddEditAttributeTypeVisibilityItemFormOk={onAddEditAttributeTypeVisibilityItemFormOk}
							onCloseAddEditAttributeTypeVisibilityForm={onCloseAddEditAttributeTypeVisibilityForm}
							onOpenEditAttributeTypeVisibilityForm={onOpenEditAttributeTypeVisibilityForm}
							onOpenAddAttributeTypeVisibilityForm={onOpenAddAttributeTypeVisibilityForm}
						/>

						<MixinVisibilityList
							boxTypes={props.boxTypes}
							mixinVisibilityMap={mixinVisibilityMap}

							isAddEditMixinVisibilityItemDialogVisible={isAddEditMixinVisibilityItemDialogVisible}
							isAddEditMixinVisibilityItemDialogAdding={isAddEditMixinVisibilityItemDialogAdding}
							addEditMixinVisibilityItem={addEditMixinVisibilityItem}

							onDeleteMixinVisibilityItem={onDeleteMixinVisibilityItem}
							onAddEditMixinVisibilityItemFormOk={onAddEditMixinVisibilityItemFormOk}
							onCloseAddEditMixinVisibilityForm={onCloseAddEditMixinVisibilityForm}
							onOpenEditMixinVisibilityForm={onOpenEditMixinVisibilityForm}
							onOpenAddMixinVisibilityForm={onOpenAddMixinVisibilityForm}

						/>

					</Panel>
				</Collapse>
			</div>
		</div>
	</Modal>
}

export default AddEditQueryDialog;