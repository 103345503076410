import React from "react";

import { Modal, Checkbox, Card, Row, Col, Collapse } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";

import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";
import { AttributeTypeMap } from "@lib/box/attribute-type";

const { Panel } = Collapse;

const ASSOCIATION_VALUE_TYPE = "associations";
const UNTYPED_BOX_KEY = "untyped";

interface GenericMap<T> {
	[key: string]: T;
}

interface BoxTypeKeyMap {
	[key: string]: string[];
}

interface MappingViewModel {
	name: string;
	from: BoxTypeViewModel;
	to: BoxTypeViewModel;
}

interface BoxTypeViewModel {
	name: string;
	attributeTypes: GenericMap<AttributeTypeViewModel>;
}

interface AttributeTypeViewModel {
	name: string;
}

interface Mapping {
	selectedSourceAttributeTypes: string[];
	selectedDestinationBoxTypeAttributes: BoxTypeKeyMap;
}

interface CreateAssociationsDialogProps {
	// illustration: any | undefined,
	onOKButtonClick: (boxes: boxLib.BoxMap | undefined) => void;
	onCancelButtonClick: () => void;
	isVisible: boolean;
	firstSelectedBoxKey: string;
	boxSelectionInfoMap: any;
	flattenedBoxMap: boxLib.BoxMap;
	boxTypeMap: boxTypeLib.BoxTypeMap;
}

interface CreateAssociationsDialogState {
	sourceBox: boxLib.Box;
	sourceBoxType: boxTypeLib.BoxType;
	destinationBoxes: boxLib.BoxMap;
	destinationBoxTypes: boxTypeLib.BoxTypeMap;
	mappings: GenericMap<Mapping>;
	mappingViewModels: GenericMap<MappingViewModel>;
}

interface BoxTypesProps {
	boxTypes: boxTypeLib.BoxTypeMap;
	onAttributeTypeSelectionChange: (
		boxTypeKey: string,
		attributeTypeKey: string,
		state: boolean
	) => void;
}

interface BoxTypeProps {
	boxTypeKey: string;
	boxType: boxTypeLib.BoxType;
	onAttributeTypeSelectionChange: (
		boxTypeKey: string,
		attributeTypeKey: string,
		state: boolean
	) => void;
}

interface AssociationAttributeTypesProps {
	boxTypeKey: string;
	attributeTypes: AttributeTypeMap;
	onAttributeTypeSelectionChange: (
		boxTypeKey: string,
		attributeTypeKey: string,
		state: boolean
	) => void;
}

function BoxTypes(props: BoxTypesProps) {
	return props.boxTypes ? (
		<div>
			{Object.keys(props.boxTypes).map((key) => {
				return (
					<BoxType
						key={key}
						boxTypeKey={key}
						onAttributeTypeSelectionChange={
							props.onAttributeTypeSelectionChange
						}
						boxType={props.boxTypes[key]}
					/>
				);
			})}
		</div>
	) : null;
}

function AssociationAttributeTypes(props: AssociationAttributeTypesProps) {
	return (
		<div>
			{props.attributeTypes !== undefined
				? Object.keys(props.attributeTypes)
					.filter(
						(key: string) =>
							props.attributeTypes[key].valueType ===
							ASSOCIATION_VALUE_TYPE &&
							(!props.attributeTypes[key]
								.permittedAssociationBoxTypes ||
								props.attributeTypes[key]
									.permittedAssociationBoxTypes === "")
					)
					.map((attributeTypeKey, attributeTypeIndex) => {
						return (
							<div key={attributeTypeKey}>
								<Checkbox
									onChange={(e: CheckboxChangeEvent) => {
										props.onAttributeTypeSelectionChange(
											props.boxTypeKey,
											attributeTypeKey,
											e.target.checked
										);
									}}
									name={props.boxTypeKey}
								>
									{
										props.attributeTypes[
											attributeTypeKey
										].name
									}
								</Checkbox>
							</div>
						);
					})
				: null}
		</div>
	);
}

function BoxType(props: BoxTypeProps) {
	return (
		<div key={props.boxTypeKey} style={{ marginBottom: "16px" }}>
			<div>
				<span style={{ color: "rgba(0, 0, 0, 0.45)" }}>Type: </span>
				<span style={{ color: "rgba(0, 0, 0, 0.85)" }}>
					{props.boxType.name}
				</span>
			</div>
			<AssociationAttributeTypes
				boxTypeKey={props.boxTypeKey}
				attributeTypes={props.boxType.attributeTypes}
				onAttributeTypeSelectionChange={
					props.onAttributeTypeSelectionChange
				}
			/>
		</div>
	);
}

export default class CreateAssociationsDialog extends React.Component<
	CreateAssociationsDialogProps,
	CreateAssociationsDialogState
> {
	constructor(props: CreateAssociationsDialogProps) {
		super(props);

		const updatedState = this.updateState(
			this.props.firstSelectedBoxKey,
			this.props.boxSelectionInfoMap,
			this.props.flattenedBoxMap,
			this.props.boxTypeMap
		);

		let mappings: GenericMap<Mapping> = {};

		if (updatedState.mappingViewModels) {
			console.log("we have some view models!");
			const mappingViewModels: GenericMap<MappingViewModel> =
				updatedState.mappingViewModels;

			if (mappingViewModels) {
				Object.keys(mappingViewModels).forEach((mappingKey) => {
					const mappingViewModel = mappingViewModels[mappingKey];

					console.log(
						"To length is: " +
						Object.keys(mappingViewModel.to.attributeTypes)
							.length
					);
					console.log(
						"From length is: " +
						Object.keys(mappingViewModel.from.attributeTypes)
							.length
					);
					if (
						mappingViewModel.from &&
						mappingViewModel.from.attributeTypes &&
						Object.keys(mappingViewModel.from.attributeTypes)
							.length === 1
					) {
						console.log("Hello!!!!!");
						const attributeTypeKey = Object.keys(
							mappingViewModel.from.attributeTypes
						)[0];
						mappings = this.selectSourceBoxTypeAttributeWithoutState(
							mappingKey,
							attributeTypeKey,
							mappings
						);
					}

					if (
						mappingViewModel.to &&
						mappingViewModel.to.attributeTypes &&
						Object.keys(mappingViewModel.to.attributeTypes)
							.length === 1
					) {
						console.log("Hello to you too!!!!!");
						const attributeTypeKey = Object.keys(
							mappingViewModel.to.attributeTypes
						)[0];
						mappings = this.selectDestinationBoxTypeAttributeWithoutState(
							mappingKey,
							mappingKey,
							attributeTypeKey,
							mappings
						);
					}
				});
			}
		}

		updatedState.mappings = mappings;

		console.log("Toot toot");
		console.log(mappings);

		this.state = updatedState;
	}

	private updateState(
		firstSelectedBoxKey: string,
		boxSelectionInfoMap: any,
		flattenedBoxMap: boxLib.BoxMap,
		boxTypeMap: boxTypeLib.BoxTypeMap
	): any {
		const updatedBoxTypeMap = JSON.parse(JSON.stringify(boxTypeMap));

		const updatedFlattenedBoxMap = JSON.parse(
			JSON.stringify(flattenedBoxMap)
		);

		// Get the box for our first box key
		const firstSelectedBox = updatedFlattenedBoxMap[firstSelectedBoxKey];

		const destinationBoxKeys = JSON.parse(
			JSON.stringify(boxSelectionInfoMap)
		);

		// Delete the first box from the info map
		delete destinationBoxKeys[firstSelectedBoxKey];

		const destinationBoxes: boxLib.BoxMap = {};

		const destinationBoxTypes: boxTypeLib.BoxTypeMap = {};

		// Build the destination boxes list and the destination box types list
		for (let key of Object.keys(destinationBoxKeys)) {
			destinationBoxes[key] = updatedFlattenedBoxMap[key];
			let boxType = destinationBoxes[key].boxType;
			destinationBoxTypes[boxType] = updatedBoxTypeMap[boxType];
		}

		const sourceBoxTypeKey = firstSelectedBox.boxType;

		const sourceBoxType: boxTypeLib.BoxType | null =
			updatedBoxTypeMap[sourceBoxTypeKey];

		// Begin experiment
		let allMappings: GenericMap<MappingViewModel> = {};

		// If we have a source box type
		if (sourceBoxType) {
			// Loop through all our destination box types
			Object.keys(destinationBoxTypes).forEach(
				(destinationBoxTypeKey) => {
					const destinationBoxType =
						destinationBoxTypes[destinationBoxTypeKey];

					const fromAttributeTypes: GenericMap<AttributeTypeViewModel> = {};
					const toAttributeTypes: GenericMap<AttributeTypeViewModel> = {};

					// The fromAttributeTypes need to be all the attributes on source box type that match
					// the type of the destination box type
					Object.keys(sourceBoxType.attributeTypes)
						.filter((attributeTypeKey) => {
							const attributeType =
								sourceBoxType.attributeTypes[attributeTypeKey];
							return (
								attributeType.permittedAssociationBoxTypes &&
								attributeType.permittedAssociationBoxTypes
									.split(",")
									.indexOf(destinationBoxTypeKey) >= 0
							);
						})
						.forEach((attributeTypeKey) => {
							const attributeType =
								sourceBoxType.attributeTypes[attributeTypeKey];
							fromAttributeTypes[attributeTypeKey] = {
								name: attributeType.name,
							};
						});

					// The toAttributeTypes need to be all the attributes on the destination box type that
					// match the type of the source box type
					Object.keys(destinationBoxType.attributeTypes)
						.filter((attribiteTypeKey) => {
							const attributeType =
								destinationBoxType.attributeTypes[
								attribiteTypeKey
								];
							return (
								attributeType.permittedAssociationBoxTypes &&
								attributeType.permittedAssociationBoxTypes
									.split(",")
									.indexOf(sourceBoxTypeKey) >= 0
							);
						})
						.forEach((attributeTypeKey) => {
							const attributeType =
								destinationBoxType.attributeTypes[
								attributeTypeKey
								];
							toAttributeTypes[attributeTypeKey] = {
								name: attributeType.name,
							};
						});

					// Create the mapping view model
					const mapping: MappingViewModel = {
						name: destinationBoxType.name,
						from: {
							name: sourceBoxType.name,
							attributeTypes: fromAttributeTypes,
						},
						to: {
							name: destinationBoxType.name,
							attributeTypes: toAttributeTypes,
						},
					};

					// Push the mapping view model onto our collection of all mappings
					allMappings[destinationBoxTypeKey] = mapping;
				}
			);
		}

		// Get all of the association attributes from the destination boxes.
		// We're going to want to split these up into ones that have specific types
		// that we can match to and ones that don't

		// console.log(newStructure);

		return {
			sourceBox: firstSelectedBox,
			sourceBoxType: sourceBoxType,
			destinationBoxes: destinationBoxes,
			destinationBoxTypes: destinationBoxTypes,
			mappingViewModels: allMappings,
		};
	}

	private addStringToArray(key: string, array: string[]) {
		// console.log(`adding ${key} to ${JSON.stringify(array)}`);
		const arrayCopy = JSON.parse(JSON.stringify(array));

		const index = arrayCopy.indexOf(key, 0);

		// If the item doesn't exist on the array
		if (index < 0) {
			// Add it
			arrayCopy.push(key);
		}

		return arrayCopy;
	}

	private removeStringFromArray(key: string, array: string[]) {
		// console.log(`removing ${key} from ${JSON.stringify(array)}`);
		const arrayCopy = JSON.parse(JSON.stringify(array));

		// Get the index of the key in our array
		const index = arrayCopy.indexOf(key, 0);

		// If the item does exist
		if (index > -1) {
			// Remove it
			arrayCopy.splice(index, 1);
		}

		return arrayCopy;
	}

	// TODO: Get rid of the repetition here
	private selectSourceBoxTypeAttributeWithoutState(
		mappingKey: string,
		attributeTypeKey: string,
		originalMappings: GenericMap<Mapping>
	) {
		let mappings = JSON.parse(JSON.stringify(originalMappings));

		let mapping = mappings[mappingKey];

		if (!mapping) {
			mapping = {
				selectedDestinationBoxTypeAttributes: {},
				selectedSourceAttributeTypes: [],
			};
		}

		const selectedSourceAttributeTypes = this.addStringToArray(
			attributeTypeKey,
			mapping.selectedSourceAttributeTypes
		);

		mapping.selectedSourceAttributeTypes = selectedSourceAttributeTypes;
		mappings[mappingKey] = mapping;

		return mappings;
	}

	// TODO: Get rid of the repetition here
	private selectDestinationBoxTypeAttributeWithoutState(
		mappingKey: string,
		boxTypeKey: string,
		attributeTypeKey: string,
		originalMappings: GenericMap<Mapping>
	) {
		let mappings = JSON.parse(JSON.stringify(originalMappings));

		let mapping = mappings[mappingKey];

		if (!mapping) {
			mapping = {
				selectedDestinationBoxTypeAttributes: {},
				selectedSourceAttributeTypes: [],
			};
		}

		if (
			mapping.selectedDestinationBoxTypeAttributes[boxTypeKey] ===
			undefined
		) {
			// console.log("needed to re-create the array");
			mapping.selectedDestinationBoxTypeAttributes[boxTypeKey] = [];
		}

		mapping.selectedDestinationBoxTypeAttributes[
			boxTypeKey
		] = this.addStringToArray(
			attributeTypeKey,
			mapping.selectedDestinationBoxTypeAttributes[boxTypeKey]
		);

		mappings[mappingKey] = mapping;

		// console.log("select: New selectedDestinationBoxTypeAttributes is: " + JSON.stringify(selectedDestinationBoxTypeAttributes));
		return mappings;
	}

	private selectSourceBoxTypeAttribute(
		mappingKey: string,
		attributeTypeKey: string,
		originalMappings: GenericMap<Mapping> = this.state.mappings
	) {
		let mappings = JSON.parse(JSON.stringify(originalMappings));

		let mapping = mappings[mappingKey];

		if (!mapping) {
			mapping = {
				selectedDestinationBoxTypeAttributes: {},
				selectedSourceAttributeTypes: [],
			};
		}

		const selectedSourceAttributeTypes = this.addStringToArray(
			attributeTypeKey,
			mapping.selectedSourceAttributeTypes
		);

		mapping.selectedSourceAttributeTypes = selectedSourceAttributeTypes;
		mappings[mappingKey] = mapping;

		this.setState({ mappings });
	}

	private selectDestinationBoxTypeAttribute(
		mappingKey: string,
		boxTypeKey: string,
		attributeTypeKey: string,
		originalMappings: GenericMap<Mapping> = this.state.mappings
	) {
		let mappings = JSON.parse(JSON.stringify(originalMappings));

		let mapping = mappings[mappingKey];

		if (!mapping) {
			mapping = {
				selectedDestinationBoxTypeAttributes: {},
				selectedSourceAttributeTypes: [],
			};
		}

		if (
			mapping.selectedDestinationBoxTypeAttributes[boxTypeKey] ===
			undefined
		) {
			// console.log("needed to re-create the array");
			mapping.selectedDestinationBoxTypeAttributes[boxTypeKey] = [];
		}

		mapping.selectedDestinationBoxTypeAttributes[
			boxTypeKey
		] = this.addStringToArray(
			attributeTypeKey,
			mapping.selectedDestinationBoxTypeAttributes[boxTypeKey]
		);

		mappings[mappingKey] = mapping;

		// console.log("select: New selectedDestinationBoxTypeAttributes is: " + JSON.stringify(selectedDestinationBoxTypeAttributes));
		this.setState({ mappings });
	}

	private removeDestinationBoxTypeAttribute(
		mappingKey: string,
		boxTypeKey: string,
		attributeTypeKey: string,
		originalMappings: GenericMap<Mapping> = this.state.mappings
	) {
		let mappings = JSON.parse(JSON.stringify(originalMappings));
		let mapping = mappings[mappingKey];

		if (!mapping) {
			mapping = {
				selectedDestinationBoxTypeAttributes: {},
				selectedSourceAttributeTypes: [],
			};
		}

		if (
			mapping.selectedDestinationBoxTypeAttributes[boxTypeKey] ===
			undefined
		) {
			// console.log("needed to re-create the array");
			mapping.selectedDestinationBoxTypeAttributes[boxTypeKey] = [];
		}

		mapping.selectedDestinationBoxTypeAttributes[
			boxTypeKey
		] = this.removeStringFromArray(
			attributeTypeKey,
			mapping.selectedDestinationBoxTypeAttributes[boxTypeKey]
		);

		mappings[mappingKey] = mapping;

		// console.log("select: New selectedDestinationBoxTypeAttributes is: " + JSON.stringify(selectedDestinationBoxTypeAttributes));
		this.setState({ mappings });
	}

	private removeSourceBoxTypeAttribute(
		mappingKey: string,
		attributeTypeKey: string,
		originalMappings: GenericMap<Mapping> = this.state.mappings
	) {
		let mappings = JSON.parse(JSON.stringify(originalMappings));

		let mapping = mappings[mappingKey];

		if (!mapping) {
			mapping = {
				selectedDestinationBoxTypeAttributes: {},
				selectedSourceAttributeTypes: [],
			};
		}

		const selectedSourceAttributeTypes = this.removeStringFromArray(
			attributeTypeKey,
			mapping.selectedSourceAttributeTypes
		);

		mapping.selectedSourceAttributeTypes = selectedSourceAttributeTypes;
		mappings[mappingKey] = mapping;

		this.setState({ mappings });
	}

	public componentWillReceiveProps(nextProps: CreateAssociationsDialogProps) {
		console.log("someone say props?");
		// Only update if we're showing the dialog
		if (!this.props.isVisible && nextProps.isVisible) {
			const updatedState = this.updateState(
				this.props.firstSelectedBoxKey,
				this.props.boxSelectionInfoMap,
				this.props.flattenedBoxMap,
				this.props.boxTypeMap
			);

			this.setState(updatedState);
		}
	}

	private getSourceCheckedState = (
		mappingKey: string,
		attributeTypeKey: string
	): boolean => {
		return (
			this.state.mappings !== undefined &&
			this.state.mappings[mappingKey] !== undefined &&
			this.state.mappings[mappingKey].selectedSourceAttributeTypes !==
			undefined &&
			this.state.mappings[
				mappingKey
			].selectedSourceAttributeTypes.indexOf(attributeTypeKey) >= 0
		);
	};

	private getDestinationCheckedState = (
		mappingKey: string,
		attributeTypeKey: string
	): boolean => {
		return (
			this.state.mappings !== undefined &&
			this.state.mappings[mappingKey] !== undefined &&
			this.state.mappings[mappingKey]
				.selectedDestinationBoxTypeAttributes !== undefined &&
			this.state.mappings[mappingKey]
				.selectedDestinationBoxTypeAttributes[mappingKey] !==
			undefined &&
			this.state.mappings[
				mappingKey
			].selectedDestinationBoxTypeAttributes[mappingKey].indexOf(
				attributeTypeKey
			) >= 0
		);
	};

	public render() {
		return (
			<Modal
				title={"Create Associations"}
				open={this.props.isVisible}
				onOk={this.handleOKButtonClick}
				onCancel={this.props.onCancelButtonClick}
				width="75%"
				zIndex={9999}
			>
				<div>
					{this.state.mappingViewModels ? (
						<Collapse
							defaultActiveKey={Object.keys(
								this.state.mappingViewModels
							)}
						>
							{Object.keys(this.state.mappingViewModels).map(
								(destinationBoxTypeKey) => {
									const mapping = this.state
										.mappingViewModels[
										destinationBoxTypeKey
									];

									return (
										<Panel
											header={mapping.name}
											key={destinationBoxTypeKey}
										>
											<div>
												<Row gutter={6}>
													<Col span={12}>
														<Card
															title={`From: ${mapping.from.name}`}
														>
															{Object.keys(
																mapping.from
																	.attributeTypes
															).map(
																(
																	attributeTypeKey
																) => {
																	const attributeType =
																		mapping
																			.from
																			.attributeTypes[
																		attributeTypeKey
																		];
																	return (
																		<div
																			key={
																				attributeTypeKey
																			}
																		>
																			<Checkbox
																				checked={this.getSourceCheckedState(
																					destinationBoxTypeKey,
																					attributeTypeKey
																				)}
																				onChange={(
																					e: CheckboxChangeEvent
																				) => {
																					if (
																						e
																							.target
																							.checked
																					) {
																						this.selectSourceBoxTypeAttribute(
																							destinationBoxTypeKey,
																							attributeTypeKey
																						);
																					} else {
																						this.removeSourceBoxTypeAttribute(
																							destinationBoxTypeKey,
																							attributeTypeKey
																						);
																					}
																				}}
																				name={
																					attributeTypeKey
																				}
																			>
																				{
																					attributeType.name
																				}
																			</Checkbox>
																		</div>
																	);
																}
															)}
														</Card>
													</Col>
													<Col span={12}>
														<Card
															title={`To: ${mapping.to.name}`}
														>
															{Object.keys(
																mapping.to
																	.attributeTypes
															).map(
																(
																	attributeTypeKey
																) => {
																	const attributeType =
																		mapping
																			.to
																			.attributeTypes[
																		attributeTypeKey
																		];
																	return (
																		<div
																			key={
																				attributeTypeKey
																			}
																		>
																			<Checkbox
																				checked={this.getDestinationCheckedState(
																					destinationBoxTypeKey,
																					attributeTypeKey
																				)}
																				onChange={(
																					e: CheckboxChangeEvent
																				) => {
																					if (
																						e
																							.target
																							.checked
																					) {
																						this.selectDestinationBoxTypeAttribute(
																							destinationBoxTypeKey,
																							destinationBoxTypeKey,
																							attributeTypeKey
																						);
																					} else {
																						this.removeDestinationBoxTypeAttribute(
																							destinationBoxTypeKey,
																							destinationBoxTypeKey,
																							attributeTypeKey
																						);
																					}
																				}}
																				name={
																					attributeTypeKey
																				}
																			>
																				{
																					attributeType.name
																				}
																			</Checkbox>
																		</div>
																	);
																}
															)}
														</Card>
													</Col>
												</Row>
											</div>
										</Panel>
									);
								}
							)}
							<Panel key="untyped" header="Untyped Mappings">
								<Row gutter={6}>
									<Col span={12}>
										<Card
											title="From"
											style={{ marginLeft: "16px" }}
										>
											<BoxType
												boxType={
													this.state.sourceBoxType
												}
												boxTypeKey={
													this.state.sourceBox.boxType
												}
												onAttributeTypeSelectionChange={(
													boxTypeKey: string,
													attributeTypeKey: string,
													state: boolean
												) => {
													// console.log(`boxTypeKey: ${boxTypeKey}, attributeTypeKey: ${attributeTypeKey}, state: ${state}`);

													// console.log("before: selected source attributes are:" + JSON.stringify(this.state.selectedSourceAttributeTypes));
													if (state) {
														this.selectSourceBoxTypeAttribute(
															UNTYPED_BOX_KEY,
															attributeTypeKey
														);
													} else {
														this.removeSourceBoxTypeAttribute(
															UNTYPED_BOX_KEY,
															attributeTypeKey
														);
													}

													// console.log("after: selected source attributes are:" + JSON.stringify(this.state.selectedSourceAttributeTypes));
												}}
											/>
										</Card>
									</Col>

									<Col span={12}>
										<Card title="To">
											{
												<BoxTypes
													boxTypes={
														this.state
															.destinationBoxTypes
													}
													onAttributeTypeSelectionChange={(
														boxTypeKey: string,
														attributeTypeKey: string,
														state: boolean
													) => {
														// console.log(`boxTypeKey: ${boxTypeKey}, attributeTypeKey: ${attributeTypeKey}, state: ${state}`);

														// console.log("before: selected destination attributes are:" + JSON.stringify(this.state.selectedDestinationBoxTypeAttributes));
														if (state) {
															this.selectDestinationBoxTypeAttribute(
																UNTYPED_BOX_KEY,
																boxTypeKey,
																attributeTypeKey
															);
														} else {
															this.removeDestinationBoxTypeAttribute(
																UNTYPED_BOX_KEY,
																boxTypeKey,
																attributeTypeKey
															);
														}

														// console.log("after: selected destination attributes are:" + JSON.stringify(this.state.selectedDestinationBoxTypeAttributes));
													}}
												/>
											}
										</Card>
									</Col>
								</Row>
							</Panel>
						</Collapse>
					) : null}
				</div>
			</Modal>
		);
	}

	private handleInputChange = (event: any) => {
		// var illustration = JSON.parse(JSON.stringify(this.state.illustration));
		// illustration[event.target.name] = event.target.value;
		// this.setState({ illustration: illustration });
	};

	private handleOKButtonClick = () => {
		// console.log("source:" + JSON.stringify(this.state.selectedSourceAttributeTypes));
		// console.log("destination:" + JSON.stringify(this.state.selectedDestinationBoxTypeAttributes));

		// We want to add the source box to every attribute we've selected on the destination boxes
		const updatedBoxMap: boxLib.BoxMap = this.state.destinationBoxes;

		const sourceBoxKey = this.props.firstSelectedBoxKey;
		// console.log("Keys are!" + Object.keys(this.state.selectedDestinationBoxTypeAttributes));

		// Iterate through our list of box types
		if (this.state.mappings) {
			console.log("Mappings are...");
			console.log(this.state.mappings);
			Object.keys(this.state.mappings).forEach((mappingKey) => {
				const mapping = this.state.mappings[mappingKey];

				// Iterate through all the selected destination box types and add the source box
				Object.keys(
					mapping.selectedDestinationBoxTypeAttributes
				).forEach((boxTypeKey: string) => {
					console.log(
						"inside the selectedDestinationBoxTypeAttributes loop!" +
						boxTypeKey
					);

					console.log(updatedBoxMap);

					// Grab all the boxes that have a matching box type
					Object.keys(updatedBoxMap)
						.filter((boxKey: string) => {
							return (updatedBoxMap[boxKey].boxType === undefined && boxTypeKey === 'undefined')
								|| updatedBoxMap[boxKey].boxType === boxTypeKey;
						})
						.forEach((boxKey: string) => {
							console.log("inside the box loop!");
							if (
								updatedBoxMap &&
								updatedBoxMap[boxKey] !== undefined &&
								updatedBoxMap[boxKey] !== null &&
								boxKey &&
								updatedBoxMap[boxKey].attributes
							) {
								// Iterate through each one of the attributes in the array
								const attributeTypes =
									mapping
										.selectedDestinationBoxTypeAttributes[
									boxTypeKey
									];

								console.log(
									"inside the box loop if statement!"
								);
								if (attributeTypes) {
									attributeTypes.forEach(
										(attributeTypeKey: string) => {
											const box = updatedBoxMap[boxKey];
											if (
												box !== null &&
												box.attributes !== undefined &&
												box.attributes !== null
											) {
												// Grab the existing value
												console.log(
													"about to map the values for " +
													attributeTypeKey
												);
												const attributeValue =
													String(box.attributes[
														attributeTypeKey
													]);

												let boxKeys =
													attributeValue &&
														attributeValue.length > 0
														? attributeValue.split(
															","
														)
														: [];

												// Add the source box
												box.attributes[
													attributeTypeKey
												] = this.addStringToArray(
													sourceBoxKey,
													boxKeys
												).join(",");
											}
										}
									);
								}
							}
						});
				});

				const updatedSourceBox = this.state.sourceBox;
				// We want to add the destination boxes to every attribute that we've selected on the source box
				if (updatedSourceBox) {
					// Iterate through each one of the attributes in the array
					const attributeTypes = mapping.selectedSourceAttributeTypes;

					// console.log("Iterating through each one of " + JSON.stringify(attributeTypes));

					if (attributeTypes) {
						attributeTypes.forEach((attributeTypeKey: string) => {
							const box = updatedSourceBox;
							if (
								box.attributes !== undefined &&
								box.attributes !== null
							) {
								// Grab the existing value
								const attributeValue = String(box.attributes[attributeTypeKey]);

								let boxKeys =
									attributeValue && attributeValue.length > 0
										? attributeValue.split(",")
										: [];

								console.log("selected atts");

								console.log(
									mapping.selectedDestinationBoxTypeAttributes
								);

								// Add the source box
								Object.keys(this.state.destinationBoxes)
									.filter((boxKey: string) => {
										return (
											Object.keys(
												mapping.selectedDestinationBoxTypeAttributes
											).indexOf(
												this.state.destinationBoxes[
													boxKey
												].boxType
											) >= 0
										);
									})
									.forEach((boxKey: string) => {
										boxKeys = this.addStringToArray(
											boxKey,
											boxKeys
										);
									});
								box.attributes[attributeTypeKey] = boxKeys.join(
									","
								);
							}
						});
					}

					// Add the source box to our list of updated boxes
					updatedBoxMap[sourceBoxKey] = updatedSourceBox;
				}
			});
		}

		// Notify the callback that the OK button has been clicked, passing the new
		// box name and box type key
		this.props.onOKButtonClick(updatedBoxMap);
	};
}
