import React from 'react';

import * as attributeLib from "@lib/box/attribute";
import * as boxLib from '@lib/box/box';
import * as boxTypeLib from '@lib/box/box-type';
import * as boxVisualisationLib from '@lib/box/box-visualisation';
import { BoxStyleMap } from '@lib/box/box-style';
import { ChildLayout, Properties } from '@lib/box/box-properties'

import {
	BoxDimensions,
	calculateBoxDimensionsInfo
} from "@lib/layout/layout";

import { Box } from './Box';

interface BoxesProps {
	registerHighlightedBoxChangeListener: (boxKey: string,
		listener: (boxKey: string) => void) => void;
	unregisterHighlightedBoxChangeListener: (boxKey: string) => void;
	onHighlightedBoxChange: (boxKey: string) => void;

	onBoxClick: ((boxKey: string, ctrlKey: boolean, shiftKey: boolean) => void),
	onBoxHeaderChangeAccept: ((boxKey: string, boxText: string) => void),
	onBoxAddChild: ((boxKey: string) => void),
	onBoxRemoveChild: ((boxKey: string) => void),
	onBoxChildLayoutChange: ((boxKey: string, childLayout: string) => void),
	onBoxCanDrop: ((dragSourceBoxKey: string,
		dragSourceBoxParentKey: string,
		dropTargetBoxKey: string,
		dropTargetBoxParentKey: string) => boolean),
	onBoxDragStart: ((dragSourceBoxKey: string, dragSourceBoxParentKey: string) => void),
	onBoxDragCancel: (() => void),
	onBoxDragHover: ((dragSourceBoxKey: string,
		dragSourceBoxParentKey: string,
		dropTargetBoxKey: string,
		dropTargetBoxParentKey: string,
		sortedBoxKeys: string[],
		dragX: number,
		dragY: number) => void),
	onBoxDragDrop: ((dragSourceBoxKey: string,
		dragSourceBoxParentKey: string,
		dropTargetBoxKey: string,
		dropTargetBoxParentKey: string,
		sortedBoxKeys: string[]) => void),
	leftInPixels: number,
	topInPixels: number,
	widthInPixels: number,
	boxesWidthInPixels: number,
	heightInPixels: number,
	boxesHeightInPixels: number,
	horizontalBoxGapInPixels: number,
	verticalBoxGapInPixels: number,
	childLayout: ChildLayout,
	boxesKey: string,
	boxes: boxLib.BoxMap | undefined,
	illustrationFlattenedBoxMap: boxLib.BoxMap | undefined;
	boxTypeKey: string,
	boxTypes: boxTypeLib.BoxTypeMap | undefined,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined,
	boxVisibilityMap: boxLib.BoxVisibilityMap | undefined,
	boxSelectionInfoMap: boxLib.BoxSelectionInfoMap | undefined,
	boxVisualisations: boxVisualisationLib.BoxVisualisationMap | undefined,
	boxStyles: BoxStyleMap | undefined,
	boxParentTypeKey: string,
	boxParentProperties: Properties | undefined,
	boxParentAttributes: attributeLib.AttributeMap | undefined,
	boxAssociationsMap: boxLib.BoxAssociationsMap | undefined,
	currentDragSourceBoxKey: string,
	currentlyHighlightedDropTargetBoxKey: string,
	hasChildren: boolean,
	canAddChildren: boolean,
	canRemoveChildren: boolean,
	canRemove: boolean,
	canDragChildren: boolean,
	canDrag: boolean,
	canChangeChildLayout: boolean,
	canHighlightAssociations: boolean,
}

const getTransformStyle = (left: number,
	top: number,
	width: number,
	height: number): Object => {
	// Replace unitless items with px
	const translate = `translate(${left}px,${top}px)`;
	return {
		transform: translate,
		WebkitTransform: translate,
		MozTransform: translate,
		msTransform: translate,
		OTransform: translate,
		width: `${width}px`,
		height: `${height}px`,
		position: 'absolute'
	};
}

// const getDragHandleClassName = (boxesKey: string) => `handle-${boxesKey}`

export const Boxes = (props: BoxesProps) => {
	const {
		registerHighlightedBoxChangeListener,
		unregisterHighlightedBoxChangeListener,
		onHighlightedBoxChange,
		onBoxClick,
		onBoxHeaderChangeAccept,
		onBoxAddChild,
		onBoxRemoveChild,
		onBoxChildLayoutChange,
		onBoxCanDrop,
		onBoxDragStart,
		onBoxDragCancel,
		onBoxDragHover,
		onBoxDragDrop,
		leftInPixels,
		topInPixels,
		boxesWidthInPixels,
		boxesHeightInPixels,
		horizontalBoxGapInPixels,
		verticalBoxGapInPixels,
		childLayout,
		boxesKey,
		boxes,
		illustrationFlattenedBoxMap,
		boxTypeKey,
		boxTypes,
		boxTypeVisibilityMap,
		boxVisibilityMap,
		boxSelectionInfoMap,
		boxVisualisations,
		boxStyles,
		boxParentTypeKey,
		boxParentProperties,
		boxParentAttributes,
		boxAssociationsMap,
		currentDragSourceBoxKey,
		currentlyHighlightedDropTargetBoxKey,
		hasChildren,
		canAddChildren,
		canRemoveChildren,
		canRemove,
		canDragChildren,
		canDrag,
		canChangeChildLayout,
		canHighlightAssociations,
	} = props;

	if (!hasChildren || !boxes) {
		return null;
	}

	let maximumGridColumns = 1;
	try {
		if (boxParentProperties && boxParentProperties.maximumGridColumns) {
			maximumGridColumns = parseInt(boxParentProperties.maximumGridColumns);
		}
	} catch (e) {}


	const boxParentAttributeTypes = boxTypeLib
		.getBoxTypeAttributeTypeCacheForType(boxParentTypeKey);

	// Get the box keys
	const boxKeys = Object.keys(boxes);

	const boxDimensionsInfo = calculateBoxDimensionsInfo(childLayout,
		boxesWidthInPixels,
		boxesHeightInPixels,
		horizontalBoxGapInPixels,
		verticalBoxGapInPixels,
		maximumGridColumns,
		boxKeys,
		boxesKey,
		boxes,
		boxTypes,
		boxTypeVisibilityMap,
		boxVisibilityMap,
		boxVisualisations,
		boxStyles,
		boxParentTypeKey,
		boxParentProperties,
		boxParentAttributeTypes,
		boxParentAttributes,
		boxAssociationsMap,
		currentDragSourceBoxKey,
		currentlyHighlightedDropTargetBoxKey,
		canHighlightAssociations,
		illustrationFlattenedBoxMap);

	const layoutItems = boxDimensionsInfo.layoutItems;
	const layoutDimensions = boxDimensionsInfo.layoutDimensions;

	const renderedBoxes = boxDimensionsInfo.boxDimensions
		.map((boxDimensions: BoxDimensions) => {
			// Get the box key
			const boxKey = boxDimensions.boxKey;

			// Get the box
			const currentBox = boxes[boxKey];

			const boxLeftInPixels = boxDimensions.leftInPixels;
			const boxTopInPixels = boxDimensions.topInPixels;
			const boxWidthInPixels = boxDimensions.widthInPixels;
			const boxHeightInPixels = boxDimensions.heightInPixels;

			// Get the box style
			const boxStyle = getTransformStyle(boxLeftInPixels,
				boxTopInPixels,
				boxWidthInPixels,
				boxHeightInPixels);

			// Render the box
			return (
				<Box
					key={boxKey}
					registerHighlightedBoxChangeListener={registerHighlightedBoxChangeListener}
					unregisterHighlightedBoxChangeListener={unregisterHighlightedBoxChangeListener}
					onHighlightedBoxChange={onHighlightedBoxChange}
					onBoxClick={onBoxClick}
					onBoxHeaderChangeAccept={onBoxHeaderChangeAccept}
					onBoxAddChild={onBoxAddChild}
					onBoxRemoveChild={onBoxRemoveChild}
					onBoxChildLayoutChange={onBoxChildLayoutChange}
					onBoxCanDrop={onBoxCanDrop}
					onBoxDragStart={onBoxDragStart}
					onBoxDragCancel={onBoxDragCancel}
					onBoxDragHover={onBoxDragHover}
					onBoxDragDrop={onBoxDragDrop}
					widthInPixels={boxWidthInPixels}
					heightInPixels={boxHeightInPixels}
					horizontalBoxGapInPixels={horizontalBoxGapInPixels}
					verticalBoxGapInPixels={verticalBoxGapInPixels}
					baseStyle={boxStyle}
					boxKey={boxKey}
					boxParentKey={boxesKey}
					boxParentLayout={layoutItems}
					boxParentChildLayout={childLayout}
					boxParentGridColumnWidthInPixels={layoutDimensions.gridColumnWidthInPixels}
					boxParentGridRowHeightInPixels={layoutDimensions.gridRowHeightInPixels}
					box={currentBox}
					boxTypeKey={boxTypeKey}
					illustrationFlattenedBoxMap={illustrationFlattenedBoxMap}
					boxTypes={boxTypes}
					boxTypeVisibilityMap={boxTypeVisibilityMap}
					boxVisibilityMap={boxVisibilityMap}
					boxSelectionInfoMap={boxSelectionInfoMap}
					boxVisualisations={boxVisualisations}
					boxStyles={boxStyles}
					boxParentTypeKey={boxParentTypeKey}
					boxParentProperties={boxParentProperties}
					boxParentAttributes={boxParentAttributes}
					boxAssociationsMap={boxAssociationsMap}
					currentDragSourceBoxKey={currentDragSourceBoxKey}
					currentlyHighlightedDropTargetBoxKey={currentlyHighlightedDropTargetBoxKey}
					showHeader={true}
					canAddChildren={canAddChildren}
					canRemoveChildren={canRemoveChildren}
					canRemove={canRemove}
					canDragChildren={canDragChildren}
					canDrag={canDrag}
					canChangeChildLayout={canChangeChildLayout}
					canHighlightAssociations={canHighlightAssociations}
				/>
			);
		});

	return (
		<div
			style={{
				display: "inline-block",
				position: "absolute",
				left: leftInPixels,
				top: topInPixels,
				width: layoutDimensions.gridWidthInPixels,
				height: layoutDimensions.gridHeightInPixels,
				lineHeight: "0px",
				marginTop: 0,
				marginLeft: 0,
				marginRight: 0,
				marginBottom: 0,
				paddingTop: 0,
				paddingLeft: 0,
				paddingRight: 0,
				paddingBottom: 0,
			}}
		>
			{renderedBoxes}
		</div>
	)
}

export const MemoizedBoxes = React.memo(Boxes)
