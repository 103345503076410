import * as React from "react";

import * as attributeLib from "@lib/box/attribute";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as boxLib from "@lib/box/box";
import * as boxTypeLib from "@lib/box/box-type";
import * as boxVisualisationLib from "@lib/box/box-visualisation";
import {
	ChildLayout,
	Properties,
	getBackgroundImage,
	getChildLayout
} from "@lib/box/box-properties";
import { BoxStyleMap } from "@lib/box/box-style";
import {
	getBoxDimensions
} from "@lib/box/box-dimensions";
import { LayoutItem } from "@lib/layout/layout";

import { MemoizedBadges } from '../badges/Badges'

import { Boxes } from "./Boxes";
import { MemoizedBoxHeader } from "./BoxHeader";
import { BoxButtonAdd } from './BoxButtonAdd'
import { BoxButtonRemove } from './BoxButtonRemove'
import { BoxButtonToggleChildLayout } from './BoxButtonToggleChildLayout'
import { BoxSelection } from './BoxSelection'

const DEFAULT_BADGE_SIZE_IN_PIXELS: number = 24;
const DEFAULT_BADGE_OFFSET_IN_PIXELS: number = Math.floor(
	DEFAULT_BADGE_SIZE_IN_PIXELS / 2
);

const SELECTION_BORDER_RADIUS_IN_PIXELS: number = 8;

const getItemStyle = (boxProperties: Properties,
	baseStyle: React.CSSProperties,
	boxWidthInPixels: number,
	boxHeightInPixels: number,
	boxHorizontalMarginInPixels: number,
	boxVerticalMarginInPixels: number,
	backgroundImage: string): React.CSSProperties => ({
		display: "inline-block",
		position: "relative",
		width: boxWidthInPixels,
		height: boxHeightInPixels,
		lineHeight: "0px",
		borderColor: boxProperties.borderColor,
		borderRadius: `${boxProperties.borderRadius}px`,
		borderWidth: `${boxProperties.borderSizeInPixels}px`,
		borderStyle: boxProperties.borderStyle,
		color: boxProperties.textColor,
		backgroundColor: boxProperties.backgroundColor,
		backgroundImage,
		backgroundRepeat: boxProperties.backgroundImageRepeat,
		backgroundPosition: boxProperties.backgroundImagePosition,
		backgroundAttachment: "scroll", // The background image will scroll with the page.
		backgroundClip: "padding-box", // The background extends to the inside edge of the border
		backgroundOrigin: "padding-box", // The background image starts from the upper left corner of the padding edge
		backgroundSize: boxProperties.backgroundImageSize,
		margin: 0,
		padding: 0,
		...baseStyle,
	});

export interface BoxBaseProps {
	onRender: (node: React.ReactElement) => React.ReactElement | null;

	registerHighlightedBoxChangeListener: (boxKey: string,
		listener: (boxKey: string) => void) => void;
	unregisterHighlightedBoxChangeListener: (boxKey: string) => void;
	onHighlightedBoxChange: (boxKey: string) => void;

	onBoxClick: (boxKey: string, ctrlKey: boolean, shiftKey: boolean) => void;
	onBoxHeaderChangeAccept: (boxKey: string, boxText: string) => void;
	onBoxAddChild: (boxKey: string) => void;
	onBoxRemoveChild: (boxKey: string) => void;
	onBoxChildLayoutChange: (boxKey: string, childLayout: string) => void;
	onBoxCanDrop: (
		dragSourceBoxKey: string,
		dragSourceBoxParentKey: string,
		dropTargetBoxKey: string,
		dropTargetBoxParentKey: string
	) => boolean;
	onBoxDragStart: (dragSourceBoxKey: string, dragSourceBoxParentKey: string) => void;
	onBoxDragCancel: () => void;
	onBoxDragHover: (
		dragSourceBoxKey: string,
		dragSourceBoxParentKey: string,
		dropTargetBoxKey: string,
		dropTargetBoxParentKey: string,
		sortedBoxKeys: string[],
		dragX: number,
		dragY: number
	) => void;
	onBoxDragDrop: (
		dragSourceBoxKey: string,
		dragSourceBoxParentKey: string,
		dropTargetBoxKey: string,
		dropTargetBoxParentKey: string,
		sortedBoxKeys: string[]
	) => void;

	// Box-specific props
	widthInPixels: number;
	heightInPixels: number;
	horizontalBoxGapInPixels: number;
	verticalBoxGapInPixels: number;
	baseStyle: React.CSSProperties;
	boxKey: string;
	boxParentKey: string;
	boxParentLayout: LayoutItem[] | undefined;
	boxParentChildLayout: ChildLayout;
	boxParentGridColumnWidthInPixels: number;
	boxParentGridRowHeightInPixels: number;
	box: boxLib.Box | undefined;
	boxTypeKey: string;
	boxParentTypeKey: string;
	boxParentProperties: Properties | undefined;
	boxParentAttributes: attributeLib.AttributeMap | undefined;
	showHeader: boolean;
	canRemove: boolean;
	canDrag: boolean;

	// Illustration data props
	illustrationFlattenedBoxMap: boxLib.BoxMap | undefined;
	boxTypes: boxTypeLib.BoxTypeMap | undefined;
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined;
	boxVisibilityMap: boxLib.BoxVisibilityMap | undefined;
	boxSelectionInfoMap: boxLib.BoxSelectionInfoMap | undefined;
	boxVisualisations: boxVisualisationLib.BoxVisualisationMap | undefined;
	boxStyles: BoxStyleMap | undefined;
	boxAssociationsMap: boxLib.BoxAssociationsMap | undefined;

	// Illustration flag props
	canAddChildren: boolean;
	canRemoveChildren: boolean;
	canDragChildren: boolean;
	canChangeChildLayout: boolean;
	canHighlightAssociations: boolean;

	// Illustration state props
	currentDragSourceBoxKey: string;
	currentlyHighlightedDropTargetBoxKey: string;
}

export interface BoxBaseState {
	isAddBoxButtonHovered: boolean;
	isRemoveBoxButtonHovered: boolean;
	isToggleChildLayoutButtonHovered: boolean;
	currentlyHighlightedBoxKey: string;

	currentlyEditingInPlaceBoxKey: string;
	currentlyEditingInPlaceBoxHeaderText: string;
}

export class BoxBase extends React.Component<BoxBaseProps, BoxBaseState> {
	static defaultProps = {
		canRemove: true,
		showHeader: true,
	};

	ref: React.RefObject<HTMLDivElement> | null = null;
	addButtonRef: React.RefObject<HTMLDivElement> | null = null;
	removeButtonRef: React.RefObject<HTMLDivElement> | null = null;
	toggleButtonRef: React.RefObject<HTMLDivElement> | null = null;

	// Used to detect double-clicks
	doubleClickTimeout: number | undefined = undefined;

	// Used to detect click counts
	clickCount: number = 0;

	constructor(props: BoxBaseProps) {
		super(props);

		this.ref = React.createRef<HTMLDivElement>()
		this.addButtonRef = React.createRef<HTMLDivElement>()
		this.removeButtonRef = React.createRef<HTMLDivElement>()
		this.toggleButtonRef = React.createRef<HTMLDivElement>()

		// Set the initial state
		this.state = {
			isAddBoxButtonHovered: false,
			isRemoveBoxButtonHovered: false,
			isToggleChildLayoutButtonHovered: false,
			currentlyHighlightedBoxKey: '',
			currentlyEditingInPlaceBoxKey: '',
			currentlyEditingInPlaceBoxHeaderText: '',
		};
	}

	public componentDidMount() {
		this.props.registerHighlightedBoxChangeListener(this.props.boxKey, this.handleHighlightedBoxChange)
	}

	componentWillUnmount() {
		// Cancel the double click timeout.
		if (this.doubleClickTimeout) {
			clearTimeout(this.doubleClickTimeout);
			this.doubleClickTimeout = undefined;
		}

		this.props.unregisterHighlightedBoxChangeListener(this.props.boxKey);
	}

	shouldComponentUpdate(nextProps: BoxBaseProps): boolean {
		// Don't bother comparing props, it's faster just to render.
		return true;
	}

	public render() {
		// If we don't have a box or it doesn't have a size then there's nothing to
		// render
		if (
			!this.props.box ||
			this.props.widthInPixels < 0 ||
			this.props.heightInPixels < 0
		) {
			return null;
		}

		const attributeTypeVisibilityMap = boxLib.getAttributeTypeVisibilityMap(this.props.boxKey,
			this.props.boxVisibilityMap,
			this.props.boxTypes,
			this.props.box.boxType,
			this.props.boxTypeVisibilityMap)
		if (!attributeTypeVisibilityMap) {
			return null;
		}

		// Get the box attribute types
		const boxAttributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(this.props.box.boxType);

		// Whether the box is currently highlighted
		const isHighlighted = this.state.currentlyHighlightedBoxKey === this.props.boxKey;

		// Get the attributes of the currently highlighted box
		let currentlyHighlightedBoxTypeKey = '';
		let currentlyHighlightedBoxAttributeTypes: attributeTypeLib.AttributeTypeMap | undefined = undefined;
		let currentlyHighlightedBoxAttributes: attributeLib.AttributeMap | undefined = undefined;
		if (this.state.currentlyHighlightedBoxKey !== '' &&
			this.props.illustrationFlattenedBoxMap !== undefined) {
			const currentlyHighlightedBox = this.props.illustrationFlattenedBoxMap[this.state.currentlyHighlightedBoxKey];
			if (currentlyHighlightedBox) {
				currentlyHighlightedBoxTypeKey = currentlyHighlightedBox.boxType;
				currentlyHighlightedBoxAttributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(currentlyHighlightedBoxTypeKey);
				currentlyHighlightedBoxAttributes = currentlyHighlightedBox.attributes;
			}
		}

		const boxParentAttributeTypes = boxTypeLib
			.getBoxTypeAttributeTypeCacheForType(this.props.boxParentTypeKey);

		// The box properties
		const boxProperties = boxLib.getBoxProperties(this.props.boxKey,
			this.props.box,
			this.props.boxTypes,
			this.props.boxTypeVisibilityMap,
			boxAttributeTypes,
			attributeTypeVisibilityMap,
			this.props.boxStyles,
			this.props.boxParentTypeKey,
			this.props.boxParentProperties,
			boxParentAttributeTypes,
			this.props.boxParentAttributes,
			this.props.boxAssociationsMap,
			this.state.currentlyHighlightedBoxKey,
			currentlyHighlightedBoxTypeKey,
			currentlyHighlightedBoxAttributeTypes,
			currentlyHighlightedBoxAttributes,
			this.props.currentlyHighlightedDropTargetBoxKey,
			this.props.canHighlightAssociations,
			this.props.illustrationFlattenedBoxMap);

		// Build the box text
		const boxText = boxLib.getBoxText(this.props.box, boxProperties);

		// Do we have any child boxes that are taking up space in the layout?
		const hasChildren = boxLib.hasChildrenInLayout(this.props.box,
			this.props.boxVisibilityMap,
			this.props.boxTypeVisibilityMap);

		const boxDimensions = getBoxDimensions(this.props.boxKey,
			boxText,
			boxProperties,
			this.props.widthInPixels,
			this.props.heightInPixels,
			this.props.horizontalBoxGapInPixels,
			this.props.verticalBoxGapInPixels,
			hasChildren,
			this.props.showHeader,
			this.state.currentlyEditingInPlaceBoxKey)

		const isSelected = boxLib.getIsSelected(this.props.boxKey,
			this.props.boxSelectionInfoMap);

		const backgroundImage = getBackgroundImage(boxProperties)

		// Build the item style
		const itemStyle = getItemStyle(boxProperties,
			this.props.baseStyle,
			boxDimensions.boxWidthInPixels,
			boxDimensions.boxHeightInPixels,
			boxDimensions.boxHorizontalMarginInPixels,
			boxDimensions.boxVerticalMarginInPixels,
			backgroundImage);

		// The boxes badges
		const renderedBadges = (
			<MemoizedBadges
				box={this.props.box}
				boxProperties={boxProperties}
				attributeTypeVisibilityMap={attributeTypeVisibilityMap}
				boxWidthInPixels={boxDimensions.boxInternalWidthInPixels}
				boxHeightInPixels={boxDimensions.boxInternalHeightInPixels}
			/>
		);

		const renderedHeader = (
			<MemoizedBoxHeader
				onBoxHeaderTextChange={this.handleBoxHeaderTextChange}
				onBoxHeaderTextAccept={this.handleBoxHeaderTextAccept}
				onBoxHeaderTextCancel={this.handleBoxHeaderTextCancel}
				boxKey={this.props.boxKey}
				boxProperties={boxProperties}
				boxText={boxText}
				textWidthInPixels={boxDimensions.textWidthInPixels}
				headerHeightInPixels={boxDimensions.headerHeightInPixels}
				headerFontSizeInPixels={boxDimensions.headerFontSizeInPixels}
				currentlyEditingInPlaceBoxKey={this.state.currentlyEditingInPlaceBoxKey}
				currentlyEditingInPlaceBoxHeaderText={this.state.currentlyEditingInPlaceBoxHeaderText}
				isHeaderVisible={boxDimensions.isHeaderVisible}
				showHeader={this.props.showHeader}
				hasChildren={hasChildren}
			/>
		)

		const childLayout = getChildLayout(boxProperties);

		const renderedChildren = (
			<Boxes
				registerHighlightedBoxChangeListener={this.props.registerHighlightedBoxChangeListener}
				unregisterHighlightedBoxChangeListener={this.props.unregisterHighlightedBoxChangeListener}
				onHighlightedBoxChange={this.props.onHighlightedBoxChange}
				onBoxClick={this.props.onBoxClick}
				onBoxHeaderChangeAccept={this.props.onBoxHeaderChangeAccept}
				onBoxAddChild={this.props.onBoxAddChild}
				onBoxRemoveChild={this.props.onBoxRemoveChild}
				onBoxChildLayoutChange={this.props.onBoxChildLayoutChange}
				onBoxCanDrop={this.props.onBoxCanDrop}
				onBoxDragStart={this.props.onBoxDragStart}
				onBoxDragCancel={this.props.onBoxDragCancel}
				onBoxDragHover={this.props.onBoxDragHover}
				onBoxDragDrop={this.props.onBoxDragDrop}
				leftInPixels={boxDimensions.childrenLeftInPixels}
				topInPixels={boxDimensions.childrenTopInPixels}
				widthInPixels={boxDimensions.childrenWidthInPixels}
				boxesWidthInPixels={boxDimensions.childBoxesWidthInPixels}
				heightInPixels={boxDimensions.childrenHeightInPixels}
				boxesHeightInPixels={boxDimensions.childBoxesHeightInPixels}
				horizontalBoxGapInPixels={this.props.horizontalBoxGapInPixels}
				verticalBoxGapInPixels={this.props.verticalBoxGapInPixels}
				childLayout={childLayout}
				boxesKey={this.props.boxKey}
				boxes={this.props.box.children}
				illustrationFlattenedBoxMap={this.props.illustrationFlattenedBoxMap}
				boxTypeKey={this.props.box.boxType}
				boxTypes={this.props.boxTypes}
				boxTypeVisibilityMap={this.props.boxTypeVisibilityMap}
				boxVisibilityMap={this.props.boxVisibilityMap}
				boxSelectionInfoMap={this.props.boxSelectionInfoMap}
				boxVisualisations={this.props.boxVisualisations}
				boxStyles={this.props.boxStyles}
				boxParentTypeKey={this.props.box.boxType}
				boxParentProperties={boxProperties}
				boxParentAttributes={this.props.box.attributes}
				boxAssociationsMap={this.props.boxAssociationsMap}
				currentDragSourceBoxKey={this.props.currentDragSourceBoxKey}
				currentlyHighlightedDropTargetBoxKey={
					this.props.currentlyHighlightedDropTargetBoxKey
				}
				hasChildren={hasChildren}
				canAddChildren={this.props.canAddChildren}
				canRemoveChildren={this.props.canRemoveChildren}
				canRemove={this.props.canRemoveChildren}
				canDragChildren={this.props.canDragChildren}
				canDrag={this.props.canDragChildren}
				canChangeChildLayout={this.props.canChangeChildLayout}
				canHighlightAssociations={this.props.canHighlightAssociations}
			/>
		);

		// The rendered Add and Remove Box and Toggle Child Layout buttons
		const renderedAddBoxButton = (
			<BoxButtonAdd
				ref={this.addButtonRef}
				onMouseEnter={this.handleAddBoxButtonMouseEnter}
				onMouseLeave={this.handleAddBoxButtonMouseLeave}
				onClick={this.handleAddBoxButtonClick}
				canAddChildren={isHighlighted && this.props.canAddChildren}
				isAddBoxButtonHovered={this.state.isAddBoxButtonHovered}
				buttonSizeInPixels={boxDimensions.buttonSizeInPixels}
				buttonBorderRadiusInPixels={boxDimensions.buttonBorderRadiusInPixels}
				buttonOffsetInPixels={DEFAULT_BADGE_OFFSET_IN_PIXELS}
			/>
		);

		const renderedRemoveBoxButton = (
			<BoxButtonRemove
				ref={this.removeButtonRef}
				onMouseEnter={this.handleRemoveBoxButtonMouseEnter}
				onMouseLeave={this.handleRemoveBoxButtonMouseLeave}
				onClick={this.handleRemoveBoxButtonClick}
				canRemove={isHighlighted && this.props.canRemove}
				isRemoveBoxButtonHovered={this.state.isRemoveBoxButtonHovered}
				buttonSizeInPixels={boxDimensions.buttonSizeInPixels}
				buttonBorderRadiusInPixels={boxDimensions.buttonBorderRadiusInPixels}
				buttonOffsetInPixels={DEFAULT_BADGE_OFFSET_IN_PIXELS}
			/>
		);

		const renderedToggleChildLayoutButton = (
			<BoxButtonToggleChildLayout
				ref={this.toggleButtonRef}
				onMouseEnter={this.handleToggleChildLayoutButtonMouseEnter}
				onMouseLeave={this.handleToggleChildLayoutButtonMouseLeave}
				onClick={(event: React.MouseEvent<HTMLDivElement>) =>
					this.handleToggleChildLayoutButtonClick(
						event,
						boxProperties.childLayout
				)}
				canToggleChildLayout={!!hasChildren && isHighlighted && this.props.canChangeChildLayout}
				isToggleChildLayoutButtonHovered={this.state.isToggleChildLayoutButtonHovered}
				childLayout={boxProperties.childLayout}
				boxInternalWidthInPixels={boxDimensions.boxInternalWidthInPixels}
				buttonSizeInPixels={boxDimensions.buttonSizeInPixels}
				buttonBorderRadiusInPixels={boxDimensions.buttonBorderRadiusInPixels}
				buttonOffsetInPixels={DEFAULT_BADGE_OFFSET_IN_PIXELS}
			/>
		);

		// The rendered selection component
		const renderedSelectionComponent = (
			<BoxSelection
				isSelected={isSelected}
				boxKey={this.props.boxKey}
				boxSelectionInfoMap={this.props.boxSelectionInfoMap}
				boxInternalWidthInPixels={boxDimensions.boxInternalWidthInPixels}
				boxInternalHeightInPixels={boxDimensions.boxInternalHeightInPixels}
				borderRadiusInPixels={SELECTION_BORDER_RADIUS_IN_PIXELS}
			/>
		);

		// If the box is currently being dragged, make it invisible
		if (this.props.currentDragSourceBoxKey === this.props.boxKey) {
			itemStyle.visibility = 'hidden';
		}

		return this.props.onRender(
			<div
				ref={this.ref}
				id={this.props.boxKey}
				key={this.props.boxKey}
				className="BoxTransition"
				data-id={this.props.boxKey}
				data-parent-id={this.props.boxParentKey}
				style={itemStyle}
				onMouseOver={this.handleMouseOver}
				onMouseLeave={this.handleMouseLeave}
				onMouseOut={this.handleMouseOut}
				onClick={this.handleMouseClick}
			>
				{renderedBadges}
				{renderedHeader}
				{renderedChildren}
				{renderedAddBoxButton}
				{renderedRemoveBoxButton}
				{renderedToggleChildLayoutButton}
				{renderedSelectionComponent}
			</div>
		);
	}

	private handleHighlightedBoxChange = (boxKey: string) => {
		this.setState({
			currentlyHighlightedBoxKey: boxKey,
		});
	}

	private handleMouseOver = (event: React.MouseEvent<HTMLDivElement>) => {
		// We want to know when a pointing device enters a box, including when it
		// enters any of its children, so we use mouseover instead of mouseenter

		// The event bubbles so we need to stop propagating it
		event.stopPropagation();

		if (this.state.currentlyHighlightedBoxKey !== this.props.boxKey) {
			this.setState({
				currentlyHighlightedBoxKey: this.props.boxKey,
			}, () => this.props.onHighlightedBoxChange(this.props.boxKey));
		}
	};

	private handleMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
		// The mouseleave event is fired when the pointer of a pointing device
		// (usually a mouse) is moved out of an element that has the listener
		// attached to it.
		//
		// mouseleave and mouseout are similar but differ in that mouseleave does
		// not bubble and mouseout does.
		//
		// We use mouseleave instead of mouseout because we're only interested in
		// when the pointing device leaves the box and its child hierarchy, not when
		// it leaves the box to move to a child
		if (this.state.currentlyHighlightedBoxKey === this.props.boxKey) {
			this.setState({
				currentlyHighlightedBoxKey: ''
			}, () => this.props.onHighlightedBoxChange(''))
		}
	};

	private handleMouseOut = (event: React.MouseEvent<HTMLDivElement>) => {	
		// We need to use the mouseleave event, but that doesn't tell us when the
		// mouse moves to a child, so on mouseout we check if that's happened.

		// If we don't have a ref to the component, skip handling this event.
		if (!this.ref || !this.ref.current) {
			return;
		}

		// If we already know we're not highlighted, ignore this event.
		if (this.state.currentlyHighlightedBoxKey === '') {
			return;
		}

		const currentElement = this.ref.current;		
		const exitedElement = event.relatedTarget;

		// If the element that was exited isn't a Node, skip handling this event.
		if (!!exitedElement && !(exitedElement instanceof Node)) {
			return;
		}

		const addButtonElement = (this.addButtonRef && this.addButtonRef.current)
			? this.addButtonRef.current
			: null;
		const removeButtonElement = (this.removeButtonRef && this.removeButtonRef.current)
			? this.removeButtonRef.current
			: null;
		const toggleButtonElement = (this.toggleButtonRef && this.toggleButtonRef.current)
			? this.toggleButtonRef.current
			: null;	

		// Did we move over a child element?
		if (currentElement.contains(exitedElement)) {
			// Did we move over the Add button?
			if ((addButtonElement === exitedElement) ||
				(!!addButtonElement && addButtonElement.contains(exitedElement))) {
				return;
			}

			// Did we move over the Remove button?
			if ((removeButtonElement === exitedElement) ||
				(!!removeButtonElement && removeButtonElement.contains(exitedElement))) {
				return;
			}

			// Did we move over the Toggle button?
			if ((toggleButtonElement === exitedElement) ||
				(!!toggleButtonElement && toggleButtonElement.contains(exitedElement))) {
				return;
			}
		}

		this.setState({
			currentlyHighlightedBoxKey: ''
		}, () => this.props.onHighlightedBoxChange(''));
	}

	private handleMouseClick = (event: React.MouseEvent<HTMLDivElement>) => {
		// The event bubbles so we need to stop propagating it
		event.stopPropagation();

		// Ignore the click if we're currently editing in place
		if (this.state.currentlyEditingInPlaceBoxKey === this.props.boxKey) {
			return;
		}

		// We've had one more click.
		this.clickCount++;

		// If we're not yet checking for a double click, set up a timeout
		if (!this.doubleClickTimeout) {
			this.doubleClickTimeout = window.setTimeout(() => {
				// Reset the timeout
				this.doubleClickTimeout = undefined;

				// Did a double click occur?
				if (this.clickCount === 2) {
					// Only handle the double-click if we're in an edit mode 
					if (this.props.canAddChildren &&
						this.props.canRemoveChildren &&
						this.props.canDragChildren) {
						const boxText = this.props.box ? this.props.box.name : '';
						this.handleBoxHeaderTextChange(boxText);
					}
				} else {
					const { ctrlKey, shiftKey } = event;

					// Notify the single-click callback
					this.props.onBoxClick(this.props.boxKey, ctrlKey, shiftKey);
				}

				this.clickCount = 0;
			}, 250);
		}
	};

	private handleBoxHeaderTextChange = (boxText: string) => {
		this.setState({
			currentlyEditingInPlaceBoxKey: this.props.boxKey,
			currentlyEditingInPlaceBoxHeaderText: boxText
		})
	} 

	private handleBoxHeaderTextAccept = () => {
		const boxText = this.state.currentlyEditingInPlaceBoxHeaderText

		this.props.onBoxHeaderChangeAccept(this.props.boxKey, boxText);

		this.setState({
			currentlyEditingInPlaceBoxKey: '',
			currentlyEditingInPlaceBoxHeaderText: ''
		})
	} 

	private handleBoxHeaderTextCancel = () => {
		this.setState({
			currentlyEditingInPlaceBoxKey: '',
			currentlyEditingInPlaceBoxHeaderText: ''
		})
	} 

	private handleAddBoxButtonMouseEnter = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		// The event bubbles so we need to stop propagating it
		event.stopPropagation();

		if (!this.state.isAddBoxButtonHovered) {
			// The Add Box button is hovered
			this.setState({
				isAddBoxButtonHovered: true,
			});
		}
	};

	private handleAddBoxButtonMouseLeave = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		// The event bubbles so we need to stop propagating it
		event.stopPropagation();

		if (this.state.isAddBoxButtonHovered) {
			// The Add Box button is no longer hovered
			this.setState({
				isAddBoxButtonHovered: false,
			});
		}
	};

	private handleAddBoxButtonClick = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		// The event bubbles so we need to stop propagating it
		event.stopPropagation();

		// Notify the callback
		this.props.onBoxAddChild(this.props.boxKey);
	};

	private handleRemoveBoxButtonMouseEnter = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		// The event bubbles so we need to stop propagating it
		event.stopPropagation();

		if (this.state.isRemoveBoxButtonHovered) {
			// The Remove Box button is hovered
			this.setState({
				isRemoveBoxButtonHovered: true,
			});
		}
	};

	private handleRemoveBoxButtonMouseLeave = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		// The event bubbles so we need to stop propagating it
		event.stopPropagation();

		if (this.state.isRemoveBoxButtonHovered) {
			// The Remove Box button is no longer hovered
			this.setState({
				isRemoveBoxButtonHovered: false,
			});
		}
	};

	private handleRemoveBoxButtonClick = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		// The event bubbles so we need to stop propagating it
		event.stopPropagation();

		// Notify the callback
		this.props.onBoxRemoveChild(this.props.boxKey);
	};

	private handleToggleChildLayoutButtonMouseEnter = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		// The event bubbles so we need to stop propagating it
		event.stopPropagation();

		if (this.state.isToggleChildLayoutButtonHovered) {
			// The Toggle Child Layout button is hovered
			this.setState({
				isToggleChildLayoutButtonHovered: true,
			});
		}
	};

	private handleToggleChildLayoutButtonMouseLeave = (
		event: React.MouseEvent<HTMLDivElement>
	) => {
		// The event bubbles so we need to stop propagating it
		event.stopPropagation();

		if (this.state.isToggleChildLayoutButtonHovered) {
			// The Toggle Child Layout button is no longer hovered
			this.setState({
				isToggleChildLayoutButtonHovered: false,
			});
		}
	};

	private handleToggleChildLayoutButtonClick = (
		event: React.MouseEvent<HTMLDivElement>,
		currentChildLayout: string
	) => {
		// The event bubbles so we need to stop propagating it
		event.stopPropagation();

		// Get the updated child layout
		let updatedChildLayout = "vertical";
		if (currentChildLayout === "horizontal") {
			updatedChildLayout = "vertical";
		} else if (currentChildLayout === "vertical") {
			updatedChildLayout = "grid";
		} if (currentChildLayout === "grid") {
			updatedChildLayout = "horizontal"
		}

		// console.log(`handleToggleChildLayoutButtonClick(${currentChildLayout} -> ${updatedChildLayout})`)

		// Notify the callback
		this.props.onBoxChildLayoutChange(
			this.props.boxKey,
			updatedChildLayout
		);
	};
}
