import heartbeat from "../services/heartbeat";
import React from "react";
import { Navigate } from "react-router";
import { useLocation } from "react-router-dom"

type AuthenticatedRouteProps = {
  isAuthenticated: boolean;
  children?: any;
}

export const AuthenticatedRoute = (props: AuthenticatedRouteProps) => {
	const { isAuthenticated, children } = props

	React.useEffect(() => {
		console.log(`initializing interval`);
		const interval = setInterval(() => {
		  heartbeat.get()
		}, 1000*60*10);
	  
		return () => {
		  console.log(`clearing interval`);
		  clearInterval(interval);
		};
	  }, []); // has no dependency - this will be called on-component-mount

	const location = useLocation();

	if (children && location) {
		// If we're authenticated then we want to show the component
		if (isAuthenticated) {
			return children;
		}

		// Otherwise we want to redirect to the login page 
		const redirect = location.pathname +
			(location.search && location.search !== "" ?
			"?" + location.search : "");

		console.log("location: " + JSON.stringify(location));

		return (
			<Navigate to={`/login/?redirect=${redirect}`} />
		);
	}

	return null;
}
