import { Checkbox, InputNumber } from "antd";
import React from "react";
import RightMenuHeading from "../../../organisms/RightMenuHeading";
import RightMenuHeadingText from "../../..//organisms/RightMenuHeadingText";
import * as configurationLib from "@lib/configuration/configuration";

export interface ConfigurationMenuProps {
	viewSidebarToolVisibilities: any,
	areConfigurationInputsDisabled: boolean,
	onViewSidebarMenuVisibilityChange: any,
	startInViewState: boolean,
	onViewSidebarMenuStartInViewStateChange: any,
	illustrationHeightInPercent: number,
	onViewSidebarWidthInPercentChange: any,
	viewSidebarWidthInPercent: number,
	editSidebarWidthInPercent: number,
	onEditSidebarWidthInPercentChange: any,
	illustrationWidthInPercent: number,
	onIllustrationWidthInPercentChange: any,
	onIllustrationHeightInPercentChange: any,
	viewModeHorizontalBoxGapInPixels: number,
	onViewModeHorizontalBoxGapInPixelsChange: (horizontalBoxGapInPixels: number | null) => void,
	viewModeVerticalBoxGapInPixels: number,
	onViewModeVerticalBoxGapInPixelsChange: (verticalBoxGapInPixels: number | null) => void,
	editModeHorizontalBoxGapInPixels: number,
	onEditModeHorizontalBoxGapInPixelsChange: (horizontalBoxGapInPixels: number | null) => void,
	editModeVerticalBoxGapInPixels: number,
	onEditModeVerticalBoxGapInPixelsChange: (verticalBoxGapInPixels: number | null) => void,
}

interface VisibilityCheckboxProps {
	name: string,
	checked: boolean
}

const ConfigurationMenu: React.FC<ConfigurationMenuProps> = (props) => {


	const VisiblityCheckbox: React.FC<VisibilityCheckboxProps> = (innerProps) => {
		return <Checkbox
			name={innerProps.name}
			checked={
				innerProps.checked
			}
			disabled={
				props.areConfigurationInputsDisabled
			}
			onChange={
				props.onViewSidebarMenuVisibilityChange
			}
		>
			{innerProps.children}
		</Checkbox>
	};

	return <>
		<RightMenuHeading>
			<RightMenuHeadingText>Configuration</RightMenuHeadingText>
		</RightMenuHeading>
		<div style={{ padding: "12px" }}>
			<VisiblityCheckbox
				name="visibilityStateMenuVisibility"
				checked={
					props.viewSidebarToolVisibilities.visibilityStateMenuVisibility
				}
			>
				Show Story Menu
			</VisiblityCheckbox>
			<br />
			<VisiblityCheckbox
				name="lensesMenuVisibility"
				checked={
					props.viewSidebarToolVisibilities.lensesMenuVisibility
				}
			>
				Show Power Lenses Menu
			</VisiblityCheckbox>
			<br />
			<VisiblityCheckbox
				name="simpleLensesMenuVisibility"
				checked={
					props.viewSidebarToolVisibilities.simpleLensesMenuVisibility
				}
			>
				Show Lenses Menu
			</VisiblityCheckbox>
			<br />
			<VisiblityCheckbox
				name="lensPagesMenuVisibility"
				checked={
					props.viewSidebarToolVisibilities.lensPagesMenuVisibility
				}
			>
				Show Lens Pages	Menu
			</VisiblityCheckbox>
			<br />
			<VisiblityCheckbox
				name="legendMenuVisibility"
				checked={
					props.viewSidebarToolVisibilities.legendMenuVisibility
				}
			>
				Show Legend Menu
			</VisiblityCheckbox>
			<br />
			<VisiblityCheckbox
				name="simpleBoxTypesMenuVisibility"
				checked={
					props.viewSidebarToolVisibilities.simpleBoxTypesMenuVisibility
				}
			>
				Show Box Types Menu
			</VisiblityCheckbox>
			<br />
			<VisiblityCheckbox
				name="boxTypesMenuVisibility"
				checked={
					props.viewSidebarToolVisibilities.boxTypesMenuVisibility
				}
			>
				Show Advanced Box Types Menu
			</VisiblityCheckbox>
			<br />
			<VisiblityCheckbox
				name="boxesMenuVisibility"
				checked={
					props.viewSidebarToolVisibilities.boxesMenuVisibility
				}
			>
				Show Boxes Menu
			</VisiblityCheckbox>
			<br />
			<Checkbox
				name="startInViewState"
				checked={
					props.startInViewState
				}
				disabled={
					props.areConfigurationInputsDisabled
				}
				onChange={
					props
						.onViewSidebarMenuStartInViewStateChange
				}
			>
				Start in View
				State
			</Checkbox>
			<br />
			<br />
			<div
				style={{
					display:
						"inline-block",
					width:
						"100%",
				}}
			>
				<div
					style={{
						float:
							"left",
						paddingTop:
							"0.4em",
					}}
				>
					Left Hand Menu Width:
				</div>
				<div
					style={{
						float:
							"right",
					}}
				>
					<InputNumber
						value={
							props.viewSidebarWidthInPercent
						}
						min={
							configurationLib.MINIMUM_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT
						}
						max={
							configurationLib.MAXIMUM_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT
						}
						formatter={(
							value
						) =>
							`${value}%`
						}
						parser={(
							displayValue:
								| string
								| undefined
						) =>
							displayValue !==
								undefined
								? parseFloat(
									displayValue.replace(
										"%",
										""
									)
								)
								: configurationLib.DEFAULT_UI_VIEW_SIDEBAR_WIDTH_IN_PERCENT
						}
						disabled={
							props.areConfigurationInputsDisabled
						}
						onChange={
							props
								.onViewSidebarWidthInPercentChange
						}
					/>
				</div>
			</div>
			<br />
			<div
				style={{
					display:
						"inline-block",
					width:
						"100%",
				}}
			>
				<div
					style={{
						float:
							"left",
						paddingTop:
							"0.4em",
					}}
				>
					Right Hand Menu Width:
				</div>
				<div
					style={{
						float:
							"right",
					}}
				>
					<InputNumber
						value={
							props.editSidebarWidthInPercent
						}
						min={
							configurationLib.MINIMUM_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT
						}
						max={
							configurationLib.MAXIMUM_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT
						}
						formatter={(
							value
						) =>
							`${value}%`
						}
						parser={(
							displayValue
						) =>
							displayValue !==
								undefined
								? parseFloat(
									displayValue.replace(
										"%",
										""
									)
								)
								: configurationLib.DEFAULT_UI_EDIT_SIDEBAR_WIDTH_IN_PERCENT
						}
						style={{
							float:
								"right",
						}}
						disabled={
							props.areConfigurationInputsDisabled
						}
						onChange={
							props
								.onEditSidebarWidthInPercentChange
						}
					/>
				</div>
			</div>
			<br />
			<div
				style={{
					display:
						"inline-block",
					width:
						"100%",
				}}
			>
				<div
					style={{
						float:
							"left",
						paddingTop:
							"0.4em",
					}}
				>
					Illustration Width:
				</div>
				<div
					style={{
						float:
							"right",
					}}
				>
					<InputNumber
						value={
							props.illustrationWidthInPercent
						}
						min={
							configurationLib.MINIMUM_UI_ILLUSTRATION_WIDTH_IN_PERCENT
						}
						formatter={(
							value
						) =>
							`${value}%`
						}
						parser={(
							displayValue
						) =>
							displayValue !==
								undefined
								? parseFloat(
									displayValue.replace(
										"%",
										""
									)
								)
								: configurationLib.DEFAULT_UI_ILLUSTRATION_WIDTH_IN_PERCENT
						}
						disabled={
							props.areConfigurationInputsDisabled
						}
						onChange={
							props
								.onIllustrationWidthInPercentChange
						}
					/>
				</div>
			</div>
			<br />
			<div
				style={{
					display:
						"inline-block",
					width:
						"100%",
				}}
			>
				<div
					style={{
						float:
							"left",
						paddingTop:
							"0.4em",
					}}
				>
					Illustration Height:
				</div>
				<div
					style={{
						float:
							"right",
					}}
				>
					<InputNumber
						value={
							props.illustrationHeightInPercent
						}
						min={
							configurationLib.MINIMUM_UI_ILLUSTRATION_HEIGHT_IN_PERCENT
						}

						formatter={(
							value
						) =>
							`${value}%`
						}
						parser={(
							displayValue
						) =>
							displayValue !==
								undefined
								? parseFloat(
									displayValue.replace(
										"%",
										""
									)
								)
								: configurationLib.DEFAULT_UI_ILLUSTRATION_HEIGHT_IN_PERCENT
						}
						disabled={
							props.areConfigurationInputsDisabled
						}
						onChange={
							props
								.onIllustrationHeightInPercentChange
						}
					/>
				</div>
			</div>
			<div
				style={{
					display: "inline-block",
					width: "100%",
				}}
			>
				<div
					style={{
						float: "left",
						paddingTop: "0.4em",
					}}
				>
					View Mode Horizontal Spacing:
				</div>
				<div
					style={{
						float:
							"right",
					}}
				>
					<InputNumber
						value={props.viewModeHorizontalBoxGapInPixels}
						min={configurationLib.MINIMUM_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS}
						max={configurationLib.MAXIMUM_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS}
						formatter={(value) => `${value}px`}
						parser={(displayValue) => displayValue !== undefined
							? parseFloat(displayValue.replace("%", ""))
							: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS
						}
						disabled={props.areConfigurationInputsDisabled}
						onChange={props.onViewModeHorizontalBoxGapInPixelsChange}
					/>
				</div>
			</div>
			<div
				style={{
					display: "inline-block",
					width: "100%",
				}}
			>
				<div
					style={{
						float: "left",
						paddingTop: "0.4em",
					}}
				>
					View Mode Vertical Spacing:
				</div>
				<div
					style={{
						float:
							"right",
					}}
				>
					<InputNumber
						value={props.viewModeVerticalBoxGapInPixels}
						min={configurationLib.MINIMUM_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS}
						max={configurationLib.MAXIMUM_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS}
						formatter={(value) => `${value}px`}
						parser={(displayValue) => displayValue !== undefined
							? parseFloat(displayValue.replace("%", ""))
							: configurationLib.DEFAULT_UI_ILLUSTRATION_VIEW_MODE_VERTICAL_BOX_GAP_IN_PIXELS
						}
						disabled={props.areConfigurationInputsDisabled}
						onChange={props.onViewModeVerticalBoxGapInPixelsChange}
					/>
				</div>
			</div>
			<div
				style={{
					display: "inline-block",
					width: "100%",
				}}
			>
				<div
					style={{
						float: "left",
						paddingTop: "0.4em",
					}}
				>
					Edit Mode Horizontal Spacing:
				</div>
				<div
					style={{
						float:
							"right",
					}}
				>
					<InputNumber
						value={props.editModeHorizontalBoxGapInPixels}
						min={configurationLib.MINIMUM_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS}
						max={configurationLib.MAXIMUM_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS}
						formatter={(value) => `${value}px`}
						parser={(displayValue) => displayValue !== undefined
							? parseFloat(displayValue.replace("%", ""))
							: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_HORIZONTAL_BOX_GAP_IN_PIXELS
						}
						disabled={props.areConfigurationInputsDisabled}
						onChange={props.onEditModeHorizontalBoxGapInPixelsChange}
					/>
				</div>
			</div>
			<div
				style={{
					display: "inline-block",
					width: "100%",
				}}
			>
				<div
					style={{
						float: "left",
						paddingTop: "0.4em",
					}}
				>
					Edit Mode Vertical Spacing:
				</div>
				<div
					style={{
						float:
							"right",
					}}
				>
					<InputNumber
						value={props.editModeVerticalBoxGapInPixels}
						min={configurationLib.MINIMUM_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS}
						max={configurationLib.MAXIMUM_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS}
						formatter={(value) => `${value}px`}
						parser={(displayValue) => displayValue !== undefined
							? parseFloat(displayValue.replace("%", ""))
							: configurationLib.DEFAULT_UI_ILLUSTRATION_EDIT_MODE_VERTICAL_BOX_GAP_IN_PIXELS
						}
						disabled={props.areConfigurationInputsDisabled}
						onChange={props.onEditModeVerticalBoxGapInPixelsChange}
					/>
				</div>
			</div>
		</div>
	</>
};

export default ConfigurationMenu;