import { ChildLayout, Properties } from "@lib/box/box-properties";
import { BoxWeightMap } from "@lib/box/box-weight";
import { BoxTypeVisibilityMap } from "@lib/box/box-type";
import { BoxVisualisationMap } from "@lib/box/box-visualisation";
import { BoxMap, BoxVisibilityMap, getBoxCount } from "@lib/box/box";

import * as attributeLib from "@lib/box/attribute";
import * as attributeTypeLib from '@lib/box/attribute-type'
import * as boxLib from '@lib/box/box';
import * as boxTypeLib from '@lib/box/box-type';
import * as boxVisualisationLib from '@lib/box/box-visualisation';
import { BoxStyleMap } from '@lib/box/box-style';
import { buildBoxWeightMap } from '@lib/box/box-weight'

export type LayoutItem = {
	[index: string]: any;
	w: number;
	h: number;
	x: number;
	y: number;
	i: string;
	minW?: number;
	minH?: number;
	maxW?: number;
	maxH?: number;
	moved?: boolean;
	static?: boolean;
	isDraggable?: boolean;
	isResizable?: boolean;
	isBounded?: boolean;
};

export interface LayoutDimensions {
	gridWidthInPixels: number;
	gridHeightInPixels: number;

	gridWidthInColumns: number;
	gridHeightInRows: number;

	gridColumnWidthInPixels: number;
	gridRowHeightInPixels: number;

	defaultBoxWidthInColumns: number;
	defaultBoxHeightInRows: number;
}

export interface BoxDimensions {
	boxKey: string;
	leftInPixels: number;
	topInPixels: number;
	widthInPixels: number;
	heightInPixels: number;
}
export interface BoxDimensionsInfo {
	boxDimensions: BoxDimensions[];
	boxWeightMap: BoxWeightMap;
	boxRowBoxCounts: number[],
	layoutItems: LayoutItem[];
	layoutDimensions: LayoutDimensions;
}

// The grid width and height (in columns)
export const GRID_WIDTH_IN_COLUMNS = 100;
export const GRID_HEIGHT_IN_ROWS = 100;

export const getGridChildLayoutBoxesPerSide = (boxCount: number) => Math.ceil(Math.sqrt(boxCount));

export const buildLayoutItems = (sortedBoxKeys: string[],
	boxes: BoxMap | undefined,
	boxTypeVisibilityMap: BoxTypeVisibilityMap | undefined,
	boxVisibilityMap: BoxVisibilityMap | undefined,
	boxVisualisations: BoxVisualisationMap | undefined,
	boxCount: number,
	boxGridHorizontalCount: number,
	boxGridVerticalCount: number,
	boxParentKey: string,
	childLayout: ChildLayout,
	boxWeightMap: BoxWeightMap,
	boxRowBoxCounts: number[],
	layoutDimensions: LayoutDimensions): LayoutItem[] => {
	// TODO: override the default boxes width/height with the layout
	// width/height

	// Do we not have any boxes?
	if ((sortedBoxKeys.length <= 0) || (!boxes)) {
		return [];
	}

	// The current X and Y positions in the layout
	let currentXPosition = 0;
	let currentYPosition = 0;

	// The current row and column
	let currentColumn = 0;
	let currentRow = 0;

	// Build the layout items
	const layoutItems: LayoutItem[] = [];
	for (let i=0; i < sortedBoxKeys.length; i += 1) {
		const boxKey = sortedBoxKeys[i];

		// Get the box
		const layoutBox = boxes[boxKey];

		// Get the actual box type key
		const boxTypeKey = (layoutBox.boxType) ? layoutBox.boxType : '';

		// Get the box type visibility
		const boxTypeVisibility = (boxTypeVisibilityMap)
			? boxTypeVisibilityMap[boxTypeKey]
			: undefined;

		// If the boxes are not visible and not in the layout, don't include them
		if ((boxTypeVisibility)
			&& (!boxTypeVisibility.areBoxesVisible)
			&& (!boxTypeVisibility.areBoxesInLayout)) {
			continue;
		}

		// Get the box visibility
		const boxVisibility = (boxVisibilityMap)
			? boxVisibilityMap[boxKey]
			: undefined;

		// If the box is not visible and not in the layout, don't render it
		if ((boxVisibility)
			&& (!boxVisibility.isVisible)
			&& (!boxVisibility.isInLayout)) {
			continue;
		}

		// Build the default layout of the box
		let x = 0;
		let y = 0;
		let w = 0;
		let h = 0;

		if (childLayout === ChildLayout.HORIZONTAL) {
			// Get the box weight
			const boxWeight = boxWeightMap[boxKey];

			// The X position is the current X position
			x = currentXPosition;

			// Use the weight to calculate the width
			w = (boxWeight)
				? ((layoutDimensions.gridWidthInColumns * boxWeight) / 100)
				: 0;

			// The item fills the entire height
			h = layoutDimensions.gridHeightInRows

			// Move to the next X position based on the width of the current item
			currentXPosition += w;
		} else if (childLayout === ChildLayout.VERTICAL) {
			// Get the box weight
			const boxWeight = boxWeightMap[boxKey];

			// The Y position is the current Y position
			y = currentYPosition;

			// The item fills the entire width
			w = layoutDimensions.gridWidthInColumns

			// Use the weight to calculate the height
			h = (boxWeight)
				? ((layoutDimensions.gridHeightInRows * boxWeight) / 100)
				: 0;

			// Move to the next Y position based on the height of the current item
			currentYPosition += h;
		} else if (childLayout === ChildLayout.GRID) {
			// Get the box weight
			const boxWeight = boxWeightMap[boxKey];

			// The X and Y positions are the current X and Y positions
			x = currentXPosition;
			y = currentYPosition;

			// Use the weight to calculate the width
			w = (boxWeight)
				? ((layoutDimensions.gridWidthInColumns * boxWeight) / 100)
				: 0;
						
			// The item fills the entire height of a grid row
			h = (layoutDimensions.gridHeightInRows / boxGridVerticalCount);		

			// Move to the next X position based on the width of the current item
			currentXPosition += w;
			currentColumn += 1;

			const rowBoxCount = boxRowBoxCounts[currentRow];
			if (currentColumn >= rowBoxCount) {
				// Reset the X position
				currentXPosition = 0;
				currentColumn = 0;

				// Move to the next Y position based on the height of the current item
				currentYPosition += h;
				currentRow += 1;
			}
		}

		// Build the default layout item
		const defaultLayoutItem: LayoutItem = {
			i: boxKey,
			x,
			y,
			w,
			h,
		}

		// console.log({defaultLayoutItem})

		// Do we already have a layout item for the box? If so, use that, otherwise
		// use the default
		let layoutItem: LayoutItem = ((boxVisualisations) &&
			(Object.prototype.hasOwnProperty.call(boxVisualisations, boxKey)))
			? boxVisualisations[boxKey]
			: defaultLayoutItem;

		// Make sure the width/height are valid
		if (layoutItem.w < 0) {
			layoutItem.w = 0;
		}

		if (layoutItem.h < 0) {
			layoutItem.h = 0;
		}

		// Clamp the X/Y positions and width/height according to the layout
		if (childLayout === ChildLayout.HORIZONTAL) {
			// For horizontal layout items, Y is always zero
			layoutItem.y = 0;

			// Make sure the X position is within limits
			if (layoutItem.x >= layoutDimensions.gridWidthInColumns) {
				layoutItem.x = layoutDimensions.gridWidthInColumns - 1;
			}
			if (layoutItem.x < 0) {
				layoutItem.x = 0;
			}

			// Make sure the height (in rows) is not more than the grid height (in
			// rows)
			if (layoutItem.h > layoutDimensions.gridHeightInRows) {
				layoutItem.h = layoutDimensions.gridHeightInRows;
			}

			// Calculate the right-most column of the box
			const x2 = layoutItem.x + layoutItem.w;

			// Do we now have enough room to fit the box?
			if (x2 > layoutDimensions.gridWidthInColumns) {
				// Calculate a width that will fit the box
				const w = layoutDimensions.gridWidthInColumns - layoutItem.x;

				// Set the layout item to use the new width
				layoutItem.w = (w >= 0)
					? w
					: 0;
			}
		} else if (childLayout === ChildLayout.VERTICAL) {
			// For vertical layout items, X is always zero
			layoutItem.x = 0;

			// Make sure the Y position is within limits
			if (layoutItem.y >= layoutDimensions.gridHeightInRows) {
				layoutItem.y = layoutDimensions.gridHeightInRows - 1;
			}
			if (layoutItem.y < 0) {
				layoutItem.y = 0;
			}

			// Make sure the width (in columns) is not more than the grid width
			// (in columns)
			if (layoutItem.w > layoutDimensions.gridWidthInColumns) {
				layoutItem.w = layoutDimensions.gridWidthInColumns;
			}

			// Calculate the bottom-most row of the box
			const y2 = layoutItem.y + layoutItem.h;

			// Do we now have enough room to fit the box?
			if (y2 > layoutDimensions.gridHeightInRows) {
				// Calculate a height that will fit the box
				const h = layoutDimensions.gridHeightInRows - layoutItem.y;

				// Set the layout item to use the new height
				layoutItem.h = (h >= 0)
					? h
					: 0;
			}
		} else if (childLayout === ChildLayout.GRID) {
			// Make sure the X position is within limits
			if (layoutItem.x >= layoutDimensions.gridWidthInColumns) {
				layoutItem.x = layoutDimensions.gridWidthInColumns - 1;
			}
			if (layoutItem.x < 0) {
				layoutItem.x = 0;
			}

			// Calculate the right-most column of the box
			const x2 = layoutItem.x + layoutItem.w;

			// Do we now have enough room to fit the box?
			if (x2 > layoutDimensions.gridWidthInColumns) {
				// Calculate a width that will fit the box
				const w = layoutDimensions.gridWidthInColumns - layoutItem.x;

				// Set the layout item to use the new width
				layoutItem.w = (w >= 0)
					? w
					: 0;
			}

			// Make sure the Y position is within limits
			if (layoutItem.y >= layoutDimensions.gridHeightInRows) {
				layoutItem.y = layoutDimensions.gridHeightInRows - 1;
			}
			if (layoutItem.y < 0) {
				layoutItem.y = 0;
			}

			// Calculate the bottom-most row of the box
			const y2 = layoutItem.y + layoutItem.h;

			// Do we now have enough room to fit the box?
			if (y2 > layoutDimensions.gridHeightInRows) {
				// Calculate a height that will fit the box
				const h = layoutDimensions.gridHeightInRows - layoutItem.y;

				// Set the layout item to use the new height
				layoutItem.h = (h >= 0)
					? h
					: 0;
			}				
		}

		// Add the layout item to the list
		layoutItems.push(layoutItem);
	};

	// console.log({layoutItems})

	return layoutItems;
}

export const calculateLayoutDimensions = (widthInPixels: number,
	heightInPixels: number,
	gridWidthInColumns: number,
	gridHeightInRows: number,
	boxCount: number,
	childLayout: ChildLayout): LayoutDimensions => {
	const gridWidthInPixels = widthInPixels;
	const gridHeightInPixels = heightInPixels;

	// Calculate the width (in pixels) and height (in pixels) of the grids
	// columns and rows
	const gridColumnWidthInPixels = gridWidthInPixels / gridWidthInColumns;
	const gridRowHeightInPixels = gridHeightInPixels / gridHeightInRows;

	// The width (in columns) and height (in rows) of a box
	let defaultBoxWidthInColumns = gridWidthInColumns;
	let defaultBoxHeightInRows = gridHeightInRows;

	// Do we have boxes?
	if (boxCount > 0) {
		// Are we rendering children horizontally?
		if (childLayout === ChildLayout.HORIZONTAL) {
			// The default width of each box needs to be reduced to fit in all boxes
			defaultBoxWidthInColumns = (defaultBoxWidthInColumns / boxCount);
			// Are we rendering children vertically?
		} else if (childLayout === ChildLayout.VERTICAL) {
			// The default height of each box needs to be reduced to fit in all
			// boxes
			defaultBoxHeightInRows = (defaultBoxHeightInRows / boxCount)
		} else if (childLayout === ChildLayout.GRID) {
			// Layout the boxes in an NxM grid by default
			// First, figure out an NxN grid that will fit all the boxes
			const boxesPerSide = getGridChildLayoutBoxesPerSide(boxCount);

			// Use N as the number of columns
			const columns = boxesPerSide

			// Calculate how many rows we actually need to fit all the boxes
			const rows = Math.ceil(boxCount / boxesPerSide)

			// console.log(`boxesPerSide=${boxesPerSide}, columns=${columns}, rows=${rows}`)

			// The default width of each box needs to be reduced to fit in all boxes
			defaultBoxWidthInColumns = (defaultBoxWidthInColumns / columns);

			// The default height of each box needs to be reduced to fit in all
			// boxes
			defaultBoxHeightInRows = (defaultBoxHeightInRows / rows)

			// console.log(`${boxesKey} - boxCount=${boxCount}, layout=${childLayout}`);
			// console.log(`\tgridWidthInPixels=${gridWidthInPixels}, gridHeightInPixels=${gridHeightInPixels}`);
			// console.log(`\tgridColumnWidthInPixels=${gridColumnWidthInPixels}, gridRowHeightInPixels=${gridRowHeightInPixels}`);
			// console.log(`\tdefaultBoxWidthInPixels=${defaultBoxWidthInPixels}, defaultBoxHeightInPixels=${defaultBoxHeightInPixels}`);
			// console.log(`\tdefaultBoxWidthInColumns=${defaultBoxWidthInColumns}, defaultBoxHeightInRows=${defaultBoxHeightInRows}`);
		}
	}

	const layoutDimensions: LayoutDimensions = {
		gridWidthInPixels,
		gridHeightInPixels,
	
		gridWidthInColumns,
		gridHeightInRows,
	
		gridColumnWidthInPixels,
		gridRowHeightInPixels,

		defaultBoxWidthInColumns,
		defaultBoxHeightInRows,
	}

	return layoutDimensions
}

const calculateTotalGapInPixels = (boxGapCount: number,
	boxGapInPixels: number,
	boxesLengthInPixels: number): number => {
	let totalGapInPixels = boxGapCount * boxGapInPixels;

	while (totalGapInPixels > boxesLengthInPixels) {
		totalGapInPixels = Math.floor(totalGapInPixels / 2);
	}

	return totalGapInPixels;
}

export const calculateBoxDimensionsInfo = (childLayout: ChildLayout,
	boxesWidthInPixels: number,
	boxesHeightInPixels: number,
	horizontalBoxGapInPixels: number,
	verticalBoxGapInPixels: number,
	maximumGridColumns: number,
	boxKeys: string[],
	boxesKey: string,
	boxes: boxLib.BoxMap | undefined,
	boxTypes: boxTypeLib.BoxTypeMap | undefined,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined,
	boxVisibilityMap: boxLib.BoxVisibilityMap | undefined,
	boxVisualisations: boxVisualisationLib.BoxVisualisationMap | undefined,
	boxStyles: BoxStyleMap | undefined,
	boxParentTypeKey: string,
	boxParentProperties: Properties | undefined,
	boxParentAttributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	boxParentAttributes: attributeLib.AttributeMap | undefined,
	boxAssociationsMap: boxLib.BoxAssociationsMap | undefined,
	currentDragSourceBoxKey: string,
	currentlyHighlightedDropTargetBoxKey: string,
	canHighlightAssociations: boolean,
	illustrationFlattenedBoxMap: BoxMap | undefined): BoxDimensionsInfo => {
	const boxDimensionsInfo: BoxDimensionsInfo = {
		boxDimensions: [],
		boxWeightMap: {},
		boxRowBoxCounts: [],
		layoutItems: [],
		layoutDimensions: {
			gridWidthInPixels: 0,
			gridHeightInPixels: 0,
		
			gridWidthInColumns: 0,
			gridHeightInRows: 0,
		
			gridColumnWidthInPixels: 0,
			gridRowHeightInPixels: 0,
	
			defaultBoxWidthInColumns: 0,
			defaultBoxHeightInRows: 0,
		},
	}

	if (!boxes) {
		return boxDimensionsInfo;
	}

	// Get the box keys, sorted by order
	const sortedBoxKeys = boxKeys
		.sort((boxKeyA: string, boxKeyB: string) => {
			return boxes[boxKeyA].order - boxes[boxKeyB].order;
		})

	// Build the box weight map
	const boxWeightMapResult = buildBoxWeightMap(sortedBoxKeys,
		boxes,
		boxTypes,
		boxTypeVisibilityMap,
		boxVisibilityMap,
		boxStyles,
		boxParentTypeKey,
		boxParentProperties,
		boxParentAttributeTypes,
		boxParentAttributes,
		boxAssociationsMap,
		childLayout,
		maximumGridColumns,
		currentDragSourceBoxKey,
		currentlyHighlightedDropTargetBoxKey,
		canHighlightAssociations,
		illustrationFlattenedBoxMap);
	const {
		boxWeightMap,
		boxRowBoxCounts,
	} = boxWeightMapResult;

	// Get the number of boxes
	const boxCount = getBoxCount(boxKeys,
		boxes,
		boxTypeVisibilityMap,
		boxVisibilityMap,
		boxWeightMap);

	const boxGapCount = (boxCount > 0) ? boxCount - 1 : 0;

	const boxGridHorizontalCount = Math.floor(maximumGridColumns);
	const boxGridVerticalCount = boxRowBoxCounts.length;

	// console.log(`boxesWidthInPixels=${boxesWidthInPixels}, boxesHeightInPixels=${boxesHeightInPixels}`)
	// console.log(`boxCount=${boxCount}, boxGapCount=${boxGapCount}, boxGridVerticalCount=${boxGridVerticalCount}, maximumGridColumns=${maximumGridColumns}`)

	const boxGridVerticalGapCount = (boxGridVerticalCount > 0) ? boxGridVerticalCount - 1 : 0;

	let boxGridTotalVerticalGapInPixels = 0;
	let boxTotalGapInPixels = 0;

	if (childLayout === ChildLayout.HORIZONTAL) {
		if (boxGapCount > 0) {
			boxTotalGapInPixels = calculateTotalGapInPixels(boxGapCount,
				horizontalBoxGapInPixels,
				boxesWidthInPixels);
			horizontalBoxGapInPixels = boxTotalGapInPixels / boxGapCount;
		}
	} else if (childLayout === ChildLayout.VERTICAL) {
		if (boxGapCount > 0) {
			boxTotalGapInPixels = calculateTotalGapInPixels(boxGapCount,
				verticalBoxGapInPixels,
				boxesHeightInPixels);
			verticalBoxGapInPixels = boxTotalGapInPixels / boxGapCount;
		}
	}	else if (childLayout === ChildLayout.GRID) {
		if (boxGapCount > 0) {
			boxGridTotalVerticalGapInPixels = calculateTotalGapInPixels(boxGridVerticalGapCount,
				verticalBoxGapInPixels,
				boxesHeightInPixels);
			verticalBoxGapInPixels = boxGridTotalVerticalGapInPixels / boxGridVerticalGapCount;
		}
	}

	let gridWidthInPixels = boxesWidthInPixels;
	if (childLayout === ChildLayout.HORIZONTAL) {
		gridWidthInPixels = boxesWidthInPixels - boxTotalGapInPixels;
	}

	let gridHeightInPixels = boxesHeightInPixels;
	if (childLayout === ChildLayout.VERTICAL) {
		gridHeightInPixels = boxesHeightInPixels - boxTotalGapInPixels;
	} else if (childLayout === ChildLayout.GRID) {
		gridHeightInPixels = boxesHeightInPixels - boxGridTotalVerticalGapInPixels;
	}  

	const layoutDimensions = calculateLayoutDimensions(gridWidthInPixels,
		gridHeightInPixels,
		GRID_WIDTH_IN_COLUMNS,
		GRID_HEIGHT_IN_ROWS,
		boxCount,
		childLayout);

	// Build the layout items
	const layoutItems = buildLayoutItems(boxKeys,
		boxes,
		boxTypeVisibilityMap,
		boxVisibilityMap,
		boxVisualisations,
		boxCount,
		boxGridHorizontalCount,
		boxGridVerticalCount,
		boxesKey,
		childLayout,
		boxWeightMap,
		boxRowBoxCounts,
		layoutDimensions);

	let currentLayoutItemIndex = 0;

	const boxGridRowLayoutItemInfo = boxRowBoxCounts
		.map((rowLayoutItemCount: number) => {
			let visibleRowLayoutItemCount = 0;
			for(let i=0; i < rowLayoutItemCount; i += 1) {
				const rowLayoutItemIndex = currentLayoutItemIndex + i;
				const rowLayoutItem = layoutItems[rowLayoutItemIndex];
				if (rowLayoutItem && rowLayoutItem.w > 0) {
					visibleRowLayoutItemCount += 1;
				} 
			}

			const rowGridHorizontalGapCount = (visibleRowLayoutItemCount > 0) ? visibleRowLayoutItemCount - 1 : 0;
		
			const rowGridTotalHorizontalGapInPixels = calculateTotalGapInPixels(rowGridHorizontalGapCount,
				horizontalBoxGapInPixels,
				boxesWidthInPixels);
			
			const rowGridHorizontalBoxGapInPixels = rowGridTotalHorizontalGapInPixels / rowGridHorizontalGapCount;

			const rowGridWidthInPixels = boxesWidthInPixels - rowGridTotalHorizontalGapInPixels;

			const rowGridColumnWidthInPixels = rowGridWidthInPixels / GRID_WIDTH_IN_COLUMNS;

			currentLayoutItemIndex += rowLayoutItemCount;

			return {
				rowGridHorizontalGapCount,
				rowGridTotalHorizontalGapInPixels,
				rowGridHorizontalBoxGapInPixels,
				rowGridWidthInPixels,
				rowGridColumnWidthInPixels
			};
		});

	// console.log({boxRowBoxCounts})
	// console.log({boxGridRowLayoutItemInfo})

	// Render the boxes
	let layoutItemVisibleIndex = 0;
	let layoutItemColumn = 0;
	let layoutItemVisibleColumn = 0;
	let layoutItemRow = 0;

	// console.log(layoutItems);
	for (let i=0; i < layoutItems.length; i += 1) {
		const layoutItem = layoutItems[i];

		// Get the box key
		const boxKey = layoutItem.i;

		if (layoutItemRow >= boxRowBoxCounts.length) {
			console.log(`error, could not handle ${boxKey}`);
			break;
		}

		const currentRowItemCount = boxRowBoxCounts[layoutItemRow];
		// console.log(`layoutItemColumn=${layoutItemColumn}, layoutItemRow=${layoutItemRow}, currentRowItemCount=${currentRowItemCount}`);

		// Calculate the box width and height (in pixels) from the layout
		let layoutBoxWidthInPixels = layoutItem.w * layoutDimensions.gridColumnWidthInPixels;
		if (childLayout === ChildLayout.GRID) {
			const { rowGridColumnWidthInPixels } = boxGridRowLayoutItemInfo[layoutItemRow];

			layoutBoxWidthInPixels = layoutItem.w * rowGridColumnWidthInPixels;
		}

		const layoutBoxHeightInPixels = layoutItem.h * layoutDimensions.gridRowHeightInPixels;

		// Get the box width and height (in pixels)
		const boxWidthInPixels = (layoutBoxWidthInPixels >= 0) ? layoutBoxWidthInPixels : 0;
		const boxHeightInPixels = (layoutBoxHeightInPixels >= 0) ? layoutBoxHeightInPixels : 0;

		// Calculate the position of the box
		let boxLeftInPixels = layoutItem.x * layoutDimensions.gridColumnWidthInPixels;
		if (childLayout === ChildLayout.HORIZONTAL && (boxGapCount > 0) && (boxWidthInPixels > 0)) {
			boxLeftInPixels = boxLeftInPixels + (layoutItemVisibleIndex * horizontalBoxGapInPixels)
		} else if (childLayout === ChildLayout.GRID) {
			const {
				rowGridColumnWidthInPixels,
				rowGridHorizontalGapCount,
				rowGridHorizontalBoxGapInPixels,
			} = boxGridRowLayoutItemInfo[layoutItemRow];

			boxLeftInPixels = layoutItem.x * rowGridColumnWidthInPixels;

			if ((rowGridHorizontalGapCount > 0) && (boxWidthInPixels > 0)) {
				boxLeftInPixels = boxLeftInPixels + (layoutItemVisibleColumn * rowGridHorizontalBoxGapInPixels);
			}
		}

		let boxTopInPixels = layoutItem.y * layoutDimensions.gridRowHeightInPixels;
		if (childLayout === ChildLayout.VERTICAL && (boxGapCount > 0) && (boxHeightInPixels > 0)) {
			boxTopInPixels = boxTopInPixels + (layoutItemVisibleIndex * verticalBoxGapInPixels)
		} else if (childLayout === ChildLayout.GRID  && (boxGridVerticalGapCount > 0) && (boxHeightInPixels > 0)) {
			boxTopInPixels = boxTopInPixels + (layoutItemRow * verticalBoxGapInPixels)
		}

		if ((childLayout === ChildLayout.HORIZONTAL && (boxWidthInPixels > 0)) ||
			(childLayout === ChildLayout.VERTICAL && (boxHeightInPixels > 0))) {
			layoutItemVisibleIndex += 1;
		}

		if (boxWidthInPixels > 0) {
			layoutItemVisibleColumn += 1;
		}
		
		layoutItemColumn += 1;
		if (layoutItemColumn >= currentRowItemCount) {
			layoutItemColumn = 0;
			layoutItemVisibleColumn = 0;
			layoutItemRow += 1;
		}

		const boxDimensions: BoxDimensions = {
			boxKey,
			leftInPixels: boxLeftInPixels,
			topInPixels: boxTopInPixels,
			widthInPixels: boxWidthInPixels,
			heightInPixels: boxHeightInPixels,
		}

		boxDimensionsInfo.boxDimensions.push(boxDimensions);
	};

	boxDimensionsInfo.boxWeightMap = boxWeightMap;
	boxDimensionsInfo.boxRowBoxCounts = boxRowBoxCounts;
	boxDimensionsInfo.layoutDimensions = layoutDimensions;
	boxDimensionsInfo.layoutItems = layoutItems;

	return boxDimensionsInfo;
}