import React, { useEffect, useState } from "react";

import { Input, Modal, Select } from "antd";
import * as lensesLib from "@lib/lenses/lenses";
import * as boxLib from "@lib/box/box";
import LabelledElement from "@components/molecules/LabelledElement";
import { AttributeExpressionViewModel } from "./hooks/useAttributeQueryRestrictions";
import { AttributeValue } from "@lib/box/attribute";
import { AttributeTypeMap } from "@lib/box/attribute-type";
import { parameters } from "@lib/3xpr";

export interface AddEditAttributeExpressionDialogProps {
	onOKButtonClick: (index: number, attributeExpression: AttributeExpressionViewModel) => void;
	onCancelButtonClick: () => void;
	index: number;
	isAdding: boolean;
	isVisible: boolean;
	attributeExpression: AttributeExpressionViewModel | null;
	attributeTypes: AttributeTypeMap;
	parameters: lensesLib.ParameterMap;
}

const AddEditAttributeExpressionDialog: React.FC<AddEditAttributeExpressionDialogProps> = (props) => {
	// Get the title
	const title = props.isAdding ? "Add Attribute Expression" : "Edit Attribute Expression";


	const [index, setIndex] = useState(-1);

	const [operator, setOperator] = useState<lensesLib.AttributeExpressionOperator>(
		props.attributeExpression !== null && props.attributeExpression.operator !== null ? props.attributeExpression.operator : lensesLib.AttributeExpressionOperator.EQUAL);

	const [attributeParameter, setAttributeParameter] = useState<string | undefined>(
		props.attributeExpression !== null ? props.attributeExpression.attributeParameter : undefined
	);

	const [attributeType, setAttributeType] = useState<string | undefined>(
		props.attributeExpression !== null ? props.attributeExpression.attributeType : undefined
	);

	const [attributeValue, setAttributeValue] = useState<string | undefined>(
		props.attributeExpression !== null ? props.attributeExpression.attributeValue.toString() : undefined
	);


	// useEffect(() => {
	// 	setValue(props.attributeExpression? props.attributeExpression.value : lensesLib.EqualityOperator.EQUAL);
	// 	setKey(
	// 		props.attributeExpression !== null ? props.attributeExpression.key : 
	// 		Object.keys(props.boxes)[0]
	// 	);
	// }, [props.attributeExpression, props.boxes])

	const handleOKButtonClick = () => {

		if (attributeType !== undefined && attributeValue !== undefined && attributeParameter !== undefined) {
			const viewModel: AttributeExpressionViewModel = { 
				attributeParameter: attributeParameter, attributeType: attributeType, attributeValue: attributeValue,
				operator: operator
			 };

			props.onOKButtonClick(index, viewModel);
		}

	};


	const attributeTypesArray = Object.keys(props.attributeTypes)
		.map((attributeTypeKey) => {
			return { key: attributeTypeKey, name: props.attributeTypes[attributeTypeKey].name }
		})
		.sort((a, b) => {
			if (a.name < b.name) {
				return -1;
			}

			if (a.name > b.name) {
				return 1;
			}

			return 0;
		});

	return <Modal
		title={title}
		open={props.isVisible}
		onOk={handleOKButtonClick}
		onCancel={props.onCancelButtonClick}
		zIndex={9999}
		width="75%"
	>
		<div>
			<div
				style={{
					width: "100%",
					display: "grid",
					gridTemplateColumns: "0.1fr 0.9fr",
					gap: "4px 12px",
					padding: 0,
					margin: 0,
					overflowY: "visible",
				}}
			>
				<LabelledElement labelText="Attribute Type">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={attributeType}
						onChange={(newKey: string) => {
							setAttributeType(newKey);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						{
							attributeTypesArray.map((attributeType, index) => {
								return <Select.Option key={attributeType.key} value={attributeType.key}>{attributeType.name}</Select.Option>
							})
						}
					</Select>
				</LabelledElement>

				<LabelledElement labelText="Comparison Operator">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={operator}
						onChange={(newValue: lensesLib.AttributeExpressionOperator) => {
							setOperator(newValue);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						<Select.Option value={lensesLib.AttributeExpressionOperator.EQUAL.toString()}>{lensesLib.AttributeExpressionOperator.EQUAL}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.NOT_EQUAL.toString()}>{lensesLib.AttributeExpressionOperator.NOT_EQUAL}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.TRUE.toString()}>{lensesLib.AttributeExpressionOperator.TRUE}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.FALSE.toString()}>{lensesLib.AttributeExpressionOperator.FALSE}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.INCLUDES.toString()}>{lensesLib.AttributeExpressionOperator.INCLUDES}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.GREATER_THAN.toString()}>{lensesLib.AttributeExpressionOperator.GREATER_THAN}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.GREATER_THAN_OR_EQUAL_TO.toString()}>{lensesLib.AttributeExpressionOperator.GREATER_THAN_OR_EQUAL_TO}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.LESS_THAN.toString()}>{lensesLib.AttributeExpressionOperator.LESS_THAN}</Select.Option>
						<Select.Option value={lensesLib.AttributeExpressionOperator.LESS_THAN_OR_EQUAL_TO.toString()}>{lensesLib.AttributeExpressionOperator.LESS_THAN_OR_EQUAL_TO}</Select.Option>
					</Select>
				</LabelledElement>

				<LabelledElement labelText="Parameter">
					<Select
						key={`input-operator`}
						size="large"
						placeholder="Please select"
						value={attributeParameter}
						onChange={(newKey: string | undefined) => {
							setAttributeParameter(newKey);
						}}
						style={{ width: "100%" }}
						getPopupContainer={(node) => {
							let popupContainer: HTMLElement | null =
								window.document.documentElement;
							if (node && node.parentElement) {
								popupContainer = node.parentElement;
							}
							return popupContainer as HTMLElement;
						}}
					>
						<Select.Option key={"-none-"} value={undefined}>-Custom Value-</Select.Option>
						{
							Object.keys(props.parameters).map((key, index) => {
								return <Select.Option key={key} value={props.parameters[key].toString()}>{props.parameters[key].toString()}</Select.Option>
							})
						}
					</Select>
				</LabelledElement>

				{attributeParameter === "-none-" &&
				<LabelledElement labelText="Value">
					<Input value={attributeValue} onChange={(event:  React.ChangeEvent<HTMLInputElement>) => { setAttributeValue(event.target.value)}} />
				</LabelledElement>}

			</div>

		</div>
	</Modal>
}

export default AddEditAttributeExpressionDialog;