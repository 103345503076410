/*
    Functions to:
        Add a box
        Remove a box
        Update a box
            Add attributes
            Remove attributes
            Update attributes
                Validation
        Get a list of a boxes attributes
            getAttributeTypesForBox
            getCategoryForBox
*/
import * as CSS from "csstype";

import { v4 as uuid } from "uuid";

import { SmartPageMap } from "@lib/smart-pages/smart-page";

import { TextAlignment, TextSizing } from "@lib/style/text";

import * as attributeLib from "./attribute";
import * as propertyLib from "./box-properties";
import * as valueTypeLib from "./value-type";
import * as attributeTypeLib from "./attribute-type";
import * as boxTypeLib from "./box-type";
import * as boxTypeCounterLib from "./box-type-counter";
import * as renderFunctionLib from "./render-function";

import {
	ChildLayout,
	Properties,
	createBoxDefaultPropertyMap,
	setBoxPropertiesFromBoxProperties,
	getPropertyMapFromProperties,
} from './box-properties'
import { BoxStyleMap } from "./box-style";
import { BoxTypeVisibilityMap } from './box-type'
import { BoxWeightMap } from "./box-weight";

export interface Box {
	name: string;
	url: string | undefined;
	order: number;
	boxType: string;
	defaultChildBoxType: string | undefined;
	inheritParentProperties: boolean;
	defaultProperties: propertyLib.PropertyMap | undefined;
	inheritParentAttributes: boolean;
	attributes: attributeLib.AttributeMap | undefined;
	children: BoxMap | undefined;
	smartPages: SmartPageMap | undefined;
}

export type BoxMap = { [key: string]: Box };


export type BoxCountMap = { [key: string]: number };

export interface BoxVisibility {
	isVisible: boolean;
	isInLayout: boolean;
}

export type BoxVisibilityMap = {
	[key: string]: BoxVisibility;
};

export interface BoxParentMap {
	// The key is a box key, and the value is the key of its parent
	[key: string]: string;
}

export interface BoxAssociationsMap {
	// The key is a box key, and the value is the associations of the box
	[key: string]: string[];
}

export interface BoxSelectionInfo {
	color: string;
}

export interface BoxSelectionInfoMap {
	[key: string]: BoxSelectionInfo;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let private_lookups = 0;
let private_lookupBreakdownByBox: Record<string, number> = {};
let private_lookupBreakdownByBoxType: Record<string, number> = {};
let private_lookupBreakdownByAttributeType: Record<string, number> = {};

let private_associationCachingEnabled = false;
let private_associationCache: Record<string, string[]> = {};

const setAssociationCacheEnabled = (isEnabled: boolean): void => {
	private_associationCachingEnabled = isEnabled;
}

const clearAssociationCache = (): void => {
	private_associationCache = {};
}

const getCachedAssociationKey  = (boxKey: string,
	attributeTypeKey: string,
	shouldGetRelatedAssociations: boolean): string => {
	return ''.concat(boxKey, attributeTypeKey, String(shouldGetRelatedAssociations));
}

const getCachedAssociation = (boxKey: string,
	attributeTypeKey: string,
	shouldGetRelatedAssociations: boolean): string[] | undefined => {
	if (!private_associationCachingEnabled) {
		return undefined;
	}

	const cachedAssociationKey = getCachedAssociationKey(boxKey,
		attributeTypeKey,
		shouldGetRelatedAssociations);

	const cachedAssociations = private_associationCache[cachedAssociationKey];
	return cachedAssociations;
}

const setCachedAssociation = (boxKey: string,
	attributeTypeKey: string,
	shouldGetRelatedAssociations: boolean,
	associationList: string[]): void => {
	if (private_associationCachingEnabled) {
		const cachedAssociationKey = getCachedAssociationKey(boxKey,
			attributeTypeKey,
			shouldGetRelatedAssociations);

		private_associationCache[cachedAssociationKey] = associationList;
	}
}

export const getFlattenedBoxMap = (
	boxMap: BoxMap | undefined,
	currentFlattenedBoxMap: BoxMap | undefined
): BoxMap | undefined => {
	// If we don't have box map or a flattened boxmap, there's nothing to return
	if (!boxMap || !currentFlattenedBoxMap) {
		return undefined;
	}

	// Get the box map keys
	const boxMapKeys = Object.keys(boxMap);

	// Add the current level of the tree to the flattened box map
	let updatedFlattenedBoxMap: BoxMap | undefined = boxMapKeys.reduce(
		(reducedBoxMap: BoxMap, boxKey: string) => {
			reducedBoxMap[boxKey] = boxMap[boxKey];
			return reducedBoxMap;
		},
		currentFlattenedBoxMap
	);

	// Loop thru the box map, checking child box names
	for (
		let boxMapKeyIndex = 0;
		boxMapKeyIndex < boxMapKeys.length;
		boxMapKeyIndex += 1
	) {
		// Get the box map
		const boxMapKey = boxMapKeys[boxMapKeyIndex];

		// Get the box
		const box = boxMap[boxMapKey];

		// Does the box have a children property?
		if (Object.prototype.hasOwnProperty.call(box, "children")) {
			// Get the box child keys
			const boxChildKeys = box.children ? Object.keys(box.children) : [];

			// Does the box have children?
			if (boxChildKeys.length > 0) {
				// Do we have a flattened box map?
				if (updatedFlattenedBoxMap) {
					// Add the children
					updatedFlattenedBoxMap = boxChildKeys.reduce(
						(reducedBoxMap: BoxMap, boxKey: string) => {
							reducedBoxMap[boxKey] = boxMap[boxKey];
							return reducedBoxMap;
						},
						updatedFlattenedBoxMap
					);

					// Get the boxes of the children
					updatedFlattenedBoxMap = getFlattenedBoxMap(
						box.children,
						updatedFlattenedBoxMap
					);
				}
			}
		}
	}

	return updatedFlattenedBoxMap;
};

export const applyFunctionToBoxes = (
	boxMap: BoxMap | undefined,
	functionToApply: (boxKey: string, box: Box) => void
): void => {
	// If we don't have box map, there's nothing to do
	if (!boxMap) {
		return;
	}

	const boxKeys = Object.keys(boxMap);
	for (let i=0; i < boxKeys.length; i += 1) {
		const boxMapKey = boxKeys[i];

		// Get the box
		const box = boxMap[boxMapKey];

		// Apply the function to the box
		functionToApply(boxMapKey, box);

		// Does the box have a children property?
		if (Object.prototype.hasOwnProperty.call(box, "children")) {
			// Get the box children
			const boxChildren = box.children;
			if (boxChildren) {
				// Does the box have children?
				const boxChildrenKeys = Object.keys(boxChildren);
				for (let j=0; j < boxChildrenKeys.length; j += 1) {
					const boxChildKey = boxChildrenKeys[j];

					// Get the child box
					const childBox = boxChildren[boxChildKey];

					// Apply the function to the box
					functionToApply(boxChildKey, childBox);

					// Apply the function to the boxes of the children
					applyFunctionToBoxes(boxChildren, functionToApply);
				}
			}
		}
	};
};

export const findBoxInBoxMapForKey = (
	boxMap: BoxMap | undefined,
	boxKey: string
): Box | undefined => {
	// The box we found
	let foundBox = undefined;

	// Do we have a box map?
	if (boxMap) {
		// Get the box map keys
		const boxMapKeys = Object.keys(boxMap);

		// Is the box we're looking for the in parents children?
		const boxMapKeyIndex = boxMapKeys.indexOf(boxKey);
		if (boxMapKeyIndex >= 0) {
			// Get the box map key
			const boxMapKey = boxMapKeys[boxMapKeyIndex];

			// We've found the box
			foundBox = boxMap[boxMapKey];
		}

		// Have we still not found the box?
		if (!foundBox) {
			// Loop thru the box map, checking child box names
			for (
				let boxMapKeyIndex = 0;
				boxMapKeyIndex < boxMapKeys.length;
				boxMapKeyIndex += 1
			) {
				// Get the box map key
				const boxMapKey = boxMapKeys[boxMapKeyIndex];

				// Get the box
				const box = boxMap[boxMapKey];

				// Does the box have a children property?
				if (Object.prototype.hasOwnProperty.call(box, "children")) {
					// Get the box child keys
					const boxChildKeys = box.children
						? Object.keys(box.children)
						: [];

					// Does the box have children?
					if (boxChildKeys.length > 0) {
						// Try and find the box in the children
						const childBox = findBoxInBoxMapForKey(
							box.children,
							boxKey
						);

						// Is it the box we're looking for?
						if (childBox) {
							// We've found the box
							foundBox = childBox;

							// Stop looping
							break;
						}
					}
				}
			}
		}
	}

	return foundBox;
};

export const findBoxParentInBoxMapForKey = (
	currentBoxParent: Box | undefined,
	currentBoxParentChildBoxMap: BoxMap | undefined,
	boxKey: string
): Box | undefined => {
	// The parent of the box we found
	let foundParentBox = undefined;

	// Do we have a box map
	if (currentBoxParentChildBoxMap) {
		// Get the box map keys
		const currentBoxParentChildBoxMapKeys = Object.keys(
			currentBoxParentChildBoxMap
		);

		// Is the box we're looking for the in parents children?
		if (currentBoxParentChildBoxMapKeys.indexOf(boxKey) >= 0) {
			// We've found the box
			foundParentBox = currentBoxParent;
		}

		// Have we still not found the box parent?
		if (!foundParentBox) {
			// Loop thru the box map, checking child box names
			for (
				let boxMapKeyIndex = 0;
				boxMapKeyIndex < currentBoxParentChildBoxMapKeys.length;
				boxMapKeyIndex += 1
			) {
				// Get the child box map key
				const childBoxMapKey =
					currentBoxParentChildBoxMapKeys[boxMapKeyIndex];

				// Get the child box
				const childBox = currentBoxParentChildBoxMap[childBoxMapKey];

				// Does the box have a children property?
				if (
					Object.prototype.hasOwnProperty.call(childBox, "children")
				) {
					// Get the child box child keys
					const childBoxChildKeys = childBox.children
						? Object.keys(childBox.children)
						: [];

					// Does the box have children?
					if (childBoxChildKeys.length > 0) {
						// Try and find the box in the children
						const foundBox = findBoxParentInBoxMapForKey(
							childBox,
							childBox.children,
							boxKey
						);

						// Is it the box we're looking for?
						if (foundBox) {
							// We've found the parent box
							foundParentBox = foundBox;

							// Stop looping
							break;
						}
					}
				}
			}
		}
	}

	return foundParentBox;
};

export const haveBoxesChanged = (
	currentBoxMap: BoxMap | undefined,
	previousBoxMap: BoxMap | undefined
): boolean => {
	// Whether the boxes have changed
	let haveChanged = false;

	// Do we have box maps?
	if (currentBoxMap && previousBoxMap) {
		// Get the box map keys (sorted using the default sort so they should be in
		// the same order if equal)
		const currentBoxMapKeys = Object.keys(currentBoxMap).sort();
		const previousBoxMapKeys = Object.keys(previousBoxMap).sort();

		// Do we now have a different number of boxes?
		if (currentBoxMapKeys.length !== previousBoxMapKeys.length) {
			// The boxes have changed
			haveChanged = true;
		} else {
			// Are they keys different?
			if (
				!currentBoxMapKeys.every(
					(value, index) => value === previousBoxMapKeys[index]
				)
			) {
				// The boxes have changed
				haveChanged = true;
			} else {
				// Loop thru the box maps, checking top-level boxes only
				for (
					let boxMapKeyIndex = 0;
					boxMapKeyIndex < currentBoxMapKeys.length;
					boxMapKeyIndex += 1
				) {
					// Get the box map key (since they keys are the same we can assume the
					// previous box map has the same key at the same index)
					const boxMapKey = currentBoxMapKeys[boxMapKeyIndex];

					// Get the boxes
					const currentBox = currentBoxMap[boxMapKey];
					const previousBox = previousBoxMap[boxMapKey];

					// Are the boxes not the same?
					if (currentBox.name !== previousBox.name) {
						// TODO: Check attributes have changed

						// The boxes have changed
						haveChanged = true;

						// Stop looping
						break;
					}
				}
			}
		}
	}

	return haveChanged;
};

export const createDefaultBox = (boxTypeKey: string,
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined,
	order: number): Box => {

	const attributes: attributeLib.AttributeMap = {};

	if (boxTypeMap) {
		const boxType = boxTypeMap[boxTypeKey];
		if (boxType) {
			// Get the box type attribute types
			const attributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(boxTypeKey);	

			if (attributeTypes) {
				const attributeTypeKeys = Object.keys(attributeTypes);
				for (let i=0; i < attributeTypeKeys.length; i += 1) {
					const attributeTypeKey = attributeTypeKeys[i];

					const attributeType = attributeTypes[attributeTypeKey];
					if (attributeType) {
						if (attributeType.defaultValue !== '') {
							attributes[attributeTypeKey] = attributeType.defaultValue;
						}
					}
				}
			}
		}
	}

	return {
		name: "",
		url: "",
		order,
		boxType: boxTypeKey,
		defaultChildBoxType: boxTypeKey,
		inheritParentProperties: false,
		defaultProperties: createBoxDefaultPropertyMap(),
		inheritParentAttributes: false,
		attributes,
		children: {},
		smartPages: {},
	};
}

export const getHighestBoxOrder = (boxes: BoxMap): number => {
	let highestOrder = -1;

	const boxKeys = Object.keys(boxes);

	for (let i=0; i < boxKeys.length; i += 1) {
		const boxKey = boxKeys[i];

		const box = boxes[boxKey];
		if (box.order > highestOrder) {
			highestOrder = box.order;
		}
	}

	return highestOrder;
}

export const setBoxPropertiesFromStyles = (
	boxProperties: Properties | undefined,
	styles: string,
	boxStyles: BoxStyleMap | undefined
) => {
	// If we don't have box properties there's nothing to set
	if (!boxProperties) {
		return;
	}

	// Do we actyually have box styles and a list of styles to set?
	if (boxStyles && styles) {
		// Convert the styles to an array of strings that
		// represent the keys of the styles of this box
		const boxStyleKeyList = styles ? styles.split(",") : [];

		// Loop thru each of the box styles
		for (let i=0; i < boxStyleKeyList.length; i += 1) {
			const boxStyleKey = boxStyleKeyList[i];
	
			// Does the box style actually exist?
			if (Object.prototype.hasOwnProperty.call(boxStyles, boxStyleKey)) {
				// Get the box properties of the each style
				const styleBoxProperties = boxStyles[boxStyleKey].properties;

				// Set the box properties
				// TODO: should this be recursive to allow for style hierarchies?
				// To do this we'd just call setBoxPropertiesFromBoxPropertyMap
				// with the box style properties
				setBoxPropertiesFromBoxProperties(
					boxProperties,
					styleBoxProperties
				);
			}
		}
	}
};

export const setBoxPropertiesFromBoxPropertyMap = (
	boxProperties: Properties | undefined,
	boxPropertyMap: propertyLib.PropertyMap | undefined,
	boxStyles: BoxStyleMap | undefined
) => {
	// If we don't have box properties or a property map there's nothing to set
	if (!boxProperties || !boxPropertyMap) {
		return;
	}

	// Loop thru the box property map
	const boxPropertyMapKeys = Object.keys(boxPropertyMap);
	for (let i=0; i < boxPropertyMapKeys.length; i += 1) {
		const boxPropertyKey = boxPropertyMapKeys[i];

		// Does the box have a property with this key?
		if (
			Object.prototype.hasOwnProperty.call(boxProperties, boxPropertyKey)
		) {
			// Get the box property value
			let boxPropertyValue = boxPropertyMap[boxPropertyKey];

			// Only set the value if it's valid
			if (
				boxPropertyValue !== null &&
				boxPropertyValue !== undefined &&
				boxPropertyValue !== ""
			) {
				// Covert the box property value to the correct type
				if (boxPropertyKey === "childLayout") {
					// Get the box property value as a key we can use to look up the box
					// child layout
					const boxChildLayoutKey = boxPropertyValue.toUpperCase() as keyof typeof ChildLayout;
					if (boxChildLayoutKey) {
						boxProperties.childLayout =
							ChildLayout[boxChildLayoutKey];
						// console.log(`${boxPropertyKey} - ${childLayout}`);
					}
				} else if (boxPropertyKey === "textSizeInPixels") {
					// console.log(`${boxPropertyKey} - textSizeInPixels - ${boxPropertyValue}`);
					boxProperties.textSizeInPixels = Number(boxPropertyValue);
				} else if (boxPropertyKey === "textSizing") {
					// console.log(`${boxPropertyKey} - textSizing - ${boxPropertyValue}`);
					boxProperties.textSizing = boxPropertyValue as TextSizing;
				} else if (boxPropertyKey === "textAlignment") {
					// console.log(`${boxPropertyKey} - textAlignment - ${boxPropertyValue}`);
					boxProperties.textAlignment = boxPropertyValue as TextAlignment;
				} else if (boxPropertyKey === "textAlignmentPaddingInPixels") {
					// console.log(`${boxPropertyKey} - textAlignmentPaddingInPixels - ${boxPropertyValue}`);
					boxProperties.textAlignmentPaddingInPixels = Number(boxPropertyValue);
				} else if (boxPropertyKey === "textIsVertical") {
					boxProperties.textIsVertical =
						!!boxPropertyValue &&
						boxPropertyValue.toLowerCase() === "true"
							? true
							: false;
					// console.log(`${boxPropertyKey} -  ${boxPropertyValue} - boxProperties.textIsVertical = ${boxProperties.textIsVertical}`);
				} else if (boxPropertyKey === "fontFamily") {
					// console.log(`${boxPropertyKey} - ${boxPropertyValue}`);
					boxProperties.fontFamily = boxPropertyValue as CSS.Property.FontFamily;
				} else if (boxPropertyKey === "fontStyle") {
					// console.log(`${boxPropertyKey} - ${boxPropertyValue}`);
					boxProperties.fontStyle = boxPropertyValue as CSS.Property.FontStyle;
				} else if (boxPropertyKey === "fontWeight") {
					// console.log(`${boxPropertyKey} - ${boxPropertyValue}`);
					boxProperties.fontWeight = boxPropertyValue as CSS.Property.FontWeight;
				} else if (boxPropertyKey === "borderRadius") {
					// console.log(`${boxPropertyKey} - ${boxPropertyValue}`);
					boxProperties.borderRadius = Number(boxPropertyValue);
				} else if (boxPropertyKey === "borderSizeInPixels") {
					// console.log(`${boxPropertyKey} - ${boxPropertyValue}`);
					boxProperties.borderSizeInPixels = Number(boxPropertyValue);
				} else if (
					boxPropertyKey === "badge1" ||
					boxPropertyKey === "badge2" ||
					boxPropertyKey === "badge3" ||
					boxPropertyKey === "badge4" ||
					boxPropertyKey === "badge5" ||
					boxPropertyKey === "badge6" ||
					boxPropertyKey === "badge7" ||
					boxPropertyKey === "badge8" ||
					boxPropertyKey === "badge9" ||
					boxPropertyKey === "badge10" ||
					boxPropertyKey === "badge11" ||
					boxPropertyKey === "badge12" ||
					boxPropertyKey === "badge13" ||
					boxPropertyKey === "badge14" ||
					boxPropertyKey === "badge15" ||
					boxPropertyKey === "badge16" ||
					boxPropertyKey === "badge17" ||
					boxPropertyKey === "badge18" ||
					boxPropertyKey === "badge19" ||
					boxPropertyKey === "badge20" ||
					boxPropertyKey === "badge21" ||
					boxPropertyKey === "badge22" ||
					boxPropertyKey === "badge23" ||
					boxPropertyKey === "badge24" ||
					boxPropertyKey === "badge25" ||
					boxPropertyKey === "badge26" ||
					boxPropertyKey === "badge27" ||
					boxPropertyKey === "badge28" ||
					boxPropertyKey === "badge29" ||
					boxPropertyKey === "badge30" ||
					boxPropertyKey === "badge31" ||
					boxPropertyKey === "badge32"
				) {
					// The badge (undefined by default)
					let badge = undefined;

					// Try and parse the badge JSON
					try {
						badge = JSON.parse(boxPropertyValue);
					} catch (e) {
						badge = undefined;
					}

					// console.log('Parsing Badge')
					// console.log(`${boxPropertyKey} - ${boxPropertyValue}`);
					// console.log(badge)
					boxProperties[boxPropertyKey] = badge;
				} else if (boxPropertyKey === "styles") {
					// Set the box properties from the styles
					setBoxPropertiesFromStyles(
						boxProperties,
						boxPropertyValue,
						boxStyles
					);
				} else {
					// Update the box properties with the new value
					(boxProperties as any)[boxPropertyKey] = boxPropertyValue;
				}
			}
		}
	}
};

export const setBoxParents = (
	boxParentMap: BoxParentMap | undefined,
	boxKey: string,
	flattenedBoxMap: BoxMap | undefined
) => {
	// Check our inputs are valid
	if (boxParentMap && flattenedBoxMap) {
		// Get the box
		const box = flattenedBoxMap[boxKey];
		if (!box) {
			return;
		}

		// Get the box children
		const boxChildren = box.children;
		if (!boxChildren) {
			return;
		}

		// Set the box as the parent of each of it's children
		const boxChildrenKeys = Object.keys(boxChildren);
		for (let i=0; i < boxChildrenKeys.length; i += 1) {
			const childBoxKey = boxChildrenKeys[i];

			boxParentMap[childBoxKey] = boxKey;

			// Do the same for the childs children
			setBoxParents(boxParentMap, childBoxKey, flattenedBoxMap);
		}
	}
};

const setRelatedAssociations = (
	currentAssociationsList: string[],
	currentAssociationsBoxTypesList: string[],
	boxKey: string,
	parentBoxKey: string,
	flattenedBoxMap: BoxMap | undefined,
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined,
	boxTypeToBoxKeysMap: Record<string, string[]>
) => {
	// console.log(`\tsetting related associations for ${boxKey}`)

	// Check our inputs are valid
	if (flattenedBoxMap && boxTypeMap && boxTypeVisibilityMap) {
		// Get the box
		const box = flattenedBoxMap[boxKey];
		if (box) {
			// Get the box type key
			const boxTypeKey = box.boxType;

			// Get whether the box type is visible
			const isBoxTypeVisible =
				boxTypeKey &&
				boxTypeVisibilityMap &&
				Object.prototype.hasOwnProperty.call(
					boxTypeVisibilityMap,
					boxTypeKey
				)
					? boxTypeVisibilityMap[boxTypeKey].isVisible
					: false;

			// Only set the associations if the box is visible
			if (isBoxTypeVisible) {
				// Get the box type
				const boxType = boxTypeMap[boxTypeKey];
				if (boxType) {
					// Get the box type attribute types
					const attributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(boxTypeKey);
					if (attributeTypes) {
						// Get the attribute type visibilities
						const attributeTypeVisibilityMap = boxTypeLib.getAttributeTypeVisibilityMapForBoxTypeRecursive(
							boxTypeMap,
							boxTypeKey,
							boxTypeVisibilityMap
						);

						// Get the box attributes
						const boxAttributes = box.attributes;
						if (boxAttributes) {
							// Get all attributes that are associations
							Object.keys(boxAttributes).forEach(
								(attributeTypeKey) => {
									// Get the attribute type
									const boxAttributeType = attributeTypes[attributeTypeKey];
									if (boxAttributeType) {
										// Are we dealing with an associations attribute?
										if (boxAttributeType.valueType === valueTypeLib.ValueTypeKey.Associations) {
											// Is the attribute type visible?
											const attributeTypeVisibility = attributeTypeVisibilityMap
												? attributeTypeVisibilityMap[
														attributeTypeKey
												  ]
												: false;

											if (attributeTypeVisibility) {
												// If we don't have permitted associations box types,
												// don't check for them
												const ignorePermittedAssociationBoxTypes = !boxAttributeType.permittedAssociationBoxTypes;

												// Get the permitted box types of the association
												// (if we're not ignoring them)
												const permittedAssociationBoxTypesList = !ignorePermittedAssociationBoxTypes
													? boxAttributeType.permittedAssociationBoxTypes.split(
															","
													  )
													: [];

												// Get the associations
												const associationsList = getAttributeAssociation(boxKey,
													boxTypeKey,
													attributeTypeKey,
													boxAttributes,
													attributeTypes,
													flattenedBoxMap,
													boxTypeMap,
													attributeTypeVisibilityMap,
													boxTypeVisibilityMap,
													boxTypeToBoxKeysMap,
													false);

												// The local visited association box types for these associations
												// Initially we've only visited the current box type
												const localAssociationsBoxTypesList: string[] = [
													boxTypeKey,
												];

												// Set the related associations
												associationsList.forEach((associatedBoxKey) => {
													// Get the association box
													const associatedBox = flattenedBoxMap[associatedBoxKey];
													if (associatedBox) {
														// Get the associated box type key
														const associatedBoxTypeKey =
															associatedBox.boxType;
														if (associatedBoxTypeKey) {
															// console.log('')
															// console.log(`\tassociatedBoxTypeKey=${associatedBoxTypeKey}`)

															// console.log('\tpermittedAssociationBoxTypesList')
															// console.log(permittedAssociationBoxTypesList)

															// Is the associated box type permitted, or are
															// we ignoring permitted associated box types?
															const foundAssociatedBoxTypeIndex = permittedAssociationBoxTypesList
																.findIndex((key) => key === associatedBoxTypeKey);
															if (foundAssociatedBoxTypeIndex >= 0 || ignorePermittedAssociationBoxTypes) {
																// console.log(`\tfoundAssociatedBoxTypeIndex=${foundAssociatedBoxTypeIndex}`)
																// console.log(`\tignorePermittedAssociationBoxTypes=${ignorePermittedAssociationBoxTypes}`)

																// Have we not already visited that box type?
																const foundCurrentAssociationsBoxTypesListIndex = currentAssociationsBoxTypesList
																	.findIndex((key) => key === associatedBoxTypeKey);
																if (foundCurrentAssociationsBoxTypesListIndex < 0) {
																	// console.log('\tcurrentAssociationsBoxTypesList')
																	// console.log(currentAssociationsBoxTypesList)

																	// Ignore our parent so we don't infinitely recurse
																	if (associatedBoxKey !== parentBoxKey) {
																		// Is the box key not already in the current associations?
																		const foundCurrentAssociationIndex = currentAssociationsList
																			.findIndex((key) => key === associatedBoxKey);
																		if (foundCurrentAssociationIndex < 0) {
																			// console.log('\tcurrentAssociationsList')
																			// console.log(currentAssociationsList)

																			// Add the association the the list of the current
																			// boxes associations
																			currentAssociationsList.push(associatedBoxKey);

																			// We've visited the association box type
																			localAssociationsBoxTypesList.push(associatedBoxTypeKey);

																			// console.log(`\tadding association for ${associatedBoxKey}`)
																			// console.log('\t\tsetting related associations recursive start')

																			// Build the list of association box types we've already visited
																			const associationsBoxTypesList = [
																				...currentAssociationsBoxTypesList,
																				...localAssociationsBoxTypesList,
																			];

																			// Follow the associations of the box
																			setRelatedAssociations(
																				currentAssociationsList,
																				associationsBoxTypesList,
																				associatedBoxKey,
																				boxKey,
																				flattenedBoxMap,
																				boxTypeMap,
																				boxTypeVisibilityMap,
																				boxTypeToBoxKeysMap
																			);

																			// console.log('\t\tsetting related associations recursive finish')
																		}
																	} else {
																		// console.log(`\talready visited parent ${parentBoxKey} for ${associatedBoxKey}`)
																	}
																} else {
																	// console.log(`\talready visited type ${associatedBoxTypeKey} for ${associatedBoxKey}`)
																}
															} else {
																// console.log(`\ttype ${associatedBoxTypeKey} not permitted for ${associatedBoxKey}`)
															}
														}
													}
												});

												// Record the local visited association box types
												currentAssociationsBoxTypesList.concat(
													localAssociationsBoxTypesList
												);
											}
										}
									}
								}
							);

							Object.keys(attributeTypes).forEach(
								(attributeTypeKey) => {
									// Get the attribute type
									const boxAttributeType = attributeTypes[attributeTypeKey];
									if (boxAttributeType) {
										// Are we dealing with an associations attribute?
										if (boxAttributeType.valueType === valueTypeLib.ValueTypeKey.Associations) {
											// Is the attribute type visible?
											const attributeTypeVisibility = attributeTypeVisibilityMap
												? attributeTypeVisibilityMap[
														attributeTypeKey
												]
												: false;

											if (attributeTypeVisibility) {
												// If we don't have permitted associations box types,
												// don't check for them
												const ignorePermittedAssociationBoxTypes = !boxAttributeType.permittedAssociationBoxTypes;

												// Get the permitted box types of the association
												// (if we're not ignoring them)
												const permittedAssociationBoxTypesList = !ignorePermittedAssociationBoxTypes
													? boxAttributeType.permittedAssociationBoxTypes.split(
															","
													)
													: [];

												// Get the associations
												const associationsList = getAttributeTypeAssociation(box.name,
													boxKey,
													boxTypeKey,
													attributeTypeKey,
													boxAttributes,
													attributeTypes,
													flattenedBoxMap,
													boxTypeMap,
													attributeTypeVisibilityMap,
													boxTypeVisibilityMap,
													boxTypeToBoxKeysMap,
													false);

												// The local visited association box types for these associations
												// Initially we've only visited the current box type
												const localAssociationsBoxTypesList: string[] = [
													boxTypeKey,
												];

												// Set the related associations
												associationsList.forEach((associatedBoxKey) => {
													// Get the association box
													const associatedBox = flattenedBoxMap[associatedBoxKey];
													if (associatedBox) {
														// Get the associated box type key
														const associatedBoxTypeKey =
															associatedBox.boxType;
														if (associatedBoxTypeKey) {
															// console.log('')
															// console.log(`\tassociatedBoxTypeKey=${associatedBoxTypeKey}`)

															// console.log('\tpermittedAssociationBoxTypesList')
															// console.log(permittedAssociationBoxTypesList)

															// Is the associated box type permitted, or are
															// we ignoring permitted associated box types?
															const foundAssociatedBoxTypeIndex = permittedAssociationBoxTypesList
																.findIndex((key) => key === associatedBoxTypeKey);
															if (foundAssociatedBoxTypeIndex >= 0 || ignorePermittedAssociationBoxTypes) {
																// console.log(`\tfoundAssociatedBoxTypeIndex=${foundAssociatedBoxTypeIndex}`)
																// console.log(`\tignorePermittedAssociationBoxTypes=${ignorePermittedAssociationBoxTypes}`)

																// Have we not already visited that box type?
																const foundCurrentAssociationsBoxTypesListIndex = currentAssociationsBoxTypesList
																	.findIndex((key) => key === associatedBoxTypeKey);
																if (foundCurrentAssociationsBoxTypesListIndex < 0) {
																	// console.log('\tcurrentAssociationsBoxTypesList')
																	// console.log(currentAssociationsBoxTypesList)

																	// Ignore our parent so we don't infinitely recurse
																	if (associatedBoxKey !== parentBoxKey) {
																		// Is the box key not already in the current associations?
																		const foundCurrentAssociationIndex = currentAssociationsList
																			.findIndex((key) => key === associatedBoxKey);
																		if (foundCurrentAssociationIndex < 0) {
																			// console.log('\tcurrentAssociationsList')
																			// console.log(currentAssociationsList)

																			// Add the association the the list of the current
																			// boxes associations
																			currentAssociationsList.push(associatedBoxKey);

																			// We've visited the association box type
																			localAssociationsBoxTypesList.push(associatedBoxTypeKey);

																			// console.log(`\tadding association for ${associatedBoxKey}`)
																			// console.log('\t\tsetting related associations recursive start')

																			// Build the list of association box types we've already visited
																			const associationsBoxTypesList = [
																				...currentAssociationsBoxTypesList,
																				...localAssociationsBoxTypesList,
																			];

																			// Follow the associations of the box
																			setRelatedAssociations(
																				currentAssociationsList,
																				associationsBoxTypesList,
																				associatedBoxKey,
																				boxKey,
																				flattenedBoxMap,
																				boxTypeMap,
																				boxTypeVisibilityMap,
																				boxTypeToBoxKeysMap
																			);

																			// console.log('\t\tsetting related associations recursive finish')
																		}
																	} else {
																		// console.log(`\talready visited parent ${parentBoxKey} for ${associatedBoxKey}`)
																	}
																} else {
																	// console.log(`\talready visited type ${associatedBoxTypeKey} for ${associatedBoxKey}`)
																}
															} else {
																// console.log(`\ttype ${associatedBoxTypeKey} not permitted for ${associatedBoxKey}`)
															}
														}
													}
												});

												// Record the local visited association box types
												currentAssociationsBoxTypesList.concat(
													localAssociationsBoxTypesList
												);
											}
										}
									}
								});									
						}
					}
				}
			}
		}
	}
};

const getBoxAssociationsListForAssociationList = (
	boxKey: string,
	boxTypeKey: string,
	attributeTypeKey: string,
	associationsList: string[],
	flattenedBoxMap: BoxMap,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap,
	boxTypeToBoxKeysMap: Record<string, string[]>,
	shouldGetRelatedAssociations: boolean
): string[] => {
	// console.log(`\t${boxKey} association attribute ${attributeTypeKey} has associations ${associations}`)

	// The updated associations list
	let updatedAssociationsList = JSON.parse(JSON.stringify(associationsList)) as string[];

	const boxType = boxTypeMap[boxTypeKey];
	if (boxType) {
		const boxAttributeType = boxType.attributeTypes[attributeTypeKey];
		if (boxAttributeType) {
				// If we don't have permitted associations box types,
			// don't check for them
			const ignorePermittedAssociationBoxTypes = !boxAttributeType.permittedAssociationBoxTypes;

			// Get the permitted box types of the association
			// (if we're not ignoring them)
			const permittedAssociationBoxTypesList = !ignorePermittedAssociationBoxTypes
				? boxAttributeType.permittedAssociationBoxTypes.split(
						","
					)
				: [];

			if (permittedAssociationBoxTypesList.length > 0) {
				updatedAssociationsList = updatedAssociationsList
					.filter((boxKey: string) => {
						const associationBox = flattenedBoxMap[boxKey];
						if (!associationBox) {
							return false;
						}

						const associationBoxType = associationBox.boxType;

						return permittedAssociationBoxTypesList.indexOf(associationBoxType) >= 0
					});
			}
		}
	}

	if (shouldGetRelatedAssociations) {
		// The current associations box types list
		// We include the box type of the current box as we
		// don't want to visit it again
		const currentAssociationsBoxTypesList: string[] = [
			boxTypeKey,
		];

		// Set the related associations
		updatedAssociationsList.forEach(
			(associatedBoxKey) => {
				setRelatedAssociations(
					updatedAssociationsList,
					currentAssociationsBoxTypesList,
					associatedBoxKey,
					boxKey,
					flattenedBoxMap,
					boxTypeMap,
					boxTypeVisibilityMap,
					boxTypeToBoxKeysMap
				);
			}
		);
	}

	return updatedAssociationsList;
}

const getBoxAssociationsListForIds = (
	boxKey: string,
	boxTypeKey: string,
	attributeTypeKey: string,
	boxAttributes: attributeLib.AttributeMap,
	flattenedBoxMap: BoxMap,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap,
	boxTypeToBoxKeysMap: Record<string, string[]>,
	shouldGetRelatedAssociations: boolean
): string[] => {
	// Get the associations
	const associations = String(boxAttributes[attributeTypeKey]);

	// Convert the associations to an array of strings that
	// represent the associations of this box
	const associationsList = associations
		? associations.split(",")
		: [];

	return getBoxAssociationsListForAssociationList(boxKey,
		boxTypeKey,
		attributeTypeKey,
		associationsList,
		flattenedBoxMap,
		boxTypeMap,
		boxTypeVisibilityMap,
		boxTypeToBoxKeysMap,
		shouldGetRelatedAssociations);
}

const getBoxAssociationsListForNames = (
	boxKey: string,
	boxTypeKey: string,
	attributeTypeKey: string,
	boxAttributeType: attributeTypeLib.AttributeType,
	boxAttributes: attributeLib.AttributeMap,
	flattenedBoxMap: BoxMap,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap,
	boxTypeToBoxKeysMap: Record<string, string[]>,
	shouldGetRelatedAssociations: boolean
): string[] => {
	// If we don't have permitted associations box types, don't check for them
	const ignorePermittedAssociationBoxTypes = !boxAttributeType.permittedAssociationBoxTypes;

	// Get the permitted box types of the association
	// (if we're not ignoring them)
	const permittedAssociationBoxTypesList = !ignorePermittedAssociationBoxTypes
		? boxAttributeType.permittedAssociationBoxTypes.split(",")
		: Object.keys(boxTypeMap);

	// Get the associations
	const associations = String(boxAttributes[attributeTypeKey]);

	// Convert the associations to an array of box names to check
	const boxNames = associations
		? associations.split(",").map((boxName: string) => boxName.trim())
		: [];

	if (boxNames.length <= 0) {
		return [];
	}

	const boxNamesMap = new Map<string, boolean>();
	boxNames.forEach((n: string) => boxNamesMap.set(n, true));

	// Search the boxes in the illustration, building up associations
	const associationsMap = new Map<string, boolean>();

	for (let i=0; i < permittedAssociationBoxTypesList.length; i += 1) {
		const permittedBoxType = permittedAssociationBoxTypesList[i];
		const boxKeys = boxTypeToBoxKeysMap[permittedBoxType];
		if (!boxKeys) {
			console.log(`No keys for box type ${permittedBoxType}`);
			continue;
		}

		for (let j=0; j < boxKeys.length; j += 1) {
			const searchBoxKey = boxKeys[j];

			// Skip the box the association orginated from
			if (searchBoxKey === boxKey) {
				continue;
			}

			const searchBox = flattenedBoxMap[searchBoxKey];
			if (!searchBox) {
				continue;
			}

			const searchBoxTypeKey = searchBox.boxType;
			if (!searchBoxTypeKey) {
				continue;
			}

			// Get whether the box type is visible
			const boxTypeVisibility = boxTypeVisibilityMap[searchBoxTypeKey];
			const isBoxTypeVisible = (boxTypeVisibility)
				? boxTypeVisibility.isVisible
				: false;

			// Only set the associations if the box is visible
			if (!isBoxTypeVisible) {
				continue;
			}

			// Does the box we're looking at have a name that's on our list?
			if (boxNamesMap.has(searchBox.name)) {
				// Associate it with our box
				associationsMap.set(searchBoxKey, true);
			}
		}
	}

	const associationsList = Array.from(associationsMap.keys());

	return getBoxAssociationsListForAssociationList(boxKey,
		boxTypeKey,
		attributeTypeKey,
		associationsList,
		flattenedBoxMap,
		boxTypeMap,
		boxTypeVisibilityMap,
		boxTypeToBoxKeysMap,
		shouldGetRelatedAssociations);
}

const getBoxAssociationsListForAttributeValues = (boxKey: string,
	boxTypeKey: string,
	attributeTypeKey: string,
	boxAttributeType: attributeTypeLib.AttributeType,
	associationsAttributeName: string,
	boxAttributes: attributeLib.AttributeMap,
	flattenedBoxMap: BoxMap,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap,
	boxTypeToBoxKeysMap: Record<string, string[]>,
	shouldGetRelatedAssociations: boolean): string[] => {
	// If we don't have permitted associations box types, don't check for them
	const ignorePermittedAssociationBoxTypes = !boxAttributeType.permittedAssociationBoxTypes;

	// Get the permitted box types of the association
	// (if we're not ignoring them)
	const permittedAssociationBoxTypesList = !ignorePermittedAssociationBoxTypes
		? boxAttributeType.permittedAssociationBoxTypes.split(",")
		: Object.keys(boxTypeMap);

	// Get the associations
	const associations = String(boxAttributes[attributeTypeKey]);

	// Convert the associations to an array of box names to check
	const boxAttributeValues = associations
		? associations.split(",").map((boxAttributeValue: string) => boxAttributeValue.trim())
		: [associations];

	if (boxAttributeValues.length <= 0) {
		return [];
	}

	const boxAttributeValuesMap = new Map<string, boolean>();
	boxAttributeValues.forEach((v: string) => boxAttributeValuesMap.set(v, true));

	// Search the boxes in the illustration, building up associations
	const associationsMap = new Map<string, boolean>();

	// "6b5dd5f7-ef92-49a0-8b10-4294b7038e6a"

	for (let i=0; i < permittedAssociationBoxTypesList.length; i += 1) {
		const permittedBoxType = permittedAssociationBoxTypesList[i];
		const boxKeys = boxTypeToBoxKeysMap[permittedBoxType];
		if (!boxKeys) {
			console.log(`No keys for box type ${permittedBoxType}`);
			continue;
		}

		for (let j=0; j < boxKeys.length; j += 1) {
			const searchBoxKey = boxKeys[j];

			// Skip the box the association orginated from
			if (searchBoxKey === boxKey) {
				continue;
			}

			const searchBox = flattenedBoxMap[searchBoxKey];
			if (!searchBox) {
				continue;
			}

			const searchBoxTypeKey = searchBox.boxType;
			if (!searchBoxTypeKey) {
				continue;
			}

			// Get whether the box type is visible
			const boxTypeVisibility = boxTypeVisibilityMap[searchBoxTypeKey];
			const isBoxTypeVisible = (boxTypeVisibility)
				? boxTypeVisibility.isVisible
				: false;

			// Only set the associations if the box is visible
			if (!isBoxTypeVisible) {
				continue;
			}

			// Get the box type
			const searchBoxType = boxTypeMap[searchBoxTypeKey];
			if (!searchBoxType) {
				continue;
			}

			// // Get the box type attribute types
			const searchBoxAttributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(searchBoxTypeKey);	
			if (!searchBoxAttributeTypes) {
				continue;
			}

			const searchBoxAttributes = searchBox.attributes;
			if (!searchBoxAttributes) {
				continue;
			}

			const searchBoxAttributeTypeKey = attributeTypeLib.findAttributeTypeKeyForName(searchBoxTypeKey,
				searchBoxAttributeTypes,
				associationsAttributeName);
			if (searchBoxAttributeTypeKey === '') {
				continue;
			}

			const searchBoxAttributeType = searchBoxAttributeTypes[searchBoxAttributeTypeKey];
			if (!searchBoxAttributeType) {
				continue;
			}

			let searchBoxAttributeValue = searchBoxAttributes[searchBoxAttributeTypeKey];
			if (!searchBoxAttributeValue) {
				continue;
			}
			searchBoxAttributeValue = String(searchBoxAttributeValue)

			// Does the attribute of the box we're looking at have a value
			// that's on our list?
			if (boxAttributeValuesMap.has(searchBoxAttributeValue)) {
				// Associate it with our box
				associationsMap.set(searchBoxKey, true);
			}
		}

		// We've performed another batch of lookups
		const boxKeyCount = boxKeys.length;

		private_lookups += boxKeyCount;

		if (!private_lookupBreakdownByBox[boxKey]) {
			private_lookupBreakdownByBox[boxKey] = 0;
		}
		private_lookupBreakdownByBox[boxKey] += boxKeyCount;

		if (!private_lookupBreakdownByBoxType[boxTypeKey]) {
			private_lookupBreakdownByBoxType[boxTypeKey] = 0;
		}
		private_lookupBreakdownByBoxType[boxTypeKey] += boxKeyCount;

		if (!private_lookupBreakdownByAttributeType[attributeTypeKey]) {
			private_lookupBreakdownByAttributeType[attributeTypeKey] = 0;
		}
		private_lookupBreakdownByAttributeType[attributeTypeKey] += boxKeyCount;
	}

	const associationsList = Array.from(associationsMap.keys());

	const boxAssociationList = getBoxAssociationsListForAssociationList(boxKey,
		boxTypeKey,
		attributeTypeKey,
		associationsList,
		flattenedBoxMap,
		boxTypeMap,
		boxTypeVisibilityMap,
		boxTypeToBoxKeysMap,
		shouldGetRelatedAssociations);

	return boxAssociationList;
}

const getBoxAssociationsListForMatchingValue = (boxKey: string,
	boxTypeKey: string,
	attributeTypeKey: string,
	searchAttributeName: string | undefined,
	matchingValue: attributeLib.AttributeValue,
	boxAttributeType: attributeTypeLib.AttributeType,
	flattenedBoxMap: BoxMap,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap,
	boxTypeToBoxKeysMap: Record<string, string[]>,
	shouldGetRelatedAssociations: boolean): string[] => {
	// console.log(`getBoxAssociationsListForMatchingValue - ${boxKey} / ${flattenedBoxMap[boxKey].name}`)

	// If we don't have permitted associations box types, don't check for them
	const ignorePermittedAssociationBoxTypes = !boxAttributeType.permittedAssociationBoxTypes;

	// Get the permitted box types of the association
	// (if we're not ignoring them)
	const permittedAssociationBoxTypesList = !ignorePermittedAssociationBoxTypes
		? boxAttributeType.permittedAssociationBoxTypes.split(",")
		: Object.keys(boxTypeMap);

	// Search the boxes in the illustration, building up associations
	const associationsMap = new Map<string, boolean>();

	for (let i=0; i < permittedAssociationBoxTypesList.length; i += 1) {
		const permittedBoxType = permittedAssociationBoxTypesList[i];
		const boxKeys = boxTypeToBoxKeysMap[permittedBoxType];
		if (!boxKeys) {
			console.log(`No keys for box type ${permittedBoxType}`);
			continue;
		}

		for (let j=0; j < boxKeys.length; j += 1) {
			const searchBoxKey = boxKeys[j];

			// Skip the box the association orginated from
			if (searchBoxKey === boxKey) {
				continue;
			}

			const searchBox = flattenedBoxMap[searchBoxKey];
			if (!searchBox) {
				continue;
			}

			const searchBoxTypeKey = searchBox.boxType;
			if (!searchBoxTypeKey) {
				continue;
			}

			// Get whether the box type is visible
			const boxTypeVisibility = boxTypeVisibilityMap[searchBoxTypeKey];
			const isBoxTypeVisible = (boxTypeVisibility)
				? boxTypeVisibility.isVisible
				: false;

			// Only set the associations if the box is visible
			if (!isBoxTypeVisible) {
				continue;
			}

			// Get the box type
			const searchBoxType = boxTypeMap[searchBoxTypeKey];
			if (!searchBoxType) {
				continue;
			}

			// // Get the box type attribute types
			const attributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(searchBoxTypeKey);	
			if (!attributeTypes) {
				continue;
			}

			const searchBoxAttributes = searchBox.attributes;
			if (!searchBoxAttributes) {
				continue;
			}

			const searchAttributeTypeKey = (searchAttributeName && searchAttributeName !== '')
				? attributeTypeLib.findAttributeTypeKeyForName(searchBoxTypeKey, attributeTypes, searchAttributeName)
				: '';

			const searchBoxAttributeKeys = (searchAttributeTypeKey !== '')
				? [searchAttributeTypeKey]
				: Object.keys(searchBoxAttributes)

			for (let k=0; k < searchBoxAttributeKeys.length; k += 1) {
				const searchBoxAttributeTypeKey = searchBoxAttributeKeys[k];

				const searchBoxAttributeType = attributeTypes[searchBoxAttributeTypeKey];
				if (!searchBoxAttributeType) {
					continue;
				}

				let searchBoxAttributeValue = searchBoxAttributes[searchBoxAttributeTypeKey];
				if (!searchBoxAttributeValue) {
					continue;
				}
				searchBoxAttributeValue = String(searchBoxAttributeValue)

				// console.log(`checking attribute ${searchBoxKey} / ${flattenedBoxMap[searchBoxKey].name} / ${searchBoxAttributeTypeKey}`);
				// console.log(`\tattribute value=${searchBoxAttributeValue}`);
				// console.log(searchBoxAttributeValue);	

				// Convert the associations to an array of strings
				const searchBoxAssociationValuesList = searchBoxAttributeValue
					? searchBoxAttributeValue.split(",").map((boxAssociationValue: string) => boxAssociationValue.trim())
					: [];

				// console.log('searchBoxAssociationValuesList', searchBoxAssociationValuesList);
				// console.log('matchingValue', matchingValue)

				// Does the association have a value that matches the value?
				// If so, associated it with our box.
				if (searchBoxAssociationValuesList.indexOf(String(matchingValue)) >= 0) {
					associationsMap.set(searchBoxKey, true);
				}
			}
		}
	}

	const associationsList = Array.from(associationsMap.keys());

	return getBoxAssociationsListForAssociationList(boxKey,
		boxTypeKey,
		attributeTypeKey,
		associationsList,
		flattenedBoxMap,
		boxTypeMap,
		boxTypeVisibilityMap,
		boxTypeToBoxKeysMap,
		shouldGetRelatedAssociations)
}

export const getAttributeTypeAssociation = (boxName: string,
	boxKey: string,
	boxTypeKey: string,
	attributeTypeKey: string,
	boxAttributes: attributeLib.AttributeMap,
	attributeTypes: attributeTypeLib.AttributeTypeMap,
	flattenedBoxMap: BoxMap,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	attributeTypeVisibilityMap: attributeTypeLib.AttributeTypeVisibilityMap,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap,
	boxTypeToBoxKeysMap: Record<string, string[]>,
	shouldGetRelatedAssociations: boolean): string[] => {
	const cachedAssociations = getCachedAssociation(boxKey, attributeTypeKey, shouldGetRelatedAssociations);
	if (cachedAssociations) {
		return cachedAssociations;
	}

	let attributeTypesAssociationsList: string[] = [];

	// Get the attribute type
	const boxAttributeType = attributeTypes[attributeTypeKey];
	if (boxAttributeType) {
		// Are we dealing with an associations attribute?
		if (boxAttributeType.valueType === valueTypeLib.ValueTypeKey.Associations) {
			// Is the attribute type visible?
			const attributeTypeVisibility = attributeTypeVisibilityMap
				? attributeTypeVisibilityMap[attributeTypeKey]
				: false;
			if (attributeTypeVisibility) {
				const hasAssociationsType = Object.prototype.hasOwnProperty.call(boxAttributeType, 'associationsType') &&
					!!boxAttributeType['associationsType']

				const associationsType = hasAssociationsType
					? boxAttributeType['associationsType']
					: attributeTypeLib.AssociationType.Uuids;

				let shouldCacheAssociation = false;

				const { associationsAttributeName } = boxAttributeType;

				if (associationsType === attributeTypeLib.AssociationType.UuidReverseLookup) {
					attributeTypesAssociationsList = getBoxAssociationsListForMatchingValue(boxKey,
						boxTypeKey,
						attributeTypeKey,
						associationsAttributeName,
						boxKey,
						boxAttributeType,
						flattenedBoxMap,
						boxTypeMap,
						boxTypeVisibilityMap,
						boxTypeToBoxKeysMap,
						shouldGetRelatedAssociations);
					shouldCacheAssociation = true;
				} else if (associationsType === attributeTypeLib.AssociationType.NameReverseLookup) {
					attributeTypesAssociationsList = getBoxAssociationsListForMatchingValue(boxKey,
						boxTypeKey,
						attributeTypeKey,
						associationsAttributeName,
						boxName,
						boxAttributeType,
						flattenedBoxMap,
						boxTypeMap,
						boxTypeVisibilityMap,
						boxTypeToBoxKeysMap,
						shouldGetRelatedAssociations);
					shouldCacheAssociation = true;
				} else if (associationsType === attributeTypeLib.AssociationType.AttributeValueReverseLookup) {			
					const searchAttributeTypeKey = (associationsAttributeName && associationsAttributeName !== '')
						? attributeTypeLib.findAttributeTypeKeyForName(boxTypeKey, attributeTypes, associationsAttributeName)
						: '';
					if (searchAttributeTypeKey !== '') {
						const associationsAttributeValue = boxAttributes[searchAttributeTypeKey]
						if (associationsAttributeValue) {
							attributeTypesAssociationsList = getBoxAssociationsListForMatchingValue(boxKey,
								boxTypeKey,
								attributeTypeKey,
								associationsAttributeName,
								associationsAttributeValue,
								boxAttributeType,
								flattenedBoxMap,
								boxTypeMap,
								boxTypeVisibilityMap,
								boxTypeToBoxKeysMap,
								shouldGetRelatedAssociations);
							shouldCacheAssociation = true;
						}
					}
				}

				if (shouldCacheAssociation) {
					setCachedAssociation(boxKey, attributeTypeKey, shouldGetRelatedAssociations, attributeTypesAssociationsList);
				}
			}
		}
	}

	return attributeTypesAssociationsList;
}

export const getAttributeAssociation = (boxKey: string,
	boxTypeKey: string,
	attributeTypeKey: string,
	boxAttributes: attributeLib.AttributeMap,
	attributeTypes: attributeTypeLib.AttributeTypeMap,
	flattenedBoxMap: BoxMap,
	boxTypeMap: boxTypeLib.BoxTypeMap,
	attributeTypeVisibilityMap: attributeTypeLib.AttributeTypeVisibilityMap,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap,
	boxTypeToBoxKeysMap: Record<string, string[]>,
	shouldGetRelatedAssociations: boolean): string[] => {
	const cachedAssociations = getCachedAssociation(boxKey, attributeTypeKey, shouldGetRelatedAssociations);
	if (cachedAssociations) {
		return cachedAssociations;
	}

	let attributesAssociationsList: string[] = [];

	// Get the attribute type
	const boxAttributeType = attributeTypes[attributeTypeKey];
	if (boxAttributeType) {
		// Are we dealing with an associations attribute?
		if (
			boxAttributeType.valueType === valueTypeLib.ValueTypeKey.Associations
		) {
			// Is the attribute type visible?
			const attributeTypeVisibility = attributeTypeVisibilityMap
				? attributeTypeVisibilityMap[attributeTypeKey]
				: false;
			if (attributeTypeVisibility) {
				const hasAssociationsType = Object.prototype.hasOwnProperty.call(boxAttributeType, 'associationsType') &&
					!!boxAttributeType['associationsType']

				const associationsType = hasAssociationsType && boxAttributeType['associationsType']
					? boxAttributeType['associationsType']
					: attributeTypeLib.AssociationType.Uuids;

				let shouldCacheAssociation = false;

				if (associationsType === attributeTypeLib.AssociationType.Uuids) {
					attributesAssociationsList = getBoxAssociationsListForIds(boxKey,
						boxTypeKey,
						attributeTypeKey,
						boxAttributes,
						flattenedBoxMap,
						boxTypeMap,
						boxTypeVisibilityMap,
						boxTypeToBoxKeysMap,
						shouldGetRelatedAssociations);
					shouldCacheAssociation = true;
				} else if (associationsType === attributeTypeLib.AssociationType.NamesLookup) {
					attributesAssociationsList = getBoxAssociationsListForNames(boxKey,
						boxTypeKey,
						attributeTypeKey,
						boxAttributeType,
						boxAttributes,
						flattenedBoxMap,
						boxTypeMap,
						boxTypeVisibilityMap,
						boxTypeToBoxKeysMap,
						shouldGetRelatedAssociations);
					shouldCacheAssociation = true;
				} else if (associationsType === attributeTypeLib.AssociationType.AttributeValuesLookup) {
					const associationsAttributeName = boxAttributeType.associationsAttributeName;
					if(!!associationsAttributeName) {
						attributesAssociationsList = getBoxAssociationsListForAttributeValues(boxKey,
							boxTypeKey,
							attributeTypeKey,
							boxAttributeType,
							associationsAttributeName,
							boxAttributes,
							flattenedBoxMap,
							boxTypeMap,
							boxTypeVisibilityMap,
							boxTypeToBoxKeysMap,
							shouldGetRelatedAssociations);
						shouldCacheAssociation = true;
					}
				}

				if (shouldCacheAssociation) {
					setCachedAssociation(boxKey, attributeTypeKey, shouldGetRelatedAssociations, attributesAssociationsList);
				}
			}
		}
	}

	return attributesAssociationsList;
}

const getBoxesByType = (flattenedBoxMap: BoxMap): Record<string, string[]> => {
	const boxTypeToBoxKeysMap: Record<string, string[]> = {};

	const boxKeys = Object.keys(flattenedBoxMap)
	for (let i=0; i < boxKeys.length; i += 1) {
		const boxKey = boxKeys[i];

		const box = flattenedBoxMap[boxKey];
		if (!box) {
			continue;
		}

		const boxTypeKey = box.boxType;
		if (!boxTypeKey) {
			continue;
		}

		if (!boxTypeToBoxKeysMap[boxTypeKey]) {
			boxTypeToBoxKeysMap[boxTypeKey] = [];
		}

		boxTypeToBoxKeysMap[boxTypeKey].push(boxKey);
	}

	return boxTypeToBoxKeysMap;
}

export const getVisibleBoxTypes = (boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap): Record<string, boolean> => {
	const visibleBoxTypes: Record<string, boolean> = {}
	Object
		.keys(boxTypeVisibilityMap)
		.forEach((boxTypeKey) => {
			const boxTypeVisibility = boxTypeVisibilityMap[boxTypeKey];
			if (boxTypeVisibility && boxTypeVisibility.areBoxesVisible) {
				visibleBoxTypes[boxTypeKey] = true;
			}
		});

	return visibleBoxTypes;
}

export const getVisibleBoxes = (flattenedBoxMap: BoxMap, visibleBoxTypes: Record<string, boolean>): BoxMap => {
	const visibleBoxes: BoxMap = {};

	// Loop thru all the boxes
	const boxKeys = Object.keys(flattenedBoxMap)
	for (let i=0; i < boxKeys.length; i += 1) {
		const boxKey = boxKeys[i];
		// console.log(`setting box associations for - ${boxKey} / ${flattenedBoxMap[boxKey].name}`)

		// Get the box
		const box = flattenedBoxMap[boxKey];
		if (box) {
			// Get the box type
			// TODO: Handle inheritance
			const boxTypeKey = box.boxType;

			if (visibleBoxTypes[boxTypeKey]) {
				visibleBoxes[boxKey] = box;
			}
		}
	}

	return visibleBoxes;
}

export const getAttributeTypeVisibilityMap = (boxKey: string,
	boxVisibilityMap: BoxVisibilityMap | undefined,
	boxTypes: boxTypeLib.BoxTypeMap | undefined,
	boxTypeKey: string,
	boxTypeVisibilityMap: BoxTypeVisibilityMap | undefined): attributeTypeLib.AttributeTypeVisibilityMap | undefined => {
	// Get the box type visibility
	const boxTypeVisibility = boxTypeVisibilityMap
		? boxTypeVisibilityMap[boxTypeKey]
		: undefined;

	// If the box types are not visible, don't generate an attribute type
	// visibility map
	if (boxTypeVisibility && !boxTypeVisibility.areBoxesVisible) {
		return undefined;
	}

	// Get the box visibility
	const boxVisibility = boxVisibilityMap
		? boxVisibilityMap[boxKey]
		: undefined;

	// If the boxes are not visible, don't generate an attribute type visibility
	// map
	if (boxVisibility && !boxVisibility.isVisible) {
		return undefined;
	}

	// Get the attribute type visibilities
	const attributeTypeVisibilityMap = boxTypeLib.getAttributeTypeVisibilityMapForBoxTypeRecursive(
		boxTypes,
		boxTypeKey,
		boxTypeVisibilityMap
	);

	return attributeTypeVisibilityMap
}

export const setBoxAssociations = (
	boxAssociationsMap: BoxAssociationsMap | undefined,
	flattenedBoxMap: BoxMap | undefined,
	boxParentMap: BoxParentMap | undefined,
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined
) => {
	// console.log('setBoxAssociations')
	private_lookups = 0;
	private_lookupBreakdownByBox = {};
	private_lookupBreakdownByBoxType = {};
	private_lookupBreakdownByAttributeType = {};

	// Turn on association caching
	clearAssociationCache();
	setAssociationCacheEnabled(true);

	// Turn on attribute type visibility caching
	boxTypeLib.clearAttributeTypeVisibilityCache();
	boxTypeLib.setAttributeTypeVisibilityCacheEnabled(true);

	// Turn on mixin box type caching
	boxTypeLib.clearMixinBoxTypeCache();
	boxTypeLib.setMixinBoxTypeCacheEnabled(true);

	// Check our inputs are valid
	if (boxAssociationsMap && flattenedBoxMap && boxParentMap && boxTypeMap && boxTypeVisibilityMap) {
		// List the boxes by type
		const boxTypeToBoxKeysMap = getBoxesByType(flattenedBoxMap);

		// Loop thru all the boxes
		const boxKeys = Object.keys(flattenedBoxMap)
		for (let i=0; i < boxKeys.length; i += 1) {
			const boxKey = boxKeys[i];
			// console.log(`setting box associations for - ${boxKey} / ${flattenedBoxMap[boxKey].name}`)

			// Get the box
			const box = flattenedBoxMap[boxKey];
			if (box) {
				// Get the box type
				// TODO: Handle inheritance
				const boxTypeKey = box.boxType;
				if (boxTypeKey) {
					// Get the box type
					const boxType = boxTypeMap[boxTypeKey];
					if (boxType) {
						// Get the box type attribute types
						const attributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(boxTypeKey);
						if (attributeTypes) {
							// Get the box type visibility
							const boxTypeVisibility = boxTypeVisibilityMap
								? boxTypeVisibilityMap[boxTypeKey]
								: undefined;

							if (!boxTypeVisibility || !boxTypeVisibility.areBoxesVisible) {
								continue;
							}

							// Get the attribute type visibilities
							const attributeTypeVisibilityMap = boxTypeLib.getAttributeTypeVisibilityMapForBoxTypeRecursive(
								boxTypeMap,
								boxTypeKey,
								boxTypeVisibilityMap
							);

							// Handle the associations that require attribute values
							const boxAttributes = box.attributes;
							if (boxAttributes) {
								// Get all attributes that are associations
								const boxAttributesKeys = Object.keys(boxAttributes);
								for (let j=0; j < boxAttributesKeys.length; j += 1) {
									const attributeTypeKey = boxAttributesKeys[j];
						
									const updatedAssociationsList = getAttributeAssociation(boxKey,
										boxTypeKey,
										attributeTypeKey,
										boxAttributes,
										attributeTypes,
										flattenedBoxMap,
										boxTypeMap,
										attributeTypeVisibilityMap,
										boxTypeVisibilityMap,
										boxTypeToBoxKeysMap,
										true);

									// Get the box associations
									const boxAssociations = boxAssociationsMap[boxKey]
										? boxAssociationsMap[boxKey]
										: [];

									// Update the box associations
									boxAssociationsMap[boxKey] = [
										...boxAssociations,
										...updatedAssociationsList,
									];
								};

								// Handle the associations that only need the attribute type
								const attributeTypesKeys = Object.keys(attributeTypes);
								for (let j=0; j < attributeTypesKeys.length; j += 1) {
									const attributeTypeKey = attributeTypesKeys[j];
						
									const updatedAssociationsList = getAttributeTypeAssociation(box.name,
										boxKey,
										boxTypeKey,
										attributeTypeKey,
										boxAttributes,
										attributeTypes,
										flattenedBoxMap,
										boxTypeMap,
										attributeTypeVisibilityMap,
										boxTypeVisibilityMap,
										boxTypeToBoxKeysMap,
										true);

									// Get the box associations
									const boxAssociations = boxAssociationsMap[boxKey]
										? boxAssociationsMap[boxKey]
										: [];

									// Update the box associations
									boxAssociationsMap[boxKey] = [
										...boxAssociations,
										...updatedAssociationsList,
									];
								};
							}
						}
					}
				}
			}
		}
	}

	// Turn off caching
	boxTypeLib.setMixinBoxTypeCacheEnabled(false);
	boxTypeLib.setAttributeTypeVisibilityCacheEnabled(false);
	setAssociationCacheEnabled(false);

	// console.log(`Performed ${private_lookups} lookups for Illustration`)
	// console.log(private_lookupBreakdownByBox);
	// console.log(private_lookupBreakdownByBoxType);
	// console.log(private_lookupBreakdownByAttributeType);
};

const areBoxAssociationsVisible = (
	boxKey: string,
	boxAssociationsMap: BoxAssociationsMap | undefined,
	boxTypeKey: string,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined,
	currentlyHighlightedBoxKey: string,
	canHighlightAssociations: boolean
) => {
	// Whether the association is visible
	let isVisible = false;

	// Do we have a currently highlighted box key, and can we highlight associations?
	if (currentlyHighlightedBoxKey && canHighlightAssociations) {
		// console.log(`\t\tisAssociationsAttributeVisible=${boxKey}, ${currentlyHighlightedBoxKey}`);
		// console.log(associations);

		// Get the box associations
		const currentlyHighlightedBoxAssociationsList =
			boxAssociationsMap && boxAssociationsMap[currentlyHighlightedBoxKey]
				? boxAssociationsMap[currentlyHighlightedBoxKey]
				: [];

		// console.log('currentlyHighlightedBoxAssociationsList')
		// console.log(currentlyHighlightedBoxAssociationsList);

		// Get whether the box type is visible
		const boxTypeVisibility = (boxTypeVisibilityMap)
			? boxTypeVisibilityMap[boxTypeKey]
			: undefined;
		const isBoxTypeVisible = (boxTypeVisibility)
			? boxTypeVisibility.isVisible
			: false;

		// console.log(`boxKey=${boxKey}, boxType=${boxType}, boxTypeVisibilityMap[boxType]=${boxTypeVisibilityMap[boxType]}, isBoxTypeVisible=[${isBoxTypeVisible}]`)

		// Is the box in the associations, or are we the currently highlighted box?
		const foundAssociationIndex = currentlyHighlightedBoxAssociationsList.findIndex(
			(associationBoxKey) => associationBoxKey === boxKey
		);
		if (
			(foundAssociationIndex !== -1 && isBoxTypeVisible) ||
			boxKey === currentlyHighlightedBoxKey
		) {
			// console.log(`\t\t\t${boxKey} - association visible (${associationsList[foundAssociationIndex]})!`);
			// console.log(associationsList)

			// The association attribute is visible
			isVisible = true;
		}
	}

	return isVisible;
};

export const deleteBoxAssociations = (
	box: Box,
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined
) => {
	// If our inputs parameters are invalid, do nothing
	if (!boxTypeMap) {
		return;
	}

	// Get the box type key
	const boxTypeKey = box.boxType;
	if (boxTypeKey) {
		// Get the box type
		const boxType = boxTypeMap[boxTypeKey];
		if (boxType) {
			// The list of box attribute types that are associations
			const boxAttributeTypeAssociationsList: string[] = [];

			// Get the box type attribute types
			const boxAttributeTypes = boxType.attributeTypes;
			if (boxAttributeTypes) {
				// Loop thru the box attribute types
				const boxAttributeTypesKeys = Object.keys(boxAttributeTypes);
				for (let i=0; i < boxAttributeTypesKeys.length; i += 1) {
					const boxAttributeTypeKey = boxAttributeTypesKeys[i];

					// Get the box attribute type
					const boxAttributeType =
						boxAttributeTypes[boxAttributeTypeKey];

					// Is it an association?
					if (
						boxAttributeType.valueType ===
						valueTypeLib.ValueTypeKey.Associations
					) {
						// Add it to our list of attributes that are associations
						boxAttributeTypeAssociationsList.push(
							boxAttributeTypeKey
						);
					}
				}
			}

			// Get the box attributes
			const boxAttributes = box.attributes;

			// Loop thru the box associations attributes
			if (boxAttributes) {
				for (let i=0; i < boxAttributeTypeAssociationsList.length; i += 1) {
					const attributeKey = boxAttributeTypeAssociationsList[i];
	
					// console.log(`Deleting attribute ${attributeKey}`);
					// Delete the attribute
					delete boxAttributes[attributeKey];
				}
			}
		}
	}
};

export const deleteAllBoxAssociations = (
	boxMap: BoxMap | undefined,
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined
) => {
	// If our inputs parameters are invalid, do nothing
	if (!boxMap || !boxTypeMap) {
		return;
	}

	// The list of box attribute types that are associations
	const boxAttributeTypeAssociationsList: string[] = [];

	// Get the box types
	const boxTypeMapKeys = Object.keys(boxTypeMap);

	for (let i=0; i < boxTypeMapKeys.length; i += 1) {
		const boxTypeKey = boxTypeMapKeys[i];

		//Get the box type
		const boxType = boxTypeMap[boxTypeKey];
		if (boxType) {
			// Get the box type attribute types
			const boxAttributeTypes = boxType.attributeTypes;
			if (boxAttributeTypes) {
				// Loop thru the box attribute types
				const boxAttributeTypesKeys = Object.keys(boxAttributeTypes);
				for (let j=0; j < boxAttributeTypesKeys.length; j += 1) {
					const boxAttributeTypeKey = boxAttributeTypesKeys[j];
			
					// Get the box attribute type
					const boxAttributeType = boxAttributeTypes[boxAttributeTypeKey];

					// Is it an association?
					if (
						boxAttributeType.valueType ===
						valueTypeLib.ValueTypeKey.Associations
					) {
						// Add it to our list of attributes that are associations
						boxAttributeTypeAssociationsList.push(
							boxAttributeTypeKey
						);
					}
				}
			}
		}
	};

	// Set up the function we'll apply to all boxes
	const deleteAssociations = (boxKey: string, box: Box) => {
		// If the Box matches one of the types in our BoxTypeMap
		if (boxTypeMap && Object.keys(boxTypeMap).indexOf(box.boxType) > -1) {
			// Get the box attributes
			const boxAttributes = box.attributes;

			// Loop thru the box associations attributes
			if (boxAttributes) {
				for (let i=0; i < boxAttributeTypeAssociationsList.length; i += 1) {
					const attributeKey = boxAttributeTypeAssociationsList[i];

					// console.log(`Deleting attribute ${attributeKey}`)
					// Delete the attribute
					delete boxAttributes[attributeKey];
				}
			}
		}
	};

	// Apply the function to all the boxes
	applyFunctionToBoxes(boxMap, deleteAssociations);
};

export const setBoxIsCanvasAttribute = (
	box: Box,
	boxType: boxTypeLib.BoxType
): void => {
	// Ignore any boxes that don't have a "Is Canvas" attribute  on their type
	if (
		!Object.prototype.hasOwnProperty.call(
			boxType.attributeTypes,
			"Is Canvas"
		)
	) {
		// Get the box attributes
		const boxAttributes = box.attributes;

		// Update whether the box is a canvas
		if (boxAttributes) {
			boxAttributes["Is Canvas"] = "False";
		}
	}
};

export const setBoxHasSmartPagesAttribute = (
	box: Box,
	boxType: boxTypeLib.BoxType
): void => {
	// Ignore any boxes that don't have a "Has Lens Pages" attribute  on their type
	if (
		Object.prototype.hasOwnProperty.call(
			boxType.attributeTypes,
			"Has Lens Pages"
		)
	) {
		// Get the box attributes
		const boxAttributes = box.attributes;

		// Update whether the box has Lens Pages
		if (boxAttributes) {
			let hasSmartPages = "False";

			if (box.smartPages) {
				if (Object.keys(box.smartPages).length > 0) {
					hasSmartPages = "True";
				}
			}

			boxAttributes["Has Lens Pages"] = hasSmartPages;
		}
	}
};

export const setDefaultBoxAttributes = (
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined,
	box: Box,
	currentBoxTypeHighestId: number,
	force: boolean = false
): number => {
	let newId = currentBoxTypeHighestId;

	// If our input parameters are invalid, do nothing
	if (!boxTypeMap) {
		return newId;
	}

	// Get the box type
	const boxType = boxTypeMap[box.boxType];
	if (boxType) {
		// For the ID attribute, ignore any container box types
		if (!boxTypeLib.isContainerBoxType(boxType.name)) {
			// Ignore any boxes that don't have an ID attrbute on their type
			if (
				Object.prototype.hasOwnProperty.call(
					boxType.attributeTypes,
					"ID"
				)
			) {
				// Get the box attributes
				const boxAttributes = box.attributes;

				// Update the ID. If we're not forcing an update then only update if it
				// doesn't already exist
				if (boxAttributes) {
					if (
						!Object.prototype.hasOwnProperty.call(
							boxAttributes,
							"ID"
						) ||
						force
					) {
						newId++;
						boxAttributes["ID"] = String(newId);
					}
				}
			}
		}

		// Add the "is canvas" default attribute
		setBoxIsCanvasAttribute(box, boxType);

		// Update whether the box has a Lens Page
		setBoxHasSmartPagesAttribute(box, boxType);
	}

	return newId;
};

export const setDefaultBoxesAttributes = (
	boxMap: BoxMap | undefined,
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined
) => {
	// If our input parameters are invalid, do nothing
	if (!boxMap || !boxTypeMap) {
		return;
	}

	// Count from 1
	let idCounter = 1;

	// Set up the function we'll apply to all boxes
	const setDefaultAttributes = (boxKey: string, box: Box) => {
		idCounter = setDefaultBoxAttributes(boxTypeMap, box, idCounter);
	};

	applyFunctionToBoxes(boxMap, setDefaultAttributes);
};

export const getHighestBoxIDAttributeForTypes = (
	boxMap: BoxMap | undefined,
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined
): boxTypeCounterLib.BoxTypeCounters => {
	// If our input parameters are invalid, do nothing
	if (!boxMap || !boxTypeMap) {
		return {};
	}

	// The highest ID
	let boxTypeCounters = boxTypeCounterLib.createCounters(boxTypeMap);

	// Set up the function we'll apply to all boxes
	const getHighestId = (boxKey: string, box: Box) => {
		const boxTypeKey = box.boxType;

		//Get the box type
		const boxType = boxTypeMap[boxTypeKey];

		// Ignore any container box types
		if (boxType && !boxTypeLib.isContainerBoxType(boxType.name)) {
			// Ignore any boxes that don't have an ID on their type
			if (
				Object.prototype.hasOwnProperty.call(
					boxType.attributeTypes,
					"ID"
				)
			) {
				// Get the box attributes
				const boxAttributes = box.attributes;

				// Loop thru the box associations attributes
				if (boxAttributes) {
					if (
						Object.prototype.hasOwnProperty.call(
							boxAttributes,
							"ID"
						)
					) {
						const boxAttributeID = Number(boxAttributes["ID"]);

						const boxTypeHighestID = boxTypeCounterLib.getCounter(
							boxTypeCounters,
							boxTypeKey
						);

						if (boxAttributeID > boxTypeHighestID) {
							boxTypeCounters[boxTypeKey] = boxAttributeID;
						}
					}
				}
			}
		}
	};

	applyFunctionToBoxes(boxMap, getHighestId);

	return boxTypeCounters;
};

export const setBoxPropertiesFromAttributes = (
	boxProperties: Properties | undefined,
	boxKey: string,
	boxTypeKey: string,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined,
	boxParentTypeKey: string,
	boxParentAttributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	boxParentAttributes: attributeLib.AttributeMap | undefined,
	currentlyHighlightedBoxKey: string,
	currentlyHighlightedBoxTypeKey: string,
	currentlyHighlightedBoxAttributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	currentlyHighlightedBoxAttributes: attributeLib.AttributeMap | undefined,
	canHighlightAssociations: boolean,
	boxAssociationsMap: BoxAssociationsMap | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeTypeVisibilityMap:
		| attributeTypeLib.AttributeTypeVisibilityMap
		| undefined,
	boxStyles: BoxStyleMap | undefined,
	illustrationFlattenedBoxMap: BoxMap | undefined
) => {
	// console.log(`\tsetBoxPropertiesFromAttributes(${boxKey}`);
	// console.log(attributeTypes)
	// console.log(attributeTypeVisibilityMap)

	// If we don't have box properties or attribute types there's nothing to set
	if (
		!boxProperties ||
		!boxAssociationsMap ||
		!attributes ||
		!attributeTypes
	) {
		return;
	}

	// Loop thru the attribute types
	const attributeTypesKeys = Object.keys(attributeTypes);
	for (let i=0; i < attributeTypesKeys.length; i += 1) {
		const attributeTypeKey = attributeTypesKeys[i];

		// Is the attribute type visible?
		const attributeTypeVisibility = attributeTypeVisibilityMap
			? attributeTypeVisibilityMap[attributeTypeKey]
			: false;

		// console.log(`attributeTypeKey=${attributeTypeKey}, attributeTypeVisibility=${attributeTypeVisibility}`)
		// console.log(attributeTypeVisibilityMap)

		if (attributeTypeVisibility) {
			// Get the box attribute type
			const boxAttributeType = attributeTypes[attributeTypeKey];
			if (boxAttributeType) {
				// The attribute value (set to the default value initially)
				let attributeValue = boxAttributeType.defaultValue;

				// Does the box have a value for this attribute type?
				if (
					Object.prototype.hasOwnProperty.call(
						attributes,
						attributeTypeKey
					)
				) {
					// Get the value
					attributeValue = attributes[attributeTypeKey];

					// console.log(`found ${attributeTypeKey} = ${attributeValue}`);
				}

				// console.log(`${boxKey} - ${boxAttributeType.name}, ${boxAttributeType.valueType}, ${attributeTypeKey} = ${attributeValue}`);

				// Whether the attribute is visible (assume it is)
				let isAttributeVisible = true;

				// If we're dealing with an associations attribute, check it's visible
				if (
					boxAttributeType.valueType ===
					valueTypeLib.ValueTypeKey.Associations
				) {
					isAttributeVisible = areBoxAssociationsVisible(
						boxKey,
						boxAssociationsMap,
						boxTypeKey,
						boxTypeVisibilityMap,
						currentlyHighlightedBoxKey,
						canHighlightAssociations
					);
				}

				// Is the attribute visible?
				if (isAttributeVisible) {
					// console.log(`${attributeTypeKey}`)
					// console.log(boxAttributeType.renderFunctions);

					// Get the render functions
					const attributeTypeRenderFunctions =
						boxAttributeType.renderFunctions;
					if (attributeTypeRenderFunctions) {
						// Apply each render function to the box properties
						const attributeTypeRenderFunctionsKeys = Object.keys(attributeTypeRenderFunctions);
						for (let j=0; j < attributeTypeRenderFunctionsKeys.length; j += 1) {
							const renderFunctionKey = attributeTypeRenderFunctionsKeys[j];

							// Get the render function info
							const renderFunctionInfo =
								attributeTypeRenderFunctions[
									renderFunctionKey
								];

							// Get the actual render function
							const actualRenderFunction =
								renderFunctionLib.TYPES[
									renderFunctionInfo.type
								].renderFunction;

							// Call the render function
							const newBoxProperties = actualRenderFunction(
								boxProperties,
								attributes,
								attributeTypes,
								boxAttributeType,
								attributeValue,
								renderFunctionInfo.inputs,
								boxKey,
								boxTypeKey,
								boxParentTypeKey,
								boxParentAttributeTypes,
								boxParentAttributes,
								currentlyHighlightedBoxTypeKey,
								currentlyHighlightedBoxAttributeTypes,
								currentlyHighlightedBoxAttributes,
								illustrationFlattenedBoxMap
							);

							// Have the styles changed?
							const haveStylesChanged =
								JSON.stringify(boxProperties.styles) !==
								JSON.stringify(newBoxProperties.styles);

							// Merge the rendered box properties into the box properties
							Object.assign(boxProperties, newBoxProperties);

							// If the styles have changed, assign box properties based
							// the new styles
							if (haveStylesChanged) {
								setBoxPropertiesFromStyles(
									boxProperties,
									boxProperties.styles,
									boxStyles
								);
							}
						}
					}
				}
			}
		}
	};

	// Apply any styke
};

export const getRenderFunctionResults = (
	boxAttributeType: attributeTypeLib.AttributeType,
	attributeValue: string,
	boxProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	attributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	boxStyles: BoxStyleMap | undefined
) => {
	// Get the render functions
	const attributeTypeRenderFunctions = boxAttributeType.renderFunctions;
	if (attributeTypeRenderFunctions && boxProperties) {
		// Apply each render function to the box properties
		const attributeTypeRenderFunctionsKeys = Object.keys(attributeTypeRenderFunctions);
		for (let i=0; i < attributeTypeRenderFunctionsKeys.length; i += 1) {
			const renderFunctionKey = attributeTypeRenderFunctionsKeys[i];

			// Get the render function info
			const renderFunctionInfo =
				attributeTypeRenderFunctions[renderFunctionKey];

			// Get the actual render function
			const actualRenderFunction =
				renderFunctionLib.TYPES[renderFunctionInfo.type]
					.renderFunction;

			// Call the render function
			const newBoxProperties = actualRenderFunction(
				boxProperties,
				attributes,
				attributeTypes,
				boxAttributeType,
				attributeValue,
				renderFunctionInfo.inputs
			);

			// Have the styles changed?
			const haveStylesChanged =
				JSON.stringify(boxProperties.styles) !==
				JSON.stringify(newBoxProperties.styles);

			// Merge the rendered box properties into the box properties
			Object.assign(boxProperties, newBoxProperties);

			// If the styles have changed, assign box properties based
			// the new styles
			if (haveStylesChanged) {
				setBoxPropertiesFromStyles(
					boxProperties,
					boxProperties.styles,
					boxStyles
				);
			}
		}
	}
};

export const setBoxProperties = (
	boxProperties: Properties | undefined,
	boxTypeKey: string,
	boxTypes: boxTypeLib.BoxTypeMap | undefined,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined,
	boxParentTypeKey: string | undefined,
	defaultProperties: propertyLib.PropertyMap | undefined,
	inheritParentProperties: boolean,
	boxParentProperties: Properties | undefined,
	attributes: attributeLib.AttributeMap | undefined,
	inheritParentAttributes: boolean,
	boxParentAttributes: attributeLib.AttributeMap | undefined,
	boxStyles: BoxStyleMap | undefined,
	boxAttributeTypes: attributeTypeLib.AttributeTypeMap,
	boxParentAttributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	attributeTypeVisibilityMap: attributeTypeLib.AttributeTypeVisibilityMap,
	boxKey: string,
	currentlyHighlightedBoxKey: string,
	currentlyHighlightedBoxTypeKey: string,
	currentlyHighlightedBoxAttributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	currentlyHighlightedBoxAttributes: attributeLib.AttributeMap | undefined,
	currentlyHighlightedDropTargetBoxKey: string,
	canHighlightAssociations: boolean,
	boxAssociationsMap: BoxAssociationsMap | undefined,
	illustrationFlattenedBoxMap: BoxMap | undefined
) => {
	// If we don't have box properties there's nothing to set
	if (!boxProperties) {
		return;
	}

	// console.log(`setBoxProperties=${boxKey}`)
	// console.log(`boxKey=${boxKey}`);
	// console.log(`boxTypeKey=${boxTypeKey}`)
	// console.log(boxTypes);
	// console.log(boxBoxType);
	// console.log(attributes);
	// console.log(boxAttributeTypes);
	// console.log(boxTypeVisibilityMap)

	// Set the box properties from the default properties
	setBoxPropertiesFromBoxPropertyMap(
		boxProperties,
		defaultProperties,
		boxStyles
	);

	// Are we inheriting the properties of the parent box?
	if (inheritParentProperties) {
		// Get the parent box properties
		if (boxParentProperties) {
			// Get the box parent properties as a property map
			const boxParentPropertyMap = getPropertyMapFromProperties(
				boxParentProperties
			);

			// Set the box properties from its parents properties
			setBoxPropertiesFromBoxPropertyMap(
				boxProperties,
				boxParentPropertyMap,
				boxStyles
			);
		}
	}

	// Are we inheriting the attributes of the parent box?
	if (inheritParentAttributes) {
		// Get the parent box attributes
		if (boxParentAttributes) {
			// Set the boxes properties from its parents attributes
			// TODO: Handle inheriting the box type from the parent
			setBoxPropertiesFromAttributes(
				boxProperties,
				boxKey,
				boxTypeKey,
				boxTypeVisibilityMap,
				boxParentTypeKey || '',
				boxParentAttributeTypes,
				boxParentAttributes,
				currentlyHighlightedBoxKey,
				currentlyHighlightedBoxTypeKey,
				currentlyHighlightedBoxAttributeTypes,
				currentlyHighlightedBoxAttributes,
				canHighlightAssociations,
				boxAssociationsMap,
				boxParentAttributes,
				boxAttributeTypes,
				attributeTypeVisibilityMap,
				boxStyles,
				illustrationFlattenedBoxMap
			);
		}
	}

	// Set the boxes properties from its attributes
	setBoxPropertiesFromAttributes(
		boxProperties,
		boxKey,
		boxTypeKey,
		boxTypeVisibilityMap,
		boxParentTypeKey || '',
		boxParentAttributeTypes,
		boxParentAttributes,
		currentlyHighlightedBoxKey,
		currentlyHighlightedBoxTypeKey,
		currentlyHighlightedBoxAttributeTypes,
		currentlyHighlightedBoxAttributes,
		canHighlightAssociations,
		boxAssociationsMap,
		attributes,
		boxAttributeTypes,
		attributeTypeVisibilityMap,
		boxStyles,
		illustrationFlattenedBoxMap
	);

	// Make sure the textIsVertial property is actually true or false
	boxProperties.textIsVertical =
		!!boxProperties.textIsVertical &&
		String(boxProperties.textIsVertical).toLowerCase() === "true"
			? true
			: false;

	// Is the box the currently highlighted drop target?
	if (boxKey === currentlyHighlightedDropTargetBoxKey) {
		// Overwrite the background color
		boxProperties.backgroundColor = "#AAA";
	}

	// console.log(boxProperties)
};

export const getBoxProperties = (boxKey: string,
	box: Box,
	boxTypes: boxTypeLib.BoxTypeMap | undefined,
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined,
	boxAttributeTypes: attributeTypeLib.AttributeTypeMap,
	attributeTypeVisibilityMap: attributeTypeLib.AttributeTypeVisibilityMap,
	boxStyles: BoxStyleMap | undefined,
	boxParentTypeKey: string,
	boxParentProperties: Properties | undefined,
	boxParentAttributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	boxParentAttributes: attributeLib.AttributeMap | undefined,
	boxAssociationsMap: BoxAssociationsMap | undefined,
	currentlyHighlightedBoxKey: string,
	currentlyHighlightedBoxTypeKey: string,
	currentlyHighlightedBoxAttributeTypes: attributeTypeLib.AttributeTypeMap | undefined,
	currentlyHighlightedBoxAttributes: attributeLib.AttributeMap | undefined,
	currentlyHighlightedDropTargetBoxKey: string,
	canHighlightAssociations: boolean,
	illustrationFlattenedBoxMap: BoxMap | undefined): Properties => {
	const boxProperties = propertyLib.createBoxDefaultProperties();

	// Set the box properties
	setBoxProperties(
		boxProperties,
		box.boxType,
		boxTypes,
		boxTypeVisibilityMap,
		boxParentTypeKey,
		box.defaultProperties,
		box.inheritParentProperties,
		boxParentProperties,
		box.attributes,
		box.inheritParentAttributes,
		boxParentAttributes,
		boxStyles,
		boxAttributeTypes,
		boxParentAttributeTypes,
		attributeTypeVisibilityMap,
		boxKey,
		currentlyHighlightedBoxKey,
		currentlyHighlightedBoxTypeKey,
		currentlyHighlightedBoxAttributeTypes,
		currentlyHighlightedBoxAttributes,
		currentlyHighlightedDropTargetBoxKey,
		canHighlightAssociations,
		boxAssociationsMap,
		illustrationFlattenedBoxMap
	);

	return boxProperties;
}

export const buildBoxVisibilityMap = (
	flattenedBoxMap: BoxMap | undefined,
	boxKeys: string[]
): BoxVisibilityMap | undefined => {
	// If we don't have boxes, there's nothing to build
	if (!flattenedBoxMap) {
		return undefined;
	}

	// Get the box map types at this level of the tree
	const boxVisibilityMap: BoxVisibilityMap = boxKeys.reduce(
		(reducedBoxVisibilityMap: BoxVisibilityMap, boxKey: string) => {
			// Do we not already have the box in our map?
			if (
				!Object.prototype.hasOwnProperty.call(
					reducedBoxVisibilityMap,
					boxKey
				)
			) {
				// Add it, setting to visible by default
				reducedBoxVisibilityMap[boxKey] = {
					isVisible: true,
					isInLayout: true,
				};
			}

			return reducedBoxVisibilityMap;
		},
		{}
	);

	return boxVisibilityMap;
};

export const getUpdatedBoxVisibilityMapForBoxes = (
	boxVisibilityMap: BoxVisibilityMap | undefined,
	currentBoxMap: BoxMap | undefined,
	previousBoxMap: BoxMap | undefined
): BoxVisibilityMap | undefined => {
	// If our inputs are invalid there's nothing to do
	if (!boxVisibilityMap || !currentBoxMap || !previousBoxMap) {
		return undefined;
	}

	// Get a copy of the current box visibility map
	const updatedBoxVisibilityMap: BoxVisibilityMap = JSON.parse(
		JSON.stringify(boxVisibilityMap)
	);

	// Remove any box visibilities that are no longer present
	const previousBoxMapKeys = Object.keys(previousBoxMap);
	for (let i=0; i < previousBoxMapKeys.length; i += 1) {
		const boxKey = previousBoxMapKeys[i];

		if (!Object.prototype.hasOwnProperty.call(currentBoxMap, boxKey)) {
			delete updatedBoxVisibilityMap[boxKey];
		}
	};

	// Add any new box visibilities, defaulting them to visible
	if (boxVisibilityMap) {
		const currentBoxMapKeys = Object.keys(currentBoxMap);
		for (let i=0; i < currentBoxMapKeys.length; i += 1) {
			const boxKey = currentBoxMapKeys[i];
	
			if (!Object.prototype.hasOwnProperty.call(previousBoxMap, boxKey)) {
				updatedBoxVisibilityMap[boxKey] = {
					isVisible: true,
					isInLayout: true,
				};
			}
		};
	}

	return updatedBoxVisibilityMap;
};

export const getBoxKeysForBoxTypeKey = (
	flattenedBoxMap: BoxMap | undefined,
	boxTypeKey: string
) => {
	// The box keys
	const boxKeys: string[] = [];

	// Do we have a box map?
	if (flattenedBoxMap) {
		// Loop thru the boxes
		const flattenedBoxMapKeys = Object.keys(flattenedBoxMap);
		for (let i=0; i < flattenedBoxMapKeys.length; i += 1) {
			const boxKey = flattenedBoxMapKeys[i];

			const boxTypesEqual = (flattenedBoxMap[boxKey].boxType === undefined && boxTypeKey === 'undefined') 
			|| flattenedBoxMap[boxKey].boxType === boxTypeKey;
			
			if (boxTypesEqual) {
				boxKeys.push(boxKey);
			}
		}
	}

	return boxKeys;
};

export const getBoxNamesForBoxKeys = (
	flattenedBoxMap: BoxMap | undefined,
	boxKeys: string[]
) => {
	// The box names
	const boxNames: string[] = [];

	// Do we have a box map?
	if (flattenedBoxMap) {
		// Loop thru the boxes
		for (let i=0; i < boxKeys.length; i += 1) {
			const boxKey = boxKeys[i];

			const boxName = flattenedBoxMap[boxKey].name;

			if (boxName) {
				boxNames.push(boxName);
			}
		};
	}

	return boxNames;
};

export const getBoxCount = (boxKeys: string[],
	boxes: BoxMap | undefined,
	boxTypeVisibilityMap: BoxTypeVisibilityMap | undefined,
	boxVisibilityMap: BoxVisibilityMap | undefined,
	boxWeightMap: BoxWeightMap): number => {
	// The box count
	let boxCount = 0;

	// Do we have any boxes?
	if (boxes) {
		// Loop thru the boxes determining if they're visible or not
		for (let i=0; i < boxKeys.length; i += 1) {
			const boxKey = boxKeys[i];

			// Get the box
			const layoutBox = boxes[boxKey];

			// Get the box type key
			const boxTypeKey = (layoutBox.boxType) ? layoutBox.boxType : '';

			// Get the box type visibility
			const boxTypeVisibility = (boxTypeVisibilityMap)
				? boxTypeVisibilityMap[boxTypeKey]
				: undefined;

			// If the box types are not visible and not in the layout, don't
			// include them
			if ((boxTypeVisibility)
				&& (!boxTypeVisibility.areBoxesVisible)
				&& (!boxTypeVisibility.areBoxesInLayout)) {
				continue;
			}

			// Get the box visibility
			const boxVisibility = (boxVisibilityMap)
				? boxVisibilityMap[boxKey]
				: undefined;

			// If the box is not visible and not in the layout, don't render it
			if ((boxVisibility)
				&& (!boxVisibility.isVisible)
				&& (!boxVisibility.isInLayout)) {
				continue;
			}

			const boxWeight = boxWeightMap[boxKey];
			if (!boxWeight || (!isNaN(boxWeight) && (boxWeight <= 0))) {
				continue;
			}

			// We have one more box
			boxCount += 1;
		}
	}

	return boxCount;
}

export const getBoxText = (box: Box, boxProperties: Properties): string => {
	// Build the box text
	const boxText =
		boxProperties.text.length > 0
			? `${box.name}</br>${boxProperties.text}`
			: `${box.name}`;

	return boxText;
}

export const hasChildren = (box: Box): boolean => {
	const hasChildren = !!box.children && Object.keys(box.children).length > 0;

	return hasChildren;
}

export const hasChildrenInLayout = (box: Box,
	boxVisibilityMap?: BoxVisibilityMap,
	boxTypeVisibilityMap?: BoxTypeVisibilityMap): boolean => {
	const children = box.children;

	if (!children || !boxVisibilityMap || !boxTypeVisibilityMap) {
		return false;
	}

	if (!hasChildren(box)) {
		return false;
	}

	const hasVisibleChildren = Object
		.keys(children)
		.some((childBoxKey: string) => {
			const childBox = children[childBoxKey];
			if (!childBox) {
				return false;
			}

			const childBoxTypeKey = childBox.boxType;			
			const childBoxTypeVisibility = boxTypeVisibilityMap[childBoxTypeKey];
			if (childBoxTypeVisibility) {
				const areBoxesVisible = childBoxTypeVisibility.areBoxesVisible;
				const areBoxesInLayout = childBoxTypeVisibility.areBoxesInLayout; 

				if (!areBoxesVisible && !areBoxesInLayout) {
					return false;
				}
			}

			const childBoxVisibility = boxVisibilityMap[childBoxKey];
			if(childBoxVisibility) {
				const isVisible = childBoxVisibility.isVisible;
				const isInLayout = childBoxVisibility.isInLayout; 

				// A box is in the layout if it's visible, or if it's hidden but
				// has been set to still take up space
				// return (isVisible || areBoxesVisible) || (isInLayout || areBoxesInLayout);
				return isVisible || isInLayout;
			}

			return false;
		})

	return hasVisibleChildren;
}


export const getIsSelected = (boxKey: string,
	boxSelectionInfoMap?: BoxSelectionInfoMap): boolean => {
	const isSelected = ((!!boxSelectionInfoMap) &&
		(Object.prototype.hasOwnProperty.call(boxSelectionInfoMap, boxKey)))

	return isSelected;
}

const setNewKeysForChildren = (box: Box): void => {
	const children = box.children;
	if (children) {
		const childrenKeys = Object.keys(children);
		for (let i=0; i < childrenKeys.length; i += 1) {
			const childKey = childrenKeys[i];

			// Generate a new key for the child
			const newChildKey = uuid();

			// Swap the child over to the new key
			children[newChildKey] = children[childKey];

			// Remove the original key
			delete children[childKey];

			// Update the keys of the child
			setNewKeysForChildren(children[newChildKey]);
		}
	}
}

export const cloneBox = (box: Box): Box => {
	const clonedBox: Box = (JSON.parse(JSON.stringify(box)) as Box);

	setNewKeysForChildren(clonedBox);

	return clonedBox;
}

export const getBoxChildren = (flattenedBoxMap: BoxMap | undefined, boxKey: string): string[] => {
	let children: string[] = [];

	if (flattenedBoxMap) {
		const box = flattenedBoxMap[boxKey];

		// Does the box have children?
		if (box && box.children && hasChildren(box)) {
			const childBoxKeys = Object.keys(box.children);

			for (let i=0; i < childBoxKeys.length; i += 1) {
				const childBoxKey = childBoxKeys[i];

				children.push(childBoxKey);

				const childBox = flattenedBoxMap[childBoxKey];
				if (childBox && childBox.children && hasChildren(childBox)) {
					const childBoxChildern = getBoxChildren(flattenedBoxMap, childBoxKey);
					children = children.concat(childBoxChildern);
				}
			}
		}
	}

	return children;
}

export const getBoxChildlessChildren = (flattenedBoxMap: BoxMap | undefined, boxKey: string): string[] => {
	let childlessChildren: string[] = [];

	if (flattenedBoxMap) {
		const box = flattenedBoxMap[boxKey];

		// Does the box have children?
		if (box && box.children && hasChildren(box)) {
			const childBoxKeys = Object.keys(box.children);

			for (let i=0; i < childBoxKeys.length; i += 1) {
				const childBoxKey = childBoxKeys[i];

				const childBox = flattenedBoxMap[childBoxKey];

				if (childBox && childBox.children && hasChildren(childBox)) {
					const childBoxChildlessChildern = getBoxChildlessChildren(flattenedBoxMap, childBoxKey);
					childlessChildren = childlessChildren.concat(childBoxChildlessChildern);
				} else {
					childlessChildren.push(childBoxKey);
				}
			}
		}
	}

	return childlessChildren;
}