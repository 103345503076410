import * as boxTypeLib from "@lib/box/box-type";
import * as boxLib from "@lib/box/box";
import * as attributeTypeLib from "@lib/box/attribute-type";
import * as attributeLib from "@lib/box/attribute";

export enum AttributeExpressionOperator {
	TRUE = "true",
	FALSE = "false",
	EQUAL = "==",
	NOT_EQUAL = "!=",
	LESS_THAN = "<",
	LESS_THAN_OR_EQUAL_TO = "<=",
	GREATER_THAN = ">",
	GREATER_THAN_OR_EQUAL_TO = ">=",
	INCLUDES = "includes",
}

export enum QueryType {
	DEFAULT = "Default",
	ATTRIBUTE = "Attribute",
	BOX_TYPE = "Box Type"
}

export enum EqualityOperator {
	EQUAL = "==",
	NOT_EQUAL = "!=",
}

export enum BooleanOperator {
	AND = "and",
	OR = "or",
}

// Could be replaced with the expression library but we would still need an editor for it.
export interface AttributeExpression {

	// UUID of the attribute type that we want to match
	attributeType: string;

	// The value that we are going to test against for equaility etc.based on the operator
	attributeValue: attributeLib.AttributeValue;

	// This should be taken from a list of the parameters in the top Power Lens form. It's basically picking values that are getting passed in. if this is set then we don't want to specify the attribute value. Parameter takes precedence.
	attributeParameter: string;

	// This is the comparison operator to test the attribute's value against either attributeParameter OR attributeValue (depending on which one is set)
	operator: AttributeExpressionOperator;

	// Recursive structure so we can basically do brackets. E.g.
	// (a === "hello" && b === "world") || (a === "help")
	children: AttributeExpression[] | undefined;

	// Whether child expressions are AND/OR'd with each other. E.g. 
	// (a === "help") || (a === "hello" && b === "world")
	// (a === "help") == first AttributeExpression
	// first.children == [a === "hello", b === "world"]
	// first.childrenOperator == &&
	// first.childrenExpressionOperator == ||
	// Supported by automatic config: (a === "first") || (a === "second" || a === "third" || a === "fourth")
	// (a === "first") && (b === "second" && c === "third" && d === "fourth")


	// (a === "first") || (b === "second" && (c === "third" || d === "fourth"))

	// Attribute Conditions
	// Operator: AND
	// 1. A === "first"
	// (2. B === "second"
	// 3. C === "third")
	childrenOperator: BooleanOperator;

	// Whether final result of child expressions is AND/OR'd with expression
	// result
	childrenExpressionOperator: BooleanOperator;

	// Simple UI could support
	// a === 1 && (b === 2 && c === 3)
	// a === "test1" || (a === "test2" || a === "test3")
	// In this scenario, we would link the values of childrenOperator and childrenExpressionOperator

}

export interface BoxTypeExpressionMap {
	[key: string]: EqualityOperator;
}

export interface AttributeTypeExpressionMap {
	[key: string]: EqualityOperator;
}

export interface BoxExpressionMap {
	[key: string]: EqualityOperator;
}

export interface Query {
	// Added by me so we can tell them apart
	name?: string;

	// The type of Query. Used to figure out if we can edit it or not, and which form to use to edit.
	type?: QueryType;

	// List of expressions that test the type of the current box
	boxTypeExpressions: BoxTypeExpressionMap | undefined;

	// List of expressions that test whether the current box has or doesn't an attribute that corresponds to the attribute type in the expression
	attributeTypeExpressions: AttributeTypeExpressionMap | undefined;

	// List of expressions that test whether the current box matches a specific UUID
	boxExpressions: BoxExpressionMap | undefined;

	// Recursive structure that tests selected attributes of a box to see if they match (==, > etc.) a value. Recursive structure includes the attributeExpressionOperator, which is why there is no value on the base query
	attributeExpression: AttributeExpression | undefined;

	// Logical operator that applies to all of the boxTypeExpressions
	boxTypeExpressionOperator: BooleanOperator;

	// Logical operator that applies to all of the attributeTypeExpressions
	attributeTypeExpressionOperator: BooleanOperator;

	// Logical operator that applies to all of the boxExpressions
	boxExpressionOperator: BooleanOperator;

	// Logical operator that applies to the combination of all expressions
	operator: BooleanOperator;

	// If the query evaluated to true, determines whether we apply boxTypeAreBoxesVisible and boxTypeAreBoxesInLayout
	applyBoxTypeVisibility: boolean;

	// if applyBoxTypeVisibility is set, this value will be used to determine whether a box type is hidden or shown using the Advanced Box Type left hand menu. "Globally whether the box type is visible or not". Corresponds to the checkbox next to the Box Type name in Advanced Box Types
	boxTypeIsVisible: boolean;
	
	// if applyBoxTypeVisibility is set, this will set the isBoxVisibile ("Show Boxes" in Advanced Box Types Left Hand Menu) box for the box types that match the query.
	boxTypeAreBoxesVisible: boolean;

	// if applyBoxTypeVisibility is set, this will set the areBoxesInLayout ("Keep Hidden boxes in layout" in Advanced Box Types Left Hand Menu) box for the box types that match the query.
	boxTypeAreBoxesInLayout: boolean;

	// This turns box attributes on and off for a attribute type that belongs to a mixin of the box.
	// Basically show/hide an attribute with a boolean determining whether it's shown/hidden. Just needs to be a list, not the big selection we currently have. Maybe we don't need to have this, or can hide it behind an advanced view.
	boxTypeMixinBoxTypeVisibilityMap:
	| boxTypeLib.BoxTypeVisibilityMap
	| undefined;

	// This turns box attributes on and off for a particular type.
	// Basically show/hide an attribute with a boolean determining whether it's shown/hidden. Just needs to be a list, not the big selection we currently have
	boxTypeAttributeTypeVisibilityMap:
	| attributeTypeLib.AttributeTypeVisibilityMap
	| undefined;

	// If the query evaluated to true, determines whether we apply boxIsVisible and boxIsInLayout
	applyBoxVisibility: boolean;

	// if applyBoxVisibility is set, this will set the isBoxVisibile ("Show Box" in Boxes Left Hand Menu) for the boxes that match the query.
	boxIsVisible: boolean;

	// if applyBoxVisibility is set, this will set the boxInLayout ("keep Hidden Box In Illustration" in Boxes Left Hand Menu) for the boxes that match the query.
	boxIsInLayout: boolean;
}

export type ParameterMap = { [key: string]: attributeLib.AttributeValue };

export interface Lens {
	name: string;
	order: number;
	description?: string;
	queries: Query[] | undefined;
	parameters: ParameterMap | undefined;
}

export interface SimpleLens {
	name: string;
	description: string;
	order: number;
	entries: SimpleLensEntry[];
}

export interface SimpleLensEntry {
	boxTypeKey: string;
	attributeTypeKey: string;
}

export interface SimpleLensGroup {
	name: string;
	description: string;
	order: number;
	lenses: SimpleLensMap;
}

export type SimpleLensGroupMap = { [key: string]: SimpleLensGroup };

export type LensMap = { [key: string]: Lens };

export type SimpleLensMap = { [key: string]: SimpleLens };

export type LensVisibilityMap = { [key: string]: boolean };

export type LensGroup = {
	name: string;
	description?: string,
	order: number;
	lensKeys: string[];
};

export type LensGroupMap = { [key: string]: LensGroup };

export type LensGroupVisibilityMap = { [key: string]: boolean };

const evaluateAttributeValueExpression = (
	boxType: boxTypeLib.BoxType | undefined,
	boxTypeKey: string,
	boxTypes: boxTypeLib.BoxTypeMap | undefined,
	box: boxLib.Box | undefined,
	expression: AttributeExpression | undefined,
	parameterMap: ParameterMap | undefined
): boolean => {
	// The result of evaluating this expression
	let result = false;

	// Check our inputs are valid
	if (boxType && box && expression && parameterMap) {
		// Get the box attributes
		const boxAttributes = box.attributes;
		if (boxAttributes) {
			// Get the box attribute type key
			const boxAttributeTypeKey = expression.attributeType;

			// Get the expression operator
			const expressionOperator = expression.operator;

			// Are we just short-circuiting to true or false?
			if (expressionOperator === AttributeExpressionOperator.TRUE) {
				result = true;
			} else if (
				expressionOperator === AttributeExpressionOperator.FALSE
			) {
				result = false;
			} else {
				const boxAttributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(boxTypeKey);

				// Does the attribute type exist?
				if (
					Object.prototype.hasOwnProperty.call(
						boxAttributeTypes,
						boxAttributeTypeKey
					)
				) {
					// Get the current attribute value
					const currentAttributeValue =
						boxAttributes[boxAttributeTypeKey];

					// Get the expression attribute value
					let expressionAttributeValue = expression.attributeValue;

					// If we have a parameter value, override the expression attribute
					// value
					if (expression.attributeParameter) {
						if (
							Object.prototype.hasOwnProperty.call(
								parameterMap,
								expression.attributeParameter
							)
						) {
							expressionAttributeValue =
								parameterMap[expression.attributeParameter];
						}
					}

					// Evaluate the expression
					switch (expressionOperator) {
						case AttributeExpressionOperator.EQUAL: {
							result =
								currentAttributeValue ===
								expressionAttributeValue;
							break;
						}
						case AttributeExpressionOperator.NOT_EQUAL: {
							result =
								currentAttributeValue !==
								expressionAttributeValue;
							break;
						}
						case AttributeExpressionOperator.LESS_THAN: {
							// Get both attribute values as numbers
							const currentAttributeValueNumber = Number(
								currentAttributeValue
							);
							const expressionAttributeValueNumber = Number(
								expressionAttributeValue
							);

							// Check both values are numeric
							if (
								!Number.isNaN(currentAttributeValueNumber) &&
								!Number.isNaN(expressionAttributeValueNumber)
							) {
								// Evaluate the expression
								result =
									currentAttributeValueNumber <
									expressionAttributeValueNumber;
							}
							break;
						}
						case AttributeExpressionOperator.LESS_THAN_OR_EQUAL_TO: {
							// Get both attribute values as numbers
							const currentAttributeValueNumber = Number(
								currentAttributeValue
							);
							const expressionAttributeValueNumber = Number(
								expressionAttributeValue
							);

							// Check both values are numeric
							if (
								!Number.isNaN(currentAttributeValueNumber) &&
								!Number.isNaN(expressionAttributeValueNumber)
							) {
								// Evaluate the expression
								result =
									currentAttributeValueNumber <=
									expressionAttributeValueNumber;
							}
							break;
						}
						case AttributeExpressionOperator.GREATER_THAN: {
							// Get both attribute values as numbers
							const currentAttributeValueNumber = Number(
								currentAttributeValue
							);
							const expressionAttributeValueNumber = Number(
								expressionAttributeValue
							);

							// Check both values are numeric
							if (
								!Number.isNaN(currentAttributeValueNumber) &&
								!Number.isNaN(expressionAttributeValueNumber)
							) {
								// Evaluate the expression
								result =
									currentAttributeValueNumber >
									expressionAttributeValueNumber;
							}
							break;
						}
						case AttributeExpressionOperator.GREATER_THAN_OR_EQUAL_TO: {
							// Get both attribute values as numbers
							const currentAttributeValueNumber = Number(
								currentAttributeValue
							);
							const expressionAttributeValueNumber = Number(
								expressionAttributeValue
							);

							// Check both values are numeric
							if (
								!Number.isNaN(currentAttributeValueNumber) &&
								!Number.isNaN(expressionAttributeValueNumber)
							) {
								// Evaluate the expression
								result =
									currentAttributeValueNumber >=
									expressionAttributeValueNumber;
							}
							break;
						}
						case AttributeExpressionOperator.INCLUDES: {
							// Get both attribute values as strings
							const currentAttributeValueString = String(
								currentAttributeValue
							);
							const expressionAttributeValueString = String(
								expressionAttributeValue
							);

							// Evaluate the expression
							result = currentAttributeValueString.includes(
								expressionAttributeValueString
							);

							break;
						}
					}
				}
			}

			// Get the expression children
			const expressionChildren = expression.children;

			// Does the expression have children?
			if (expressionChildren && expressionChildren.length > 0) {
				// The combined result of evaluating the children
				let combinedChildResult =
					expression.childrenOperator === BooleanOperator.AND
						? true
						: false;

				// Loop thru the child expressions
				expressionChildren.forEach((childExpression) => {
					// Evaluate the child expression
					const childResult = evaluateAttributeValueExpression(
						boxType,
						boxTypeKey,
						boxTypes,
						box,
						childExpression,
						parameterMap
					);

					// Combine it with the rest of the child results
					if (expression.childrenOperator === BooleanOperator.AND) {
						combinedChildResult =
							combinedChildResult && childResult;
					} else if (
						expression.childrenOperator === BooleanOperator.OR
					) {
						combinedChildResult =
							combinedChildResult || childResult;
					}
				});

				// Combine the child results with the result
				if (
					expression.childrenExpressionOperator ===
					BooleanOperator.AND
				) {
					result = result && combinedChildResult;
				} else if (
					expression.childrenExpressionOperator === BooleanOperator.OR
				) {
					result = result || combinedChildResult;
				}
			}
		}
	}

	return result;
};

const evaluateQuery = (
	query: Query,
	parameterMap: ParameterMap,
	currentBoxKey: string,
	currentBox: boxLib.Box,
	currentBoxType: boxTypeLib.BoxType,
	boxTypes: boxTypeLib.BoxTypeMap
) => {
	// The result
	let result = false;

	// Get the box, box type, and attribute expressions
	const queryBoxExpressions = query.boxExpressions;
	const queryBoxTypeExpressions = query.boxTypeExpressions;
	const queryAttributeTypeExpressions = query.attributeTypeExpressions;
	const queryAttributeExpression = query.attributeExpression;

	// Get the query operator, defaulting to "and" if one isn't specified
	const queryOperator = query.operator ? query.operator : BooleanOperator.AND;

	// Get the box expression operator, defaulting to "and" if one isn't specified
	const boxExpressionOperator = query.boxExpressionOperator
		? query.boxExpressionOperator
		: BooleanOperator.AND;

	// Get the box type expression operator, defaulting to "and" if one isn't specified
	const boxTypeExpressionOperator = query.boxTypeExpressionOperator
		? query.boxTypeExpressionOperator
		: BooleanOperator.AND;

	// Get the attribute type expression operator, defaulting to "and" if one isn't specified
	const attributeTypeExpressionOperator = query.attributeTypeExpressionOperator
		? query.attributeTypeExpressionOperator
		: BooleanOperator.AND;

	// The results of each of the matches
	let boxMatchResult =
		boxExpressionOperator === BooleanOperator.AND ? true : false;
	let boxTypeMatchResult =
		boxTypeExpressionOperator === BooleanOperator.AND ? true : false;
	let attributeTypeMatchResult =
		attributeTypeExpressionOperator === BooleanOperator.AND ? true : false;
	let attributeExpressionMatchResult = false;

	// Are we not looking for a particular box?
	if (!queryBoxExpressions || Object.keys(queryBoxExpressions).length <= 0) {
		boxMatchResult = true;
	} else {
		// Evaluate each of the box expressions
		Object.keys(queryBoxExpressions).forEach((queryBoxKey) => {
			// The box expression result
			let boxExpressionResult = false;

			// Get the query box operator
			const queryBoxOperator = queryBoxExpressions[queryBoxKey];

			// Does the current box match and we want all boxes that match?
			if (queryBoxKey === currentBoxKey) {
				boxExpressionResult =
					queryBoxOperator === EqualityOperator.EQUAL ? true : false;
			} else if (queryBoxKey !== currentBoxKey) {
				// Does the current box not match and we want all boxes that match?
				boxExpressionResult =
					queryBoxOperator === EqualityOperator.NOT_EQUAL
						? true
						: false;
			}

			// Combine the expression result with the match result
			boxMatchResult =
				boxExpressionOperator === BooleanOperator.AND
					? boxMatchResult && boxExpressionResult
					: boxMatchResult || boxExpressionResult;
		});
	}

	// Get the type of the current box
	const currentBoxTypeKey = currentBox.boxType;

	// Are we not looking for a particular box type?
	if (
		!queryBoxTypeExpressions ||
		Object.keys(queryBoxTypeExpressions).length <= 0
	) {
		boxTypeMatchResult = true;
	} else {
		// Evaluate each of the box type expressions
		Object.keys(queryBoxTypeExpressions).forEach((queryBoxTypeKey) => {
			// The box type expression result
			let boxTypeExpressionResult = false;

			// Get the query box type operator
			const queryBoxTypeOperator =
				queryBoxTypeExpressions[queryBoxTypeKey];

			// Does the current box type match and we want all box types that match?
			if (queryBoxTypeKey === currentBoxTypeKey) {
				boxTypeExpressionResult =
					queryBoxTypeOperator === EqualityOperator.EQUAL
						? true
						: false;
			} else if (queryBoxTypeKey !== currentBoxTypeKey) {
				// Does the current box type not match and we want all box types that
				// match?
				boxTypeExpressionResult =
					queryBoxTypeOperator === EqualityOperator.NOT_EQUAL
						? true
						: false;
			}

			// Combine the expression result with the match result
			boxTypeMatchResult =
				boxTypeExpressionOperator === BooleanOperator.AND
					? boxTypeMatchResult && boxTypeExpressionResult
					: boxTypeMatchResult || boxTypeExpressionResult;
		});
	}

	// Are we not looking for a particular attribute type?
	if (
		!queryAttributeTypeExpressions ||
		Object.keys(queryAttributeTypeExpressions).length <= 0
	) {
		attributeTypeMatchResult = true;
	} else {
		// Evaluate each of the attribute type expressions
		Object.keys(queryAttributeTypeExpressions).forEach(
			(queryAttributeTypeKey) => {
				// The attribute type expression result
				let attributeTypeExpressionResult = false;

				const boxAttributeTypes = boxTypeLib.getBoxTypeAttributeTypeCacheForType(currentBoxTypeKey);
				if (!boxAttributeTypes) {
					return;
				}

				// Whether the box type has the attribute type key
				const hasCurrentAttributeTypeKey = Object.prototype.hasOwnProperty.call(
					boxAttributeTypes,
					queryAttributeTypeKey
				);

				// Get the query attribute type operator
				const queryAttributeTypeOperator =
					queryAttributeTypeExpressions[queryAttributeTypeKey];

				// Does the current attribute type match and we want all box types that match?
				if (hasCurrentAttributeTypeKey) {
					attributeTypeExpressionResult =
						queryAttributeTypeOperator === EqualityOperator.EQUAL
							? true
							: false;
				} else if (!hasCurrentAttributeTypeKey) {
					// Does the current attribute type not match and we want all attribute types that
					// match?
					attributeTypeExpressionResult =
						queryAttributeTypeOperator ===
							EqualityOperator.NOT_EQUAL
							? true
							: false;
				}

				// Combine the expression result with the match result
				attributeTypeMatchResult =
					attributeTypeExpressionOperator === BooleanOperator.AND
						? attributeTypeMatchResult &&
						attributeTypeExpressionResult
						: attributeTypeMatchResult ||
						attributeTypeExpressionResult;
			}
		);
	}

	// Do we not have an attribute expression?
	if (!queryAttributeExpression) {
		// Since we don't have one the attribute expression result is a match
		attributeExpressionMatchResult = true;
	} else {
		// Evaluate the attribute expression
		attributeExpressionMatchResult = evaluateAttributeValueExpression(
			currentBoxType,
			currentBoxTypeKey,
			boxTypes,
			currentBox,
			queryAttributeExpression,
			parameterMap
		);
	}

	// Generate the final result based on the parameter operator
	if (queryOperator === BooleanOperator.AND) {
		result =
			boxMatchResult &&
			boxTypeMatchResult &&
			attributeTypeMatchResult &&
			attributeExpressionMatchResult;
	} else if (queryOperator === BooleanOperator.OR) {
		result =
			boxMatchResult ||
			boxTypeMatchResult ||
			attributeTypeMatchResult ||
			attributeExpressionMatchResult;
	}

	return result;
};

const applyQueryVisibilities = (
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap,
	boxVisibilityMap: boxLib.BoxVisibilityMap,
	currentBoxKey: string,
	currentBoxTypeKey: string,
	query: Query
) => {
	// Get the current box type visibility
	let currentBoxTypeVisibility = boxTypeVisibilityMap[currentBoxTypeKey];

	// Get the updated box type visibility, making sure to create one if we
	// don't already have one
	const updatedBoxTypeVisibility = currentBoxTypeVisibility
		? JSON.parse(JSON.stringify(currentBoxTypeVisibility))
		: {};

	// Should we apply the box type visibilities?
	if (query.applyBoxTypeVisibility) {
		// Update the box type visibilities if they're set in the query
		if (Object.prototype.hasOwnProperty.call(query, "boxTypeIsVisible")) {
			updatedBoxTypeVisibility.isVisible = query.boxTypeIsVisible;
		}
		if (
			Object.prototype.hasOwnProperty.call(
				query,
				"boxTypeAreBoxesVisible"
			)
		) {
			updatedBoxTypeVisibility.areBoxesVisible =
				query.boxTypeAreBoxesVisible;
		}
		if (
			Object.prototype.hasOwnProperty.call(
				query,
				"boxTypeAreBoxesInLayout"
			)
		) {
			updatedBoxTypeVisibility.areBoxesInLayout =
				query.boxTypeAreBoxesInLayout;
		}

		// Get the mixin boxy type visibility map
		const queryBoxTypeMixinBoxTypeVisibilityMap =
			query.boxTypeMixinBoxTypeVisibilityMap;
		if (queryBoxTypeMixinBoxTypeVisibilityMap) {
			// Get the current mixin box type visibility map, making sure to create
			// one if we don't already have one
			const currentMixinBoxTypeVisibilityMap = updatedBoxTypeVisibility.mixinBoxTypeVisibilityMap
				? updatedBoxTypeVisibility.mixinBoxTypeVisibilityMap
				: {};

			// Build the updated mixin box type visibility map
			const updatedMixinBoxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap = {
				...currentMixinBoxTypeVisibilityMap,
				...queryBoxTypeMixinBoxTypeVisibilityMap,
			};

			// Store it in the updated box type visibility
			updatedBoxTypeVisibility.mixinBoxTypeVisibilityMap = updatedMixinBoxTypeVisibilityMap;
		}
	}

	// Get the attribute type visibility map
	const queryBoxTypeAttributeTypeVisibilityMap =
		query.boxTypeAttributeTypeVisibilityMap;
	if (queryBoxTypeAttributeTypeVisibilityMap) {
		// Get the current attribute type visibility map, making sure to create
		// one if we don't already have one
		const currentAttributeTypeVisibilityMap = updatedBoxTypeVisibility.attributeTypeVisibilityMap
			? updatedBoxTypeVisibility.attributeTypeVisibilityMap
			: {};

		// Build the updated attribute type visibility map
		const updatedAttributeTypeVisibilityMap: attributeTypeLib.AttributeTypeVisibilityMap = {
			...currentAttributeTypeVisibilityMap,
			...queryBoxTypeAttributeTypeVisibilityMap,
		};

		// Store it in the updated box type visibility
		updatedBoxTypeVisibility.attributeTypeVisibilityMap = updatedAttributeTypeVisibilityMap;
	}

	// Store the updated box type visibilities in the box type visibility mao
	boxTypeVisibilityMap[currentBoxTypeKey] = updatedBoxTypeVisibility;

	// Should we apply the box visibilities?
	if (query.applyBoxVisibility) {
		// Get the current box visibility
		let currentBoxVisibility = boxVisibilityMap[currentBoxKey];

		// Get the updated box visibility, making sure to create one if we don't
		// already have one
		const updatedBoxVisibility = currentBoxVisibility
			? JSON.parse(JSON.stringify(currentBoxVisibility))
			: {};

		// Update the box visibilities
		updatedBoxVisibility.isVisible = query.boxIsVisible;
		updatedBoxVisibility.isInLayout = query.boxIsInLayout;

		// Store the updated box visibilities in the box visibility mao
		boxVisibilityMap[currentBoxKey] = updatedBoxVisibility;
	}
};

export const setVisibilityMapsForLenses = (
	boxTypeVisibilityMap: boxTypeLib.BoxTypeVisibilityMap | undefined,
	boxVisibilityMap: boxLib.BoxVisibilityMap | undefined,
	boxTypeMap: boxTypeLib.BoxTypeMap | undefined,
	flattenedBoxMap: boxLib.BoxMap | undefined,
	lensMap: LensMap | undefined,
	lensVisibilityMap: LensVisibilityMap | undefined
): boxTypeLib.BoxTypeVisibilityMap | undefined => {
	// If we don't have valid inputs, there's nothing to do
	if (
		!boxTypeVisibilityMap ||
		!boxVisibilityMap ||
		!boxTypeMap ||
		!flattenedBoxMap ||
		!lensMap ||
		!lensVisibilityMap
	) {
		return;
	}

	// Set box type visibility map based on each of the lenss
	Object.keys(lensMap).forEach((lensId) => {
		// Is the lens visible?
		const isLensVisible = lensVisibilityMap[lensId];

		// If the lens isn't visible, ignore it
		if (isLensVisible) {
			// Get the lens
			const lens = lensMap[lensId];
			if (lens) {
				// console.log(`\tapplying lens ${lens.name}`);

				// Get the parameter map
				const parameterMap = lens.parameters ? lens.parameters : {};

				// Get the queries
				const queries = lens.queries;
				if (queries) {
					// Loop thru the queries
					queries.forEach((query: Query) => {
						// Do we have a query
						if (query) {
							// Loop thru the boxes
							Object.keys(flattenedBoxMap).forEach(
								(currentBoxKey) => {
									// Get the current box
									const currentBox =
										flattenedBoxMap[currentBoxKey];
									if (currentBox) {
										// Get the current box type key
										const currentBoxTypeKey =
											currentBox.boxType;

										// Get the current box type
										const currentBoxType =
											boxTypeMap[currentBoxTypeKey];
										if (currentBoxType) {
											// Evaluate the query against the current box
											const parameterResult = evaluateQuery(
												query,
												parameterMap,
												currentBoxKey,
												currentBox,
												currentBoxType,
												boxTypeMap
											);

											// Was it a match?
											if (parameterResult) {
												// We've already checked this at the start of the
												// function, but Typescript fails to pick it up
												if (
													boxTypeVisibilityMap &&
													boxVisibilityMap
												) {
													// Apply the visbilities of the query
													applyQueryVisibilities(
														boxTypeVisibilityMap,
														boxVisibilityMap,
														currentBoxKey,
														currentBoxTypeKey,
														query
													);
												}
											}
										}
									}
								}
							);
						}
					});
				}
			}
		}
	});

	return boxTypeVisibilityMap;
};

export const getUpdatedLensVisibilityMapForLenses = (
	lensVisibilityMap: LensVisibilityMap | undefined,
	currentLensMap: LensMap | undefined,
	previousLensMap: LensMap | undefined
): LensVisibilityMap | undefined => {
	// If our inputs are invalid there's nothing to do
	if (!lensVisibilityMap) {
		return undefined;
	}

	// Get a copy of the current lens visibility map
	const updatedLensVisibilityMap = JSON.parse(
		JSON.stringify(lensVisibilityMap)
	);

	// Remove any lens visibilities that are no longer present
	if (currentLensMap && previousLensMap) {
		Object.keys(previousLensMap).forEach((lensKey: string) => {
			if (
				!Object.prototype.hasOwnProperty.call(currentLensMap, lensKey)
			) {
				delete updatedLensVisibilityMap[lensKey];
			}
		});

		// Add any new lens visibilities, defaulting them to false
		Object.keys(currentLensMap).forEach((lensKey: string) => {
			if (
				!Object.prototype.hasOwnProperty.call(previousLensMap, lensKey)
			) {
				if (
					!Object.prototype.hasOwnProperty.call(
						updatedLensVisibilityMap,
						lensKey
					)
				) {
					updatedLensVisibilityMap[lensKey] = false;
				}
			}
		});
	}

	return updatedLensVisibilityMap;
};

export const buildLensVisibilityMap = (
	lensMap: LensMap | undefined
): LensVisibilityMap => {
	// The lens visibility map (empty by default)
	const lensVisibilityMap: LensVisibilityMap = {};

	// Do we have lens map?
	if (lensMap) {
		// Set a default visibility for every lens (default to not visible)
		Object.keys(lensMap).forEach((lensId) => {
			lensVisibilityMap[lensId] = false;
		});
	}

	return lensVisibilityMap;
};

export const setLensVisibilityMapForLensGroupVisibility = (
	lensVisibilityMap: LensVisibilityMap | undefined,
	lensGroup: LensGroup | undefined,
	lensGroupIsVisible: boolean
) => {
	// If our inputs are invalid there's nothing to do
	if (!lensVisibilityMap || !lensGroup) {
		return undefined;
	}

	// Set the visibility of all the lenses in the group
	lensGroup.lensKeys.forEach((lensKey: string) => {
		lensVisibilityMap[lensKey] = lensGroupIsVisible;
	});
};

export const setLensVisibilityMapForLensGroupVisibilities = (
	lensVisibilityMap: LensVisibilityMap | undefined,
	lensGroupMap: LensGroupMap | undefined,
	lensGroupVisibilityMap: LensGroupVisibilityMap | undefined
) => {
	// If our inputs are invalid there's nothing to do
	if (!lensVisibilityMap || !lensGroupMap || !lensGroupVisibilityMap) {
		return undefined;
	}

	// Update the lens visibilities for each of the lens group visibilities
	Object.keys(lensGroupMap).forEach((lensGroupKey: string) => {
		// Get the visibility of the lens group
		const lensGroupIsVisible = lensGroupVisibilityMap[lensGroupKey]
			? true
			: false;

		// Get the lens group
		const lensGroup = lensGroupMap[lensGroupKey];

		// Update the lens visibilities for the lens group
		setLensVisibilityMapForLensGroupVisibility(
			lensVisibilityMap,
			lensGroup,
			lensGroupIsVisible
		);
	});
};

export const setDefaultLenses = (
	lensMap: LensMap | undefined,
	lensGroupMap: LensGroupMap | undefined
): void => {
	if (!lensMap || !lensGroupMap) {
		return;
	}

	// Add lens to turn all canvas indicators
	lensMap["Is Canvas"] = {
		name: "Is Canvas",
		description: "",
		order: -1,
		queries: [
			{
				boxTypeExpressions: undefined,
				attributeTypeExpressions: {
					// eslint-disable-next-line no-useless-computed-key
					["Is Canvas"]: EqualityOperator.EQUAL,
				},
				boxExpressions: undefined,
				attributeExpression: {
					attributeType: "Is Canvas",
					attributeValue: "True",
					attributeParameter: "",
					operator: AttributeExpressionOperator.EQUAL,
					children: undefined,
					childrenOperator: BooleanOperator.OR,
					childrenExpressionOperator: BooleanOperator.OR,
				},
				boxTypeExpressionOperator: BooleanOperator.OR,
				attributeTypeExpressionOperator: BooleanOperator.OR,
				boxExpressionOperator: BooleanOperator.OR,

				operator: BooleanOperator.AND,

				applyBoxTypeVisibility: false,
				boxTypeIsVisible: true,
				boxTypeAreBoxesVisible: true,
				boxTypeAreBoxesInLayout: true,
				boxTypeMixinBoxTypeVisibilityMap: undefined,
				boxTypeAttributeTypeVisibilityMap: {
					"Is Canvas": true,
				},

				applyBoxVisibility: false,
				boxIsVisible: true,
				boxIsInLayout: true,
			},
		],
		parameters: undefined,
	};

	// Add lens to turn all Lens Page indicators on/off
	lensMap["Has Lens Pages"] = {
		name: "Has Lens Pages",
		description: "",
		order: -1,
		queries: [
			{
				boxTypeExpressions: undefined,
				attributeTypeExpressions: {
					// eslint-disable-next-line no-useless-computed-key
					["Has Lens Pages"]: EqualityOperator.EQUAL,
				},
				boxExpressions: undefined,
				attributeExpression: {
					attributeType: "Has Lens Pages",
					attributeValue: "True",
					attributeParameter: "",
					operator: AttributeExpressionOperator.EQUAL,
					children: undefined,
					childrenOperator: BooleanOperator.OR,
					childrenExpressionOperator: BooleanOperator.OR,
				},
				boxTypeExpressionOperator: BooleanOperator.OR,
				attributeTypeExpressionOperator: BooleanOperator.OR,
				boxExpressionOperator: BooleanOperator.OR,

				operator: BooleanOperator.AND,

				applyBoxTypeVisibility: false,
				boxTypeIsVisible: true,
				boxTypeAreBoxesVisible: true,
				boxTypeAreBoxesInLayout: true,
				boxTypeMixinBoxTypeVisibilityMap: undefined,
				boxTypeAttributeTypeVisibilityMap: {
					"Has Lens Pages": true,
				},

				applyBoxVisibility: false,
				boxIsVisible: true,
				boxIsInLayout: true,
			},
		],
		parameters: undefined,
	};

	// Add "General" lens group
	lensGroupMap["General Lens"] = {
		name: "General Lens",
		order: -1,
		lensKeys: ["Is Canvas", "Has Lens Pages"],
	};
};
